<template>
<div>
    <div class="about" style="background: #4451b4;">
        <div class="w-100 d-flex align-items-center">
            <Nav msg="Historias Clinicas RMV" />
            <div class="dropdown">
                <fa @click="Mostrar=true" id="ellipsis-v" style=" cursor:pointer; position: absolute;right: 0%;margin-right: 10px; color:white; font-size:1.1rem;" icon="ellipsis-v" />
                <div v-if="Mostrar" class="dropdown-content">
                    <p style="cursor:pointer; color:black;" @click="salir()">Salir</p>

                </div>
            </div>
        </div>
        <div class="w-100 d-flex flex-column align-items-center" style="background: rgba(48,62,160,255);">
            <p style="color:white;"> {{ datos.nombre + " " + datos.apellido + " " }}<span>&#183; </span>{{datos.documento }}</p>
            <p style="color:white;"> Móvil <span>&#183; </span>{{movil }}</p>
        </div>

    </div>
    <div style="margin:1rem; font-size:1.3rem;">
        <p class="text-center mb-0">
            <b>Consecutivo: <span>{{consecutivo}}</span> </b>

        </p>
        <p class="text-center mb-0">
            <b>
                <span>{{nombre}}</span>
            </b>
            <br>
            <b>
                <span>{{nombreMascota}}</span>
            </b>
        </p>
        <p class="text-center mb-0">
            <b>
                <span>{{documento}}</span>
            </b>
        </p>
        <!--<p class=" mb-0">
            <b>
                Edad: <span>{{edad}}</span> años
            </b>
        </p>-->
        <div>
            <p class="mb-2">
                <b>
                    Talla
                </b>
            </p>
            <Dropdown @change="cambios(1)" class="w-100" v-model="selectedTamano" :options="options" optionLabel="valor" />
        </div>
        <div>
            <p class="mb-2">
                <b>
                    Peso
                </b>
            </p>
            <input @input="cambios(2)" maxlength="5" v-model="peso" class="w-100" style="border: 1px solid #ced4da; height: 42px; padding-left:10px;" type="number" autocomplete="off" />

        </div>
        <div>
            <p class="mb-2">
                <b>
                    Comida
                </b>
            </p>
            <Dropdown @change="cambios(3)" class="w-100" v-model="selectedComida" :options="comida" optionLabel="valor" />

        </div>
        <div>
            <p class="mb-2">
                <b>
                    Número de comidas al día
                </b>
            </p>
            <input @input="cambios(4)" maxlength="5" v-model="comidas" class="w-100" style="border: 1px solid #ced4da; height: 42px; padding-left:10px;" type="number" autocomplete="off" />

        </div>
        <div>
            <p class="mb-2">
                <b>
                    Cantidad de comida al día
                </b>
            </p>
            <input @input="cambios(5)"  v-model="cantidad" class="w-100" style="border: 1px solid #ced4da; height: 42px; padding-left:10px;" type="text" autocomplete="off" />

        </div>
        <div>
            <p class="mb-2">
                <b>
                    Marca Comida <span style="font-size: 1.2rem; cursor:pointer;" @click="modalMarca=true"> <fa style=" cursor:pointer; right:auto;" icon="upload" /> </span>
                </b>
            </p>
            <el-select class="w-100"  @change="cambios(6)"   v-model="marca" filterable placeholder="Select">
              <el-option
                v-for="item in Marca"
                :key="item.valor"
                :label="item.valor"
                :value="item.clave">
              </el-option>
            </el-select>
            

        </div>
        <div>
            <p class="mb-2">
                <b>
                    Fecha de última desparasitación
                </b>
            </p>
            <p class="mb-2">{{fechaDespara}}</p>

        </div>

        <div class="d-flex w-100 justify-content-center align-items-center  flex-column">

            <button @click="siguiente()" style="background:#2196f3; border-radius: 10px; margin-bottom: 1rem; color: white; width:90%; position: relative; top:5px;">SIGUIENTE</button>

        </div>

    </div>
    <div id="boxLoading"></div>
    <div v-if="modalMarca">
        <transition name="model">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog">

                        <div class="modal-content">
                            <div class="modal-header w-100">
                                <div style="padding-left: 5%;" class="w-100">
                                    <h1 style="color:black;">Inserte Marca Comida</h1>
                                    <Textarea class="w-100" v-model="marcaComidaInsertar" :autoResize="true" rows="1" cols="30" />
                                     
                                </div>

                            </div>
                            <div class="modal-body">

                                <div class="form-group">
                                    <button @click="modalMarca=false,marcaComidaInsertar=''" style=" color: red; widht:100px; position: relative; top:5px; ">CERRAR</button>
                                    <button @click="guardarMarca()" style="left:10px; color: green; widht:100px; position: relative; top:5px;">ACEPTAR</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </transition>
    </div>
</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import axios from 'axios'
import $ from 'jquery';
var url = "/php/crud.php";
/* var url = "http://localhost:8084/vue/proyecto_mascota s /php/crud.php"; */
export default {
    name: 'Previa',
    components: {
        Nav
    },
    data() {
        return {
            datos: JSON.parse(localStorage.getItem("datos")),
            movil: localStorage.getItem("movil"),
            Mostrar: false,
            consecutivo: localStorage.getItem("consecutivo"),
            nombre: '',
            documento: '',
            selectedTamano: '',
            options: [],
            id: '',
            peso: '',
            comida: [],
            selectedComida: '',
            comidas: '',
            cantidad: '',
            marca: '',
            fechaDespara: '',
            Marca: [],
            modalMarca: false,
            nombreMascota: '',
            edad: ''

        }
    },
    mounted() {
        $("#boxLoading").addClass("loading");
        var prueba = localStorage.getItem("prueba");
        if (prueba === null) {
            this.$router.replace({
                name: 'Home'
            });
        }
        axios.post(url, {
            consecutivo: localStorage.getItem("consecutivo"),
            opcion: 3
        }).then(response => {
            var Datos = response.data.data[0];
            this.id = Datos.ID_Ben;
            this.nombre = Datos.titnom;
            this.nombreMascota = Datos.bennm1;


            try {
                this.fechaDespara = Datos.fecha.split(" ")[0];
            } catch (error) {
                console.log(error);
            }

            axios.post(url, {
                id: this.id,
                opcion: 16
            }).then(response => {

                this.options = response.data.data;
                this.comida = response.data.comida;
                this.examen = response.data.examen;
                if (localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('tamano')) != null && localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('tamano')) != 'undefined') {
                    this.selectedTamano = JSON.parse(localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('tamano')));

                } else {
                    this.selectedTamano = response.data.data2[0];
                    localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('tamano'), JSON.stringify(this.selectedTamano))
                }
                if (localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('comida')) != null && localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('comida')) != 'undefined') {
                    this.selectedComida = JSON.parse(localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('comida')));

                } else {
                    this.selectedComida = response.data.tipo_comida[0];
                    localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('comida'), JSON.stringify(this.selectedComida));
                }
                if (localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('peso')) != null) {
                    this.peso = localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('peso'));

                } else {
                    this.peso = response.data.peso[0].peso;
                    localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('peso'), this.peso);
                }
                if (localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('comidas')) != null) {
                    this.comidas = localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('comidas'));

                } else {
                    this.comidas = response.data.peso[0].comidas;
                    if (this.comidas == null) {
                        this.comidas = '';
                    }
                    localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('comidas'), this.comidas);
                }
                if (localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('cantidad')) != null) {
                    this.cantidad = localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('cantidad'));

                } else {
                    this.cantidad = response.data.peso[0].cantidad;
                    if (this.cantidad == null) {
                        this.cantidad = '';
                    }
                    localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('cantidad'), this.cantidad);
                }
                axios.post(url, {
                    consecutivo: localStorage.getItem("consecutivo"),
                    opcion: 4
                }).then(response => {
                    var Datos = response.data.data[0];
                    this.documento = Datos.bendoc;
                    this.edad = Datos.edad;
                    axios.post(url, {
                    consecutivo: localStorage.getItem("consecutivo"),
                    opcion: 26
                    }).then(response => {
                        this.Marca = response.data.data;
                        console.log(this.Marca);
                        if(localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('marca')) != null){
                            this.marca = localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('marca'));
                            localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('marca'), this.marca);

                        }
                        else{
                            this.marca = '';
                        }
                        $("#boxLoading").removeClass("loading")
                    })
                })

            })

        })
        document.addEventListener('click',((event)=>{
            
            if(event.path[0].id != 'ellipsis-v'){
                this.Mostrar = false;
            }
        }))
        document.body.style.backgroundColor = "white";

    },
    methods: {
        salir: function () {

            this.$router.replace({
                name: 'Home'
            });
        },
        siguiente: function () {
            this.$router.replace({
                name: 'Historias'
            });
        },

        cambios: function (valor) {
            switch (valor) {
                case 1:
                    localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('tamano'), JSON.stringify(this.selectedTamano));

                    break;
                case 2:
                    localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('peso'), this.peso);

                    break;
                case 3:
                    localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('comida'), JSON.stringify(this.selectedComida));
                    break;
                case 4:
                    localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('comidas'), this.comidas);

                    break;
                case 5:
                    localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('cantidad'), this.cantidad);

                    break;
                case 6:
                    localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('marca'), this.marca);

                    break;

                default:
                    break;
            }
        },
        guardarMarca: function(){
            $("#boxLoading").addClass("loading");
            axios.post(url, {
                    opcion: 27,
                    marca: this.marcaComidaInsertar
                }).then((response) => {
                    this.modalMarca = false;
                    this.Marca = response.data.data;
                    this.$swal.fire('Marca registrada!', '', 'success');
                    $("#boxLoading").removeClass("loading");
                })
        }

    }
}
</script>

<style>
h1 {
    text-align: center;
}

.dropdown {
    position: relative;
    display: inline-block;
    top: -10px;
}

.dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0%;

}

.dropdown:hover .dropdown-content {
    display: block;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

body,
input {
    font-family: "Poppins", sans-serif;

}

h1 {
    color: white;
}

.listas {
    padding: 10px;
    border-bottom: black solid 1px;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.listas:hover {
    -webkit-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
    box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
}

#contenedor {
    display: inline-block;
    min-width: 96vw;
}

.texto2 {
    border-bottom: black solid 1px;

}

.p-dropdown-item {
    overflow: auto !important;
    white-space: break-spaces !important;
}

.p-dropdown-items-wrapper {
    max-width: 100vw;
}

.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif') 50% 50% no-repeat rgb(249, 249, 249);
    opacity: .8;
}
.el-select .el-select__tags-text{
    font-size: 120%;
}
.p-inputtext{
    font-size: 1.3rem !important;
}
.el-input__inner {
    font-size: 1.3rem !important;
}
.el-select-dropdown__item{
    font-size: 1.2rem !important;
}
.form-group {
  display: flex;
    justify-content: center;
}
</style>
