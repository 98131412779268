<template>
<div>
    <div class="about" style="background: #4451b4;">
        <div class="w-100 d-flex align-items-center">
            <Nav msg="Historias Clinicas RMV" />
            <div class="dropdown">
                <fa @click="Mostrar=true" id="ellipsis-v" style=" cursor:pointer; position: absolute;right: 0%;margin-right: 10px; color:white; font-size:1.1rem;" icon="ellipsis-v" />
                <div v-if="Mostrar" class="dropdown-content">
                    <p style="cursor:pointer; color:black;" @click="salir()">Salir</p>

                </div>
            </div>
        </div>
        <!--
            Esta seccion aquí nos sirve para desplegar un mini menu que mostrara tres opciones, al darle click en Móvil mostrara un modal que le permitira al usuario
            Ingresar el numero de la móvil con la que va a trabajar
        -->
        <div class="w-100 d-flex flex-column align-items-center" style="background: rgba(48,62,160,255);">
            <p style="color:white;"> {{ datos.nombre + " " + datos.apellido + " " }}<span>&#183; </span>{{datos.documento }}</p>
            <p style="color:white;"> Móvil <span>&#183; </span>{{movil }}</p>
        </div>
        <!--
            Esta seccion aquí nos sirve para mostrar algunos datos asociados al usuario que ingreso con el login
         -->

    </div>
    <!--
        Toda esta sección podríamos tomarlo como la cabecera que repetiremos en muchas vistas
    -->
    <div style="font-size:1.3rem;">
        <p class="texto text-center">
            <b>Consecutivo: <span>{{consecutivo}}</span> </b>
        </p>
        <!--
            Consecutivo almacenado previamente
        -->
        <p class="texto">
            <b>Nombre Propietario: </b>
            <br>
            <span>{{nombre}}</span>
        </p>
        <!--
            Nombre del propietario asociado con el campo titnom
        -->
        <p class="texto">
            <b>Telefono Beneficiario: </b>
            <br>
            <span>{{telefonoPropietario}}</span>
        </p>
        <!--
            Telefono del beneficiario, asociado con el campo bentel
        -->
        <p class="texto">
            <b>Documento Mascota: </b>
            <br>
            <span>{{documento}}</span>
        </p>
        <!--
            Documento del beneficiario, asociado con el campo bendoc
        -->
        <p class="texto">
            <b>Nombre Mascota: </b>
            <br>
            <span>{{nombreMascota}}</span>
        </p>
        <!--
            Nombre de la mascota, asociado con el campo bennm1
        -->
        <p class="texto">
            <b>Raza: </b>
            <br>
            <span>{{raza+''}}</span>
        </p>
        <!--
            Raza de la mascota, asociada con el campo raza2, al observar la tabla se vera un numero, este es el indicador para asociar con la tabla raza
        -->
        <p class="texto">
            <b>Especie: </b>
            <br>
            <span>{{especie}}</span>
        </p>
        
        <div class="d-flex justify-content-center" id="root">
            
            <img v-if="src2" style="width:300px; height:300px;" v-bind:src="src2" alt="x">
            
            
        </div>
        <!--
            Especie de la mascota, 1 para canino 2 para femenino
        -->

        
        <!--
            Esta sección nos mostrara si en verdad el usuario necesita registrar o no el ciclo de desparasitación para su entrega, aquí va a tener
            un texto cambiante teniendo dos opciones: entregar dosis y no entregar dosis, además cambiar el color del fondo dependiendo de si se entrega o no
            siendo amarillo para si y gris para no.
            Además al darle click abre un modal
        -->
        <p class="texto">{{fechaDespara}}</p>
        <p class="texto">
            <b>Dosis Desparasitación: </b>
            <br>
            <span>{{dosis}}</span>
        </p>
        <!--
            Dosis es el campo encontrado desparasitacion_tipo_dosis que hace relación con la tabla desparasitacion_tipo_dosis
        -->

        <p class="texto">
            <b>Peso: </b>
            <br>
            <span>{{peso + ' KG'}}</span>
        </p>
        <!--
            Este es el campo peso
        -->
        <p class="texto">
            <b>Última Vacunación: </b>
            <br>
            
            <span>{{fecha}}</span>

        </p>
        <!--
            Este es el campo peso
        -->
    </div>
    <div style="background-color: #efefef; font-size:1.3rem;">

        <p class="texto">
            <b>Código de valoración: </b>
            <br>
            <button class="boton" style="font-size:1rem; margin-top:0.5rem;" v-bind:style="{ background: color}">{{texto}}</button>

        </p>
        <!--
            Esto es básicamente el botón asociado al click que teniamos en la primera vista, su color y texto provienen del localstorage
        -->
        <p class="texto">
            <b>Tel Solicitud de Servicio: </b>
            <br>
            <span>{{telefonoServicio}}</span>
        </p>
        <!--
            Este es el campo reqtel, es el telefono que solicito el servicio
        -->
        <p class="texto">
            <b>Persona que llama: </b>
            <br>
            <span>{{persona}}</span>
        </p>
        <!--
            Este es el campo reqper, es la persona que solicito el servicio
        -->
        <p class="texto">
            <b>Dir Emergencia: </b>
            <br>
            <span>{{direccion}}</span>
        </p>
        <!--
            Este es el campo reqdir, es la dirección de donde se solicita el servicio
        -->
        <p class="texto">
            <b>Antecedentes: </b>
            <br>
            <span>{{antecedentes}}</span>
        </p>
        <!--
            Este es el campo bentxt
        -->
        <p class="texto">
            <b>Notas Operador: </b>
            <br>
            <span>{{notasOperador}}</span>
        </p>
        <!--
            Este es el campo reqtxo
        -->
        <p class="texto">
            <b>Notas Servicio: </b>
            <br>
            <span>{{notasServicio}}</span>
        </p>
        <!--
            Este es el campo reqtxt
        -->
        <p class="texto">
            <b>Signos y síntomas: </b>
            <br>
            <span>{{signos}}</span>
        </p>
        <!--
            Este es el campo reqsig
        -->
        <p class="texto">
            <b>Hora Llamada: </b>
            <br>
            <span>{{hora}}</span>
        </p>
        <!--
            Este es el campo reqhor
        -->
        <p class="texto">
            <b>Consulta Anterior: </b>
            <br>
            <span>{{consultaAnterior}}</span>
            <br>
            <span>Traslado: {{consulta}}</span>
        </p>
        <!--
            consultaAnterior es el campo que obtener al juntar
        -->
        
        

    </div>
    
    
    <div class="d-flex w-100 justify-content-center align-items-center  flex-column">
        <button v-bind:style="{ background: CREAR, color: CREARCOLOR}" @click="registrarLectura()" style="border-radius: 10px;  margin-bottom: 1rem;  width:90%; position: relative; top:5px;">REGISTRAR LECTURA DE EXAMENES</button>
        <button @click="redirrecionarAtras()" style="background:#2196f3; border-radius: 10px; margin-bottom: 1rem; color: white; width:90%; position: relative; top:5px;">ATRÁS</button>

    </div>
    <div id="boxLoading"></div>
</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import axios from 'axios'
import $ from 'jquery';
// var url2 = "/php/imagenes.php";
var url = "/php/crud.php";
var url3 = "/php/prueba.php";
// var url4 = "/php/imagenes_mini.php";
export default {
    name: 'Home2',
    components: {
        Nav
    },
    data() {
        return {
            datos: JSON.parse(localStorage.getItem("datos")),
            movil: localStorage.getItem("movil"),
            Mostrar: false,
            consecutivo: localStorage.getItem("consecutivo"),
            nombre: '',
            nombreMascota: '',
            color: localStorage.getItem("color"),
            texto: localStorage.getItem("texto"),
            raza: '',
            ciclo_Activo: '',
            peso: '',
            fecha: '',
            especie: '',
            dosis: '',
            antecedentes: '',
            notasOperador: '',
            notasServicio: '',
            hora: '',
            consulta: '',
            textoCicloActivo: '',
            validar: false,
            consultaAnterior: '',
            telefonoPropietario: '',
            telefonoServicio: '',
            persona: '',
            documento: '',
            direccion: '',
            signos: '',
            modal: false,
            usuario: '',
            ciclo: '',
            modal2: false,
            modalClinica: false,
            triaje: {
                A: "amarillo",
                V: "verde",
                R: "rojo",
                P: "azul",
                E: "error",
            },
            RegistrarHora: false,
            fechaDespara: '',
            NOTIFICAR: '#ffee58',
            CREAR: '#efefef',
            CREARCOLOR: 'black',
            modalPeso: false,
            src2:'',
           

        }
    },
    mounted() {
        $("#boxLoading").addClass("loading");
        
        this.NOTIFICAR = '#efefef';
        this.CREAR = '#4451b4';
        this.CREARCOLOR = 'white';
        this.RegistrarHora = true;
        
        
        var prueba = localStorage.getItem("prueba");
        
        if (prueba === null) {
            this.$router.replace({
                name: 'Home'
            });
        }
        document.body.style.backgroundColor = "white";
        axios.post(url, {
            consecutivo: localStorage.getItem("consecutivo"),
            opcion: 3
        }).then(response => {
            var Datos = response.data.data[0];
           
            this.nombre = Datos.titnom;
            this.nombreMascota = Datos.bennm1;
            this.raza = Datos.Raza;
            this.ciclo_Activo = Datos.cliclo_activo == Datos.desparasitacion_ciclo ? '#fbfb58' : '#8080808c';
            this.textoCicloActivo = Datos.cliclo_activo == Datos.desparasitacion_ciclo ? 'Entregar Dosis' : 'NO Entregar Dosis';
            this.ciclo = Datos.cliclo_activo;
            this.validar = Datos.cliclo_activo == Datos.desparasitacion_ciclo;
            this.peso = Datos.peso;
            this.especie = Datos.especie;
            this.fecha = Datos.Fecha;
            this.dosis = Datos.tipo_dosis;
            this.usuario = Datos.ID_Ben;
            this.fechaDespara = Datos.fecha;
        })
        axios.post(url, {
            consecutivo: localStorage.getItem("consecutivo"),
            opcion: 4
        }).then(response => {
           
            var Datos = response.data.data[0];
            this.antecedentes = Datos.bentxt;
            this.notasOperador = Datos.reqtxo;
            this.notasServicio = Datos.reqtxt;
            this.hora = Datos.reqhor;
            this.consulta = (Datos.ult_traslado == 30 || Datos.ult_traslado == 40) ? 'Si' : (Datos.ult_traslado == 10 || Datos.ult_traslado == 20 || Datos.ult_traslado == 41) ? 'No' : 'No Aplica';
            try {
                this.consultaAnterior = Datos.ult_diag.concat(' ', Datos.ult_diag2, ' (', Datos.ult_fecha, ', ', Datos.ult_hora, ')');
            } catch (error) {
                console.log(error);
            }

            this.telefonoPropietario = Datos.bentel;
            this.telefonoServicio = Datos.reqtel;
            this.persona = Datos.reqper;
            this.documento = Datos.bendoc;
            this.direccion = Datos.reqdir;
            this.signos = Datos.reqsig;
            axios.post(url3, {
            id: localStorage.getItem("consecutivo"),
            documento: this.documento,

            }).then(response => {
                
                this.src2 = response.data;
                if(this.src2 == ' '){
                    this.src2 = false;
                }
                $("#boxLoading").removeClass("loading")
            })
            
        })
        document.addEventListener('click',((event)=>{
            
            if(event.path[0].id != 'ellipsis-v'){
                this.Mostrar = false;
            }
        }))

    },
    methods: {
        salir: function () {
            
            this.$router.replace({
                name: 'Home'
            });
        },
        
        
        redirrecionarAtras: function () {
            this.$router.replace({
                name: 'Index'
            });

        },
        registrarLectura: function(){
            this.$swal.fire({
                   title: 'Desea Registrar Lectura Examenes',
                   showCancelButton: true,
                   confirmButtonText: `Aceptar`,
                   cancelButtonText: `Cancelar`,
                   reverseButtons: true,
               }).then((result) => {
                   /* Read more about isConfirmed, isDenied below */
                   if (result.isConfirmed) {
                       if (!localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('hora'))) {
                           var hora = new Date();
                           var texto = ''.concat(hora.getHours().toString().length > 1 ? hora.getHours().toString() : '0'.concat(hora.getHours().toString()),
                               ':',
                               hora.getMinutes().toString().length > 1 ? hora.getMinutes().toString() : '0'.concat(hora.getMinutes().toString()));
                           localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('hora'), texto);
                       }
                       this.$router.replace({
                           name: 'Lectura1'
                       });
                   }
               })
        }
       
        
       
    }
}
</script>

<style>
h1 {
    text-align: center;
}

.dropdown {
    position: relative;
    display: inline-block;
    top: -10px;
}

.dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0%;

}

.dropdown:hover .dropdown-content {
    display: block;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

body,
input {
    font-family: "Poppins", sans-serif;

}

h1 {
    color: white;
}

.listas {
    padding: 10px;
    border-bottom: black solid 1px;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.listas:hover {
    -webkit-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
    box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
}

.texto {

    margin-left: 1rem;
}

.boton {
    margin-left: auto;
    color: white;
    margin-right: 2rem !important;
    width: 4rem;
}

.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif') 50% 50% no-repeat rgb(249, 249, 249);
    opacity: .8;
}

.dialog2 {
    max-width: 90vw !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before {
    background-color: cornflowerblue !important;
}

table.dataTable thead {
    background: linear-gradient(to right, #0575E6, #00F260);
    color: white;
}

table,
th,
td {
    border: 1px solid black !important;
}

td {
    text-align: center;
}

button {
    border-radius: 10px !important;
}

.rodar {
    height: 100vh;
    overflow-y: scroll;
}
button.swal2-styled{
    padding: 0;
}
.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif') 50% 50% no-repeat rgb(249, 249, 249);
    opacity: .8;
}
.el-select .el-select__tags-text{
    font-size: 120%;
}
.p-inputtext{
    font-size: 1.3rem !important;
}
.el-input__inner {
    font-size: 1.3rem !important;
}
.el-select-dropdown__item{
    font-size: 1.2rem !important;
}
</style>
