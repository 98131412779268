<template>
  <div>
    <div class="about" style="background: #4451b4">
      <div class="w-100 d-flex align-items-center">
        <Nav msg="Historias Clinicas RMV" />
        <div class="dropdown">
          <fa
            @click="Mostrar = true"
            id="ellipsis-v"
            style="
              width: 1.2rem;
              cursor: pointer;
              position: absolute;
              right: 0%;
              margin-right: 10px;
              color: white;
              font-size: 1.1rem;
            "
            icon="ellipsis-v"
          />
          <div>
            <div v-if="Mostrar" class="dropdown-content">
              <p style="cursor: pointer; color: black" @click="descargar()">
                Descargar Pdf
              </p>
              <p style="cursor: pointer; color: black" @click="salir()">Salir</p>
            </div>
          </div>
        </div>
      </div>
      <!--
            Esta seccion aquí nos sirve para desplegar un mini menu que mostrara tres opciones, al darle click en Móvil mostrara un modal que le permitira al usuario
            Ingresar el numero de la móvil con la que va a trabajar
        -->
      <div
        class="w-100 d-flex flex-column align-items-center"
        style="background: rgba(48, 62, 160, 255)"
      >
        <p style="color: white">
          {{ datos.nombre + " " + datos.apellido + " " }}<span>&#183; </span
          >{{ datos.documento }}
        </p>
        <p style="color: white">Móvil <span>&#183; </span>{{ movil }}</p>
      </div>
      <!--
            Esta seccion aquí nos sirve para mostrar algunos datos asociados al usuario que ingreso con el login
         -->
    </div>
    <!--
        Toda esta sección podríamos tomarlo como la cabecera que repetiremos en muchas vistas
    -->
    <div style="font-size: 1.3rem">
      <p class="texto text-center" style="cursor:pointer;" @click="modalConsecutivo = true">
        <b
          >Consecutivo: <span>{{ consecutivo }}</span>
        </b>
      </p>
      <!--
            Consecutivo almacenado previamente
        -->
      <p class="texto">
        <b>Nombre Propietario: </b>
        <br />
        <span>{{ nombre }}</span>
      </p>
      <!--
            Nombre del propietario asociado con el campo titnom
        -->
      <p class="texto">
        <b>Telefono Beneficiario: </b>
        <br />
        <span>{{ telefonoPropietario }}</span>
      </p>
      <!--
            Telefono del beneficiario, asociado con el campo bentel
        -->
      <p class="texto">
        <b>Documento Mascota: </b>
        <br />
        <span>{{ documento }}</span>
      </p>
      <!--
            Documento del beneficiario, asociado con el campo bendoc
        -->
      <p class="texto">
        <b>Nombre Mascota: </b>
        <br />
        <span>{{ nombreMascota }}</span>
      </p>
      <p class="texto">
        <b>
          Edad:
          <span style="color: blue; cursor: pointer" @click="modalEdad = true">{{
            edad
          }}</span>
        </b>
      </p>
      <!--
            Nombre de la mascota, asociado con el campo bennm1
        -->
      <p class="texto">
        <b>Raza: </b> <span style="font-size: 1.2rem; cursor: pointer" @click="modalRaza = true">
            <fa style="cursor: pointer; right: auto" icon="upload" /> </span
        >
        <br />

        <el-select
          @change="actualizarRaza"
          v-model="selectedRaza"
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="item in razas"
            :key="item.valor"
            :label="item.valor"
            :value="item.valor"
          >
          </el-option>
        </el-select>
      </p>
      <!--
            Raza de la mascota, asociada con el campo raza2, al observar la tabla se vera un numero, este es el indicador para asociar con la tabla raza
        -->
      <p class="texto">
        <b
          >Especie:
          <span style="font-size: 1.2rem; cursor: pointer" @click="modalEspecie = true">
            <fa style="cursor: pointer; right: auto" icon="upload" /> </span
        ></b>
        <br />
        <span>{{ especie }}</span>
      </p>

      <div class="d-flex justify-content-center" id="root">
        <img v-if="src2" style="width: 300px; height: 300px" v-bind:src="src2" alt="x" />
      </div>
      <div
        style="
          font-size: 1rem;
          color: white;
          margin-bottom: 1rem;
          width: 100%;
          border-radius: 10px;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #689f38;
        "
      >
        <form ref="form">
          <label class="cameraButton"
            >SUBIR FOTO MASCOTA
            <input
              ref="file"
              id="file"
              name="prueba"
              @change="subir()"
              type="file"
              accept="image/*; capture=camera"
            />
          </label>
        </form>
      </div>
      <!--
            Especie de la mascota, 1 para canino 2 para femenino
        -->

      <p class="texto">
        <b>Ciclo Desparasitación: </b>
      </p>
      <button
        @click="mostrarModal2()"
        class="boton"
        style="
          color: black;
          width: 13rem;
          margin-left: 1rem;
          top: -1rem;
          position: relative;
        "
        v-bind:style="{ background: ciclo_Activo }"
      >
        {{ textoCicloActivo }}
      </button>
      <!--
            Esta sección nos mostrara si en verdad el usuario necesita registrar o no el ciclo de desparasitación para su entrega, aquí va a tener
            un texto cambiante teniendo dos opciones: entregar dosis y no entregar dosis, además cambiar el color del fondo dependiendo de si se entrega o no
            siendo amarillo para si y gris para no.
            Además al darle click abre un modal
        -->
      <p class="texto">{{ fechaDespara }}</p>
      <p class="texto">
        <b>Dosis Desparasitación: </b>
        <br />
        <!-- <span>{{ dosis }}</span> -->
        <Dropdown
          @change="actualizarDosis"
          v-model="selectedDosis"
          :options="options"
          optionLabel="valor"
        />
      </p>
      <!--
            Dosis es el campo encontrado desparasitacion_tipo_dosis que hace relación con la tabla desparasitacion_tipo_dosis
        -->

      <p class="texto">
        <b
          >Peso:
          <span style="font-size: 1.2rem; cursor: pointer" @click="modalPeso = true">
            <fa style="cursor: pointer; right: auto" icon="upload" /> </span
        ></b>
        <br />
        <span>{{ peso + " KG" }}</span>
      </p>
      <!--
            Este es el campo peso
        -->
      <p class="texto">
        <b>Última Vacunación: </b>
        <br />
        <button
          @click="registrarVacuna()"
          class="boton"
          style="color: white; width: 13rem; background: #0038ff"
        >
          Vacunación
        </button>
        <br />
        <span>{{ fecha }}</span>
      </p>
      <!--
            Este es el campo peso
        -->
    </div>
    <div style="background-color: #efefef; font-size: 1.3rem">
      <p class="texto">
        <b>Código de valoración: </b>
        <br />
        <button
          class="boton"
          style="font-size: 1rem; margin-top: 0.5rem"
          v-bind:style="{ background: color }"
        >
          {{ texto }}
        </button>
      </p>
      <!--
            Esto es básicamente el botón asociado al click que teniamos en la primera vista, su color y texto provienen del localstorage
        -->
      <p class="texto">
        <b>Tel Solicitud de Servicio: </b>
        <br />
        <span>{{ telefonoServicio }}</span>
      </p>
      <!--
            Este es el campo reqtel, es el telefono que solicito el servicio
        -->
      <p class="texto">
        <b>Persona que llama: </b>
        <br />
        <span>{{ persona }}</span>
      </p>
      <!--
            Este es el campo reqper, es la persona que solicito el servicio
        -->
      <p class="texto">
        <b>Dir Emergencia: </b>
        <br />
        <span>{{ direccion }}</span>
      </p>
      <!--
            Este es el campo reqdir, es la dirección de donde se solicita el servicio
        -->
      <p class="texto">
        <b>Antecedentes: </b>
        <br />
        <span>{{ antecedentes }}</span>
      </p>
      <!--
            Este es el campo bentxt
        -->
      <p class="texto">
        <b>Notas Operador: </b>
        <br />
        <span>{{ notasOperador }}</span>
      </p>
      <!--
            Este es el campo reqtxo
        -->
      <p class="texto">
        <b>Notas Servicio: </b>
        <br />
        <span>{{ notasServicio }}</span>
      </p>
      <!--
            Este es el campo reqtxt
        -->
      <p class="texto">
        <b>Signos y síntomas: </b>
        <br />
        <span>{{ signos }}</span>
      </p>
      <!--
            Este es el campo reqsig
        -->
      <p class="texto">
        <b>Hora Llamada: </b>
        <br />
        <span>{{ hora }}</span>
      </p>
      <!--
            Este es el campo reqhor
        -->
      <p class="texto">
        <b>Consulta Anterior: </b>
        <!-- <br /> -->
        <!-- <span>{{ consultaAnterior }}</span> -->
        <br />
        <span
          v-if="otro_consecutivo"
          @click="descargarParticular(otro_consecutivo)"
          style="color: blue; cursor: pointer"
          >{{ dx_registrado }}</span
        >
        <span v-else>{{ dx_registrado }}</span>
        <br />
        <span>Traslado: {{ consulta }}</span>
      </p>
      <!--
            consultaAnterior es el campo que obtener al juntar
        -->
      <div id="boxLoading"></div>
    </div>

    <div v-if="modal">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header w-100">
                  <h4 class="modal-title w-100">¿Desea registrar desparasitación?</h4>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <button
                      @click="modal = false"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                    <button
                      @click="Modal2()"
                      style="
                        left: 10px;
                        color: green;
                        widht: 100px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      ACEPTAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="modal2">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog dialog2" id="dialog">
              <div class="modal-content" id="content" @click="pModal2()">
                <div
                  class="modal-header d-flex flex-column w-100 d-flex justify-content-center"
                >
                  <h1 style="color: black" class="text-center">Bitacora Vacunación</h1>
                  <a
                    href="https://app.vitalmascotas.com.co/hclinica-vet/login/index"
                    target="_blank"
                  >
                    <button
                      style="
                        background: #0038ff;
                        color: white;
                        width: 200px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      Agregar nueva vacuna
                    </button>
                  </a>
                </div>
                <div class="modal-body">
                  <div
                    class="container"
                    style="margin-top: 1rem"
                    id="containerTable"
                  ></div>
                  <div class="form-group d-flex justify-content-center">
                    <button
                      @click="pModal22()"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="modalClinica">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog dialog2" id="dialog">
              <div class="modal-content" id="content" @click="pClinica()">
                <div
                  class="modal-header d-flex flex-column w-100 d-flex justify-content-center"
                >
                  <div style="display: flex; justify-content: end; width: 100%">
                    <span style="cursor: pointer" @click="pClinica2()">X</span>
                  </div>
                  <h1 style="color: black">Historias Clinicas</h1>
                </div>
                <div class="modal-body">
                  <div
                    class="container"
                    style="margin-top: 1rem"
                    id="containerTableClinica"
                  ></div>
                  <div class="form-group d-flex justify-content-center">
                    <button
                      @click="pClinica2()"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="modalPeso">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header w-100">
                  <div class="d-flex w-100" style="justify-content: space-evenly">
                    <div style="padding-left: 5%">
                      <h1 style="color: black">Ingrese el peso</h1>
                      <input
                        maxlength="5"
                        v-model="peso"
                        class="w-100"
                        style="
                          border: 0;
                          padding: 1rem;
                          border-bottom: 1px solid #ced4da;
                          height: 42px;
                        "
                        type="number"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <button
                      @click="modalPeso = false"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                    <button
                      @click="guardarModalPeso()"
                      style="
                        left: 10px;
                        color: green;
                        widht: 100px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      GUARDAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <Dialog header="Modificar Especie" v-model:visible="modalEspecie">
      <el-select v-model="especie" filterable placeholder="Select">
        <el-option
          v-for="item in especies"
          :key="item.valor"
          :label="item.valor"
          :value="item.valor"
        >
        </el-option>
      </el-select>
      <br />
      <Button
        @click="guardarModalEspecie"
        label="Guardar"
        style="float: right; margin-top: 1rem"
      />
    </Dialog>
    <Dialog header="Agregar Raza" v-model:visible="modalRaza">
      <el-input v-model="razaAgregar" placeholder="Ingrese su raza" />
      <br />
      <Button
        @click="guardarModalRaza"
        label="Guardar"
        style="float: right; margin-top: 1rem"
      />
    </Dialog>

    <Dialog header="Modificar Edad" v-model:visible="modalEdad">
      <input type="date" v-model="edadCambiar" />
      <br />
      <Button
        @click="guardarModalEdad"
        label="Guardar"
        style="float: right; margin-top: 1rem"
      />
    </Dialog>
    <div class="d-flex w-100 justify-content-center align-items-center flex-column">
      <button
        @click="ModalClinica()"
        style="
          border-radius: 10px;
          margin-bottom: 1rem;
          color: black;
          width: 90%;
          position: relative;
          top: 5px;
        "
      >
        HISTORIAS CLÍNICAS
      </button>
      <button
        v-bind:style="{ background: NOTIFICAR }"
        @click="registrarHora()"
        style="
          border-radius: 10px;
          margin-bottom: 1rem;
          color: #4c470f;
          width: 90%;
          position: relative;
          top: 5px;
        "
      >
        NOTIFICAR HORA DE LLEGADA
      </button>
      <button
        v-bind:style="{ background: CREAR, color: CREARCOLOR }"
        @click="crearHistoria()"
        style="
          border-radius: 10px;
          margin-bottom: 1rem;
          width: 90%;
          position: relative;
          top: 5px;
        "
      >
        CREAR HISTORIA CLÍNICA
      </button>
      <button
        @click="redirrecionarAtras()"
        style="
          background: #2196f3;
          border-radius: 10px;
          margin-bottom: 1rem;
          color: white;
          width: 90%;
          position: relative;
          top: 5px;
        "
      >
        ATRÁS
      </button>
    </div>
    <Dialog header="Datos Generales" v-model:visible="modalConsecutivo">
      <p>Nombre Mascota: {{nombreMascota}}</p>
      <p>Correo: {{correo}}</p>
      <p>Nombre Titular: {{nombre}}</p>
      <p>Telefono Titular: {{telefonoTitular}}</p>
      <p>Peso: {{peso}} KG</p>
      
      <br />
      <Button
        @click="modalConsecutivo = false"
        label="Cerrar"
        style="float: right; margin-top: 1rem"
      />
    </Dialog>
    <div id="boxLoading"></div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import axios from "axios";
import $ from "jquery";
// var url2 = "/php/imagenes.php";
var url = "/php/crud.php";
var url3 = "/php/prueba.php";
var url4 = "/php/imagenes_mini.php";
export default {
  name: "Home",
  components: {
    Nav,
  },
  data() {
    return {
      datos: JSON.parse(localStorage.getItem("datos")),
      movil: localStorage.getItem("movil"),
      Mostrar: false,
      consecutivo: localStorage.getItem("consecutivo"),
      nombre: "",
      nombreMascota: "",
      color: localStorage.getItem("color"),
      texto: localStorage.getItem("texto"),
      raza: "",
      ciclo_Activo: "",
      peso: "",
      fecha: "",
      especie: "",
      dosis: "",
      antecedentes: "",
      notasOperador: "",
      notasServicio: "",
      hora: "",
      consulta: "",
      textoCicloActivo: "",
      validar: false,
      consultaAnterior: "",
      telefonoPropietario: "",
      telefonoServicio: "",
      persona: "",
      documento: "",
      direccion: "",
      signos: "",
      modal: false,
      usuario: "",
      ciclo: "",
      modal2: false,
      modalClinica: false,
      triaje: {
        A: "amarillo",
        V: "verde",
        R: "rojo",
        P: "azul",
        E: "error",
      },
      RegistrarHora: false,
      fechaDespara: "",
      NOTIFICAR: "#ffee58",
      CREAR: "#efefef",
      CREARCOLOR: "black",
      modalPeso: false,
      src2: "",
      selectedDosis: {
        clave: "",
        valor: "",
      },
      options: [],
      dosisId: "",
      selectedRaza: [],
      razas: [],
      edad: "",
      modalEspecie: false,
      especies: [
        { clave: "1", valor: "Canino" },
        { clave: "2", valor: "Felino" },
      ],
      dx_registrado: "",
      otro_consecutivo: "",
      modalEdad: false,
      edadCambiar: null,
      modalRaza: false,
      razaAgregar: "",
      modalConsecutivo: false,
      telefonoTitular: "",
      correo: "",
    };
  },
  mounted() {
    $("#boxLoading").addClass("loading");
    if (
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("registrar")
      )
    ) {
      this.NOTIFICAR = "#efefef";
      this.CREAR = "#4451b4";
      this.CREARCOLOR = "white";
      this.RegistrarHora = true;
    }

    var prueba = localStorage.getItem("prueba");

    if (prueba === null) {
      this.$router.replace({
        name: "Home",
      });
    }
    document.body.style.backgroundColor = "white";
    axios
      .post(url, {
        consecutivo: localStorage.getItem("consecutivo"),
        opcion: 3,
      })
      .then((response) => {
        var Datos = response.data.data[0];

        this.nombre = Datos.titnom;
        this.nombreMascota = Datos.bennm1;
        this.raza = Datos.Raza;
        this.ciclo_Activo =
          Datos.cliclo_activo == Datos.desparasitacion_ciclo ? "#fbfb58" : "#8080808c";
        this.textoCicloActivo =
          Datos.cliclo_activo == Datos.desparasitacion_ciclo
            ? "Entregar Dosis"
            : "NO Entregar Dosis";
        this.ciclo = Datos.cliclo_activo;
        this.validar = Datos.cliclo_activo == Datos.desparasitacion_ciclo;
        this.peso = Datos.peso;
        this.especie = Datos.especie;
        this.fecha = Datos.Fecha;
        this.dosis = Datos.tipo_dosis;
        this.dosisId = Datos.id_dosis;
        this.usuario = Datos.ID_Ben;
        this.fechaDespara = Datos.fecha;
        this.telefonoTitular = Datos.tittel;
        this.correo = Datos.mail;
      });
    axios
      .post(url, {
        consecutivo: localStorage.getItem("consecutivo"),
        opcion: 4,
      })
      .then((response) => {
        var Datos = response.data.data[0];
        this.antecedentes = Datos.bentxt;
        this.notasOperador = Datos.reqtxo;
        this.notasServicio = Datos.reqtxt;
        this.edad = Datos.edad;
        this.edadCambiar = Datos.bennac;
        this.hora = Datos.reqhor;
        this.dx_registrado = Datos.dx_registrado ?? "Sin Dx Registrado";
        this.otro_consecutivo = Datos.otro_consecutivo;
        this.consulta =
          Datos.ult_traslado == 30 || Datos.ult_traslado == 40
            ? "Si"
            : Datos.ult_traslado == 10 ||
              Datos.ult_traslado == 20 ||
              Datos.ult_traslado == 41
            ? "No"
            : "No Aplica";
        try {
          this.consultaAnterior = Datos.ult_diag.concat(
            " ",
            Datos.ult_diag2,
            " (",
            Datos.ult_fecha,
            ", ",
            Datos.ult_hora,
            ")"
          );
        } catch (error) {
          console.log(error);
        }

        this.telefonoPropietario = Datos.bentel;
        this.telefonoServicio = Datos.reqtel;
        this.persona = Datos.reqper;
        this.documento = Datos.bendoc;
        this.direccion = Datos.reqdir;
        this.signos = Datos.reqsig;
        axios
          .post(url3, {
            id: localStorage.getItem("consecutivo"),
            documento: this.documento,
          })
          .then((response) => {
            this.src2 = response.data;
            if (this.src2 == " ") {
              this.src2 = false;
            }
            axios
              .post(url, {
                opcion: 38,
              })
              .then((response) => {
                console.log(response.data);
                this.options = response.data;

                this.options.forEach((element) => {
                  if (element.clave == this.dosisId) {
                    this.selectedDosis = element;
                  }
                });
                axios
                  .post(url, {
                    opcion: 40,
                  })
                  .then((response) => {
                    console.log(response.data);
                    this.razas = response.data;
                    this.selectedRaza = this.raza;

                    /* this.options.forEach(element => {
                  if(element.clave == this.dosisId){
                    this.selectedDosis = element;
                  }

                }); */

                    $("#boxLoading").removeClass("loading");
                  });
              });
          });
      });
    document.addEventListener("click", (event) => {
      if (event.path[0].id != "ellipsis-v") {
        this.Mostrar = false;
      }
    });
  },
  methods: {
    salir: function () {
      this.$router.replace({
        name: "Home",
      });
    },
    mostrarModal: function () {
      this.modal2 = true;
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 6,
          consecutivo: localStorage.getItem("consecutivo"),
        })
        .then((response) => {
          var Div_Contenedor_Table = document.getElementById("containerTable");
          Div_Contenedor_Table.innerHTML = "";
          var Datos = response.data;
          let html = `
                            <div class="row">
                                <div class="col-lg-12">
                                    <table id="Tabla_Beneficiarios" class="table-striped table-bordered" style="width:100%; font-size: 1.1rem;">
                                        <thead class="text-center">
                                            <th>CSC</th>
                                            <th>Vacuna</th>
                                            <th>Fecha</th>
                                            <th>Vacunación Periodo</th>
                                            <th>Veterinario</th>
                                        </thead>
                                        <tbody id="Datos_Beneficiarios">
                                        </tbody>
                                    </table>
                                </div>
                            </div> 
                            `;
          Div_Contenedor_Table.append($(html)[0]);
          var Datos_Beneficiarios = document.getElementById("Datos_Beneficiarios");

          Datos.forEach((element) => {
            let html_Interno = `
                                <tr>
                                    <td>${element["consecutivo"]}</td>
                                    <td>${element["vacuna"]}</td>
                                    <td>${element["fecha"]}</td>
                                    <td>${element["periodo"]}</td>
                                    <td @click="hola">${element["veterinario"]}</td>
                                </tr>
                                `;
            Datos_Beneficiarios.append($(html_Interno)[0]);
          });

          $("#Tabla_Beneficiarios").DataTable({
            responsive: true,
            dom: "Bfrtip",
            buttons: [],
          });
          $("#boxLoading").removeClass("loading");
        });
    },
    mostrarModal2: function () {
      this.modal = true;
    },
    Modal2: function () {
      $("#boxLoading").addClass("loading");
      if ("geolocation" in navigator) {
        const onUbicacionConcedida = (ubicacion) => {
          if (this.validar) {
            /* this.modal = true; */
            axios
              .post(url, {
                usuario: this.usuario,
                opcion: 5,
                ciclo: this.ciclo,
                nombre: this.datos.nombre,
                coordenada: "".concat(
                  ubicacion.coords.latitude,
                  ",",
                  ubicacion.coords.longitude
                ),
              })
              .then((response) => {
                $("#boxLoading").removeClass("loading");
                if (response.data.ok == true) {
                  if (response.data.problemas == true) {
                    this.$swal.fire(
                      "Asignado pero el ciclo activo actual no corresponde con el asignado",
                      "",
                      "error"
                    );
                  } else {
                    this.$swal.fire("Registrado satisfactoriamente", "", "success");
                  }
                } else {
                  //Estado de desparasitacion entregado
                  if (response.data.problemas2 == true) {
                    this.$swal.fire("Desparasitacion entregada previamente", "", "error");
                  } else {
                    this.$swal.fire("Desparasitacion no asignada", "", "error");
                  }
                }
              });
          } else {
            $("#boxLoading").removeClass("loading");
            this.$swal.fire(
              "No se ha registrado entrega de desparasitación",
              "",
              "error"
            );
          }
          this.modal = false;
        };

        const onErrorDeUbicacion = (err) => {
          console.log(err);
          if (this.validar) {
            /* this.modal = true; */
            axios
              .post(url, {
                usuario: this.usuario,
                opcion: 5,
                ciclo: this.ciclo,
                nombre: this.datos.nombre,
                coordenada: "falta coordenada",
              })
              .then((response) => {
                $("#boxLoading").removeClass("loading");
                if (response.data.ok == true) {
                  if (response.data.problemas == true) {
                    this.$swal.fire(
                      "Asignado pero el ciclo activo actual no corresponde con el asignado",
                      "",
                      "error"
                    );
                  } else {
                    this.$swal.fire("Registrado satisfactoriamente", "", "success");
                  }
                } else {
                  //Estado de desparasitacion entregado
                  if (response.data.problemas2 == true) {
                    this.$swal.fire("Desparasitacion entregada previamente", "", "error");
                  } else {
                    this.$swal.fire("Desparasitacion no asignada", "", "error");
                  }
                }
              });
          } else {
            $("#boxLoading").removeClass("loading");
            this.$swal.fire(
              "No se ha registrado entrega de desparasitación",
              "",
              "error"
            );
          }
          this.modal = false;
          $("#boxLoading").removeClass("loading");
        };

        const opcionesDeSolicitud = {
          enableHighAccuracy: true, // Alta precisión
          maximumAge: 0, // No queremos caché
          timeout: 20000, // Esperar solo 5 segundos
        };
        // Solicitar
        navigator.geolocation.getCurrentPosition(
          onUbicacionConcedida,
          onErrorDeUbicacion,
          opcionesDeSolicitud
        );
      } else {
        $("#boxLoading").addClass("loading");
        this.$swal.fire("Geolocalización no disponible", "", "error");
      }
    },
    Modal: function () {
      this.modal2 = false;
    },
    redirrecionarAtras: function () {
      this.$router.replace({
        name: "Index",
      });
    },
    ModalClinica: function () {
      this.modalClinica = true;

      axios
        .post(url, {
          opcion: 7,

          usuario: this.usuario,
        })
        .then((response) => {
          var Div_Contenedor_Table = document.getElementById("containerTableClinica");
          Div_Contenedor_Table.innerHTML = "";
          var Datos = response.data;
          let html = `
                            <div class="row">
                                <div class="col-lg-12">
                                    <table id="Tabla_Beneficiarios" class="table-striped table-bordered" style="width:100%; font-size: 1.1rem;">
                                        <thead class="text-center">
                                            <th>Consecutivo</th>
                                            <th>DESCARGAR</th>
                                            <th>Triaje</th>
                                            
                                            <th>Signos</th>
                                            <th>Diagnostico</th>
                                            <th>Tratamiento</th>
                                            <th>Veterinario</th>
                                            <th>Fecha</th>
                                        </thead>
                                        <tbody id="Datos_Beneficiarios">
                                        </tbody>
                                    </table>
                                </div>
                            </div> 
                            `;
          Div_Contenedor_Table.append($(html)[0]);
          var Datos_Beneficiarios = document.getElementById("Datos_Beneficiarios");

          Datos.forEach((element) => {
            let html_Interno = `
                                <tr>
                                    <td>${element["REQCSC"]}</td>
                                    <td style="cursor:pointer; color:blue;" id="${
                                      element["REQCSC"] + "DESCARGAR"
                                    }">DESCARGAR</td>
                                    <td>${this.triaje[element["REQCDA"]]}</td>
                                    
                                    <td>${element["REQSIG"]}</td>
                                    <td>${element["diagnostico"]}</td>
                                    <td>${element["tratamiento"]}</td>
                                    <td>${element["Medico"]}</td>
                                    <td>${element["REQDIA"]}</td>
                                </tr>
                                `;
            Datos_Beneficiarios.append($(html_Interno)[0]);
          });
          let callback = (element) => {
            this.descargarParticular(element);
          };
          Datos.forEach((element) => {
            var td = document.getElementById(element["REQCSC"] + "DESCARGAR");
            td.addEventListener("click", function () {
              callback(element["REQCSC"]);
            });
          });

          $("#Tabla_Beneficiarios").DataTable({
            responsive: true,
            dom: "Bfrtip",
            buttons: [],
            order: [[0, "desc"]],
          });
        });
    },
    registrarHora: function () {
      if (this.RegistrarHora != true) {
        $("#boxLoading").addClass("loading");
        axios
          .post(url, {
            opcion: 8,
            consecutivo: localStorage.getItem("consecutivo"),
          })
          .then((response) => {
            localStorage.setItem(
              localStorage.getItem("consecutivo").toString().concat("registrar"),
              true
            );
            if (response.data.ok == true) {
              this.$swal.fire("Hora Registrada exitosamente", "", "success");
            } else {
              this.$swal.fire("Error ya registro hora", "", "error");
            }
            $("#boxLoading").removeClass("loading");
          });
      } else {
        this.$swal.fire("Error ya registro hora", "", "error");
      }
      this.NOTIFICAR = "#efefef";
      this.CREAR = "#4451b4";
      this.CREARCOLOR = "white";
      this.RegistrarHora = true;
    },
    crearHistoria: function () {
      if (this.RegistrarHora == true) {
        this.$swal
          .fire({
            title: "Desea Crear Historia Clinica",
            showCancelButton: true,
            confirmButtonText: `Aceptar`,
            cancelButtonText: `Cancelar`,
            reverseButtons: true,
          })
          .then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              if (
                !localStorage.getItem(
                  localStorage.getItem("consecutivo").toString().concat("hora")
                )
              ) {
                var hora = new Date();
                var texto = "".concat(
                  hora.getHours().toString().length > 1
                    ? hora.getHours().toString()
                    : "0".concat(hora.getHours().toString()),
                  ":",
                  hora.getMinutes().toString().length > 1
                    ? hora.getMinutes().toString()
                    : "0".concat(hora.getMinutes().toString())
                );
                localStorage.setItem(
                  localStorage.getItem("consecutivo").toString().concat("hora"),
                  texto
                );
              }
              this.$router.replace({
                name: "Previa",
              });
            }
          });
      } else {
        this.$swal.fire("Aun no has registrado la hora", "", "error");
      }
    },
    pClinica: function () {
      var prueba = document.getElementById("content");

      if (prueba.offsetHeight > window.innerHeight) {
        $("#dialog").addClass("rodar");
        $("#content").addClass("rodar");
      } else {
        $("#dialog").removeClass("rodar");
        $("#content").removeClass("rodar");
      }
    },
    pClinica2: function () {
      this.pClinica();
      this.modalClinica = false;
    },
    pModal2: function () {
      var prueba = document.getElementById("content");

      if (prueba.offsetHeight > window.innerHeight) {
        $("#dialog").addClass("rodar");
        $("#content").addClass("rodar");
      } else {
        $("#dialog").removeClass("rodar");
        $("#content").removeClass("rodar");
      }
    },
    pModal22: function () {
      this.pModal2();
      this.modal2 = false;
    },
    subir: function () {
      $("#boxLoading").addClass("loading");
      // var formData = new FormData();
      var documento = this.documento;
      var usuario = this.usuario;
      // var contador = 1;
      const MAX_WIDTH = 300;
      const MAX_HEIGHT = 300;
      /* const MIME_TYPE = "image/jpeg";
            const QUALITY = 0.7; */
      const file = document.getElementById("file").files[0];
      const blobURL = URL.createObjectURL(file);
      const img = new Image();
      img.src = blobURL;
      img.onload = function () {
        URL.revokeObjectURL(this.src);
        let width = img.width;
        let height = img.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
          if (width > MAX_WIDTH) {
            height = Math.round((height * MAX_WIDTH) / width);
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width = Math.round((width * MAX_HEIGHT) / height);
            height = MAX_HEIGHT;
          }
        }

        const [newWidth, newHeight] = [width, height];
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        axios
          .post(url4, {
            id: localStorage.getItem("consecutivo"),
            documento: documento,
            imgBase64: canvas.toDataURL(),
            usuario: usuario,
          })
          .then(() => {
            document.getElementById("root").innerHTML = "";
            document.getElementById("root").append(canvas);
            $("#boxLoading").removeClass("loading");
            this.$swal.fire("Imagen Registrada", "", "success");
          });
      };

      /* formData.append('hola', documento);
            formData.append('asd', localStorage.getItem("consecutivo")); */

      /* axios.post(url2, {
                id: localStorage.getItem("consecutivo"),
                form: formData,
                documento: documento

            }) */
    },
    guardarModalPeso: function () {
      axios
        .post(url, {
          opcion: 21,
          peso: this.peso,
          usuario: this.usuario,
        })
        .then(() => {
          this.$swal.fire("Peso Registrado", "", "success");
          this.modalPeso = false;
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("peso"),
            this.peso
          );
        });
    },
    registrarVacuna: function () {
      this.$router.replace({
        name: "Vacuna",
      });
    },
    descargar() {
      this.Mostrar = false;
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 36,
          consecutivo: localStorage.getItem("consecutivo"),
        })
        .then((response) => {
          console.log("HOLA");

          if (response.data == 2) {
            axios({
              url: url,

              method: "POST",
              data: {
                consecutivo: localStorage.getItem("consecutivo"),
                opcion: 35,
                documento: this.datos.documento,
              },

              responseType: "blob",
            }).then((response) => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));

              var fileLink = document.createElement("a");

              fileLink.href = fileURL;

              fileLink.setAttribute("download", "historia.pdf");

              document.body.appendChild(fileLink);
              $("#boxLoading").removeClass("loading");
              fileLink.click();
            });
          } else {
            $("#boxLoading").removeClass("loading");
            this.$swal.fire(
              "Aun no completa su historia medica, pdf no habilitado",
              "",
              "error"
            );
          }
        });
    },
    actualizarDosis() {
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 39,
          dosis: this.selectedDosis.clave,
          usuario: this.usuario,
        })
        .then(() => {
          this.$swal.fire("Dosis Actualizada!", "", "success");
          $("#boxLoading").removeClass("loading");
        });
    },
    actualizarRaza() {
      $("#boxLoading").addClass("loading");

      var temporal = "";
      this.razas.forEach((element) => {
        if (element.valor == this.selectedRaza) {
          temporal = element.clave;
        }
      });

      axios
        .post(url, {
          opcion: 41,
          raza: temporal,
          usuario: this.usuario,
        })
        .then(() => {
          this.$swal.fire("Raza Actualizada!", "", "success");
          $("#boxLoading").removeClass("loading");
        });
    },
    say: function (message) {
      alert(message);
    },
    descargarParticular(csc) {
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 36,
          consecutivo: csc,
        })
        .then((response) => {
          console.log("HOLA");
          if (response.data == 2) {
            axios({
              url: url,

              method: "POST",
              data: {
                consecutivo: csc,
                opcion: 35,
                documento: this.datos.documento,
              },

              responseType: "blob",
            }).then((response) => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));

              var fileLink = document.createElement("a");

              fileLink.href = fileURL;

              fileLink.setAttribute("download", "historia.pdf");

              document.body.appendChild(fileLink);
              $("#boxLoading").removeClass("loading");
              fileLink.click();
            });
          } else {
            $("#boxLoading").removeClass("loading");
            this.$swal.fire(
              "Aun no completa su historia medica, pdf no habilitado",
              "",
              "error"
            );
          }
        });
    },
    guardarModalEspecie() {
      $("#boxLoading").addClass("loading");

      this.modalEspecie = false;

      this.especies.forEach((element) => {
        if (element.valor == this.especie) {
          axios
            .post(url, {
              opcion: 42,
              especie: element.clave,
              usuario: this.usuario,
            })
            .then((response) => {
              this.razas = response.data;
              this.selectedRaza = this.raza;
              this.$swal.fire("Especie Actualizada!", "", "success");
              $("#boxLoading").removeClass("loading");
            });
        }
      });
    },
    openBasic() {
      this.modalEspecie = true;
    },
    closeBasic() {
      this.modalEspecie = false;
    },
    guardarModalEdad() {
      this.modalEdad = false;
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 43,
          usuario: this.usuario,
          fecha: this.edadCambiar,
        })
        .then((response) => {
          this.edad = response.data.edad
          this.$swal.fire("Edad Actualizada!", "", "success");
          $("#boxLoading").removeClass("loading");
        });
    },
    guardarModalRaza(){
      this.modalRaza = false;
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 45,
         
          raza: this.razaAgregar,
        })
        .then((response) => {
          this.razas = response.data;
          this.selectedRaza = this.raza;
          this.$swal.fire("Raza Agregada!", "", "success");
          $("#boxLoading").removeClass("loading");
        });
    }
  },
};
</script>

<style>
h1 {
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  top: -10px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

h1 {
  color: white;
}

.listas {
  padding: 10px;
  border-bottom: black solid 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.listas:hover {
  -webkit-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
}

.texto {
  margin-left: 1rem;
}

.boton {
  margin-left: auto;
  color: white;
  margin-right: 2rem !important;
  width: 4rem;
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
  opacity: 0.8;
}

.dialog2 {
  max-width: 90vw !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before {
  background-color: cornflowerblue !important;
}

table.dataTable thead {
  background: linear-gradient(to right, #0575e6, #00f260);
  color: white;
}

table,
th,
td {
  border: 1px solid black !important;
}

td {
  text-align: center;
}

button {
  border-radius: 10px !important;
}

.rodar {
  height: 100vh;
  overflow-y: scroll;
}
button.swal2-styled {
  padding: 0;
}
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
  opacity: 0.8;
}
.el-select .el-select__tags-text {
  font-size: 120%;
}
.p-inputtext {
  font-size: 1.3rem !important;
}
.el-input__inner {
  font-size: 1.3rem !important;
}
.el-select-dropdown__item {
  font-size: 1.2rem !important;
}
.form-group {
  display: flex;
  justify-content: center;
}
.swal2-container {
  z-index: 999999 !important;
}
</style>
