<template>
  <div id="nav">
    <router-view/>
  </div>
  
</template>

<script>
//Hola
//Recuerda que todos los comentarios del código iran respectivamente al inicio de la variable, funcion o procedimiento
//NOTA: En este apartado se dará un breve descripción de las funcionalidades, ya será labor de la consulta en el back o el procedimiento 
//      en el front de ampliarla
//El flujo del programa esta determinado de la siguiente forma
//1) Login -> Home.vue
//2) Primera vista listar consecutivos: Esta vista mostrara todos los consecutivos asociados a una movil, que no hubiera terminado
//   previamente, esto es decir que el campo reqenp sea igual a cero -> index.vue
//3) Segunda vista mostrar datos primarios consecutivo: En esta vista mostraremos algunos datos relacionados con el usuario. Esta vista tendrá las siguientes
//   funcionalidades: Entregar Dosis Desparasitación, consultar bitacora de vacunación, consultar historias clinicas, notificar hora de llegada, crear historia clinica
//   y devolverse a la primera vista. -> consecutivos.vue
//4) Tercera vista paso 1 crear historia clinica: En esta vista se le mostrara al usuario los primeros campos a llenar en su historia clinica-> HistoriasPrevia.vue
//5) Cuarta vista paso 2 crear historia clinica: En esta vista se le mostrara al usuario aún mas campos para llenar en su historia clinica.
//   Con la diferencia de que aquí hay funcionalidades las cuales son: Notificar salida del traslado, notificar llegada institución, notificar salida institución
//   , subir foto consulta, firmar consulta y finalizar consulta -> Historias.vue
//6) Quinta vista finalizar: Esta vista en la cual se finaliza completamente la consulta, tiene como funcionalidades las siguientes: Enviar correo o modificar correo,
//   enviar historia clinica y notificar salida HistoriasFinal.vue
</script>

