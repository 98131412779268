<template>
  <div>
    <div class="about" style="background: #4451b4">
      <div class="w-100 d-flex align-items-center">
        <Nav msg="Historias Clinicas RMV" />
        <div class="dropdown">
          <fa
            @click="Mostrar = true"
            style="
              cursor: pointer;
              position: absolute;
              right: 0%;
              margin-right: 10px;
              color: white;
              font-size: 1.1rem;
            "
            id="ellipsis-v"
            icon="ellipsis-v"
          />
          <div v-if="Mostrar" class="dropdown-content">
            <p style="cursor: pointer; color: black" @click="salir()">Salir</p>
          </div>
        </div>
      </div>
    </div>
    <div id="boxLoading"></div>
    <div class="container mt-3">
      <div class="w-100 d-flex justify-content-center mb-3">
        <el-switch
          v-model="activo"
          active-text="Registra Factura"
          inactive-text="No Registra Factura"
          @change="corregirFecha"
        >
        </el-switch>
      </div>
      <div
        class="card-header"
        style="
          background: rgba(0, 0, 0, 0.03);
          padding-left: 1rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        "
      >
        Registrar Vacuna
      </div>
      <div class="card-body" style="padding-left: 1rem">
        <div class="row">
          <div class="col-12 col-md-4 mb-4">
            Fecha De Vacunación
            <br />
            <input
              type="date"
              id="fechaServicio"
              name="prox-fecha"
              class="form-control"
              :disabled="activo"
            />
          </div>
          <div class="col-12 col-md-4 mb-4">
            Nombre de producto
            <br />
            <el-select
              class="w-100"
              v-model="productoSelected"
              filterable
              placeholder="Seleccionar"
            >
              <el-option
                v-for="item in productos"
                :key="item.valor"
                :label="item.valor"
                :value="item.valor"
                @click="fechas_valores(item.json)"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-12 col-md-4 mb-4">
            Lote
            <br />
            <el-input v-model="lote" placeholder="Lote" />
          </div>
          <div class="col-12 col-md-4 mb-4">
            Próxima fecha del servicio
            <br />
            <input
              type="date"
              id="proximaFechaServicio"
              name="prox-fecha"
              class="form-control"
            />
          </div>
          <div class="col-12 col-md-4 mb-4">
            Valor cancelado:
            <br />
            <InputText
              class="w-100"
              type="text"
              v-model="valorCancelado"
              :disabled="true"
              placeholder="valor cancelado"
            />
          </div>
          <div class="col-12 col-md-4 mb-4">
            Tipo de pago:
            <br />
            <el-select
              class="w-100"
              v-model="tipoPago"
              filterable
              placeholder="Seleccionar"
            >
              <el-option
                v-for="item in tipoPagos"
                :key="item.valor"
                :label="item.valor"
                :value="item.valor"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-12 col-md-5 mb-4">
            Peso:
            <span style="font-size: 1.2rem; cursor: pointer" @click="modalPeso = true">
              <fa style="cursor: pointer; right: auto" icon="upload" />
            </span>
            <br />
            <InputText
              class="w-100"
              type="text"
              v-model="peso"
              :disabled="true"
              placeholder="peso"
            />
          </div>
          <div class="col-md-2">
            <div @click.prevent="showFileChooser" class="image_area">
              <label
                for="upload_image"
                style="border: 1px solid black; border-radius: 15px; overflow: hidden"
              >
                <img
                  src="../assets/images.svg"
                  id="imagenRecortar"
                  class="img-responsive w-100"
                  alt=""
                />
                <div class="overlay">
                  <div class="text">Sello</div>
                </div>
              </label>
            </div>
            <input
              ref="file"
              type="file"
              name="image"
              accept="image/*"
              style="display: none"
              @change="loadImage($event)"
            />
          </div>
          <div class="col-12 col-md-5 mb-4" v-if="mostrar">
            Tipo de vacunación:
            <br />
            <el-select
              @change="tipoVacunacion()"
              class="w-100"
              v-model="pago"
              filterable
              placeholder="Seleccionar"
            >
              <el-option
                v-for="item in pagos"
                :key="item.valor"
                :label="item.valor"
                :value="item.valor"
              >
              </el-option>
            </el-select>
          </div>
          <Dialog
            header="Recortar Imagen"
            v-model:visible="modal"
            :style="{ width: '100vw' }"
          >
            <div class="img-container">
              <div class="row">
                <div class="col-md-8">
                  <div class="cropper-wrapper">
                    <cropper
                      class="cropper"
                      :auto-zoom="true"
                      :src="image.src"
                      :default-size="defaultSize"
                      @change="change"
                    />
                  </div>
                </div>
                <img
                  style="
                    border: red solid 1px;
                    height: 25vh;
                    width: 40vw;
                    margin-top: 10px;
                    margin-left: 50%;
                  "
                  :src="img2"
                  alt=""
                />
              </div>
            </div>
            <template #footer>
              <Button
                label="Cerrar"
                icon="pi pi-times"
                @click="modal = false"
                class="p-button-text"
              />
              <Button
                label="Recortar"
                icon="pi pi-pencil"
                @click="crop"
                class="p-button-text"
              />
            </template>
          </Dialog>
        </div>
      </div>
    </div>
    <div style="font-size: 1.3rem; width: 80%; margin-left: 10%; margin-right: 10%">
      <p class="mb-2">
        <b> Observaciones </b>
      </p>
      <Textarea
        style="font-size: 1.3rem"
        class="w-100"
        v-model="observaciones"
        :autoResize="true"
        rows="2"
        cols="30"
      />
    </div>
    <hr />
    <div class="container mt-3">
      <div class="card-body" style="padding-left: 1rem">
        <div class="row">
          <div class="col-12 col-md-3 mb-4">
            Primer Nombre
            <br />
            <el-input v-model="nombre1" placeholder="Primer Nombre" />
          </div>
          <div class="col-12 col-md-3 mb-4">
            Segundo Nombre
            <br />
            <el-input v-model="nombre2" placeholder="Segundo Nombre" />
          </div>
          <div class="col-12 col-md-3 mb-4">
            Primer Apellido
            <br />
            <el-input v-model="apellido1" placeholder="Primer Apellido" />
          </div>
          <div class="col-12 col-md-3 mb-4">
            Segundo Apellido
            <br />
            <el-input v-model="apellido2" placeholder="Segundo Apellido" />
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button
        @click="registrar()"
        style="
          background: #2196f3;
          border-radius: 10px;
          margin-bottom: 1rem;
          color: white;
          width: 50%;
          position: relative;
          top: 5px;
        "
      >
        REGISTRAR DATOS
      </button>
    </div>
    <div class="d-flex w-100 justify-content-center align-items-center flex-column">
      <button
        @click="redirrecionarAtras()"
        style="
          background: #2196f3;
          border-radius: 10px;
          margin-bottom: 1rem;
          color: white;
          width: 90%;
          position: relative;
          top: 5px;
        "
      >
        ATRÁS
      </button>
    </div>
    <div v-if="modalPeso">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header w-100">
                  <div class="d-flex w-100" style="justify-content: space-evenly">
                    <div style="padding-left: 5%">
                      <h1 style="color: black">Ingrese el peso</h1>
                      <input
                        maxlength="5"
                        v-model="peso"
                        class="w-100"
                        style="
                          border: 0;
                          padding: 1rem;
                          border-bottom: 1px solid #ced4da;
                          height: 42px;
                        "
                        type="number"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <button
                      @click="modalPeso = false"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                    <button
                      @click="guardarModalPeso()"
                      style="
                        left: 10px;
                        color: green;
                        widht: 100px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      GUARDAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div id="boxLoading"></div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = "";
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}
import axios from "axios";
import moment from "moment";
import $ from "jquery";

var url = "/php/crudVentas.php";
var url2 = "/php/crud.php";
export default {
  name: "NuevaVacuna",
  components: {
    Nav,
    Cropper,
  },
  data() {
    return {
      datos: JSON.parse(localStorage.getItem("datos")),
      Mostrar: false, //Estado visibilidad menu
      consecutivos: [], //Listado de consecutivos asignados a la movil
      colores: ["red", "#c4c416", "#0073ff", "green", "grey"], //Colores que usaremos variando el triaje
      colores2: ["#8080808c", "white", "#dfe75e"], //Colores que usaremos de fondo dependiendo el reqreg
      reqord: [], //Dato que mostraremos después del consecutivo
      coloresAplicar: [], //Colores que aplicaremos a los botones
      coloresAplicar2: [], //Colores que aplicaremos al background
      texto: [], //Texto ue aplicaremos a los botones
      consecutivosMostrar: false, //Estado visibilidad texto no tiene consecutivos asignados
      textoLe: [],
      productoSelected: "",
      productos: [],
      valorCancelado: "",
      pagos: [
        {
          valor: "Primaria",
          clave: "1",
        },
        {
          valor: "Revacunación",
          clave: "2",
        },
      ],
      pago: "",
      tipoPagos: [
        {
          valor: "Efectivo",
          clave: "1",
        },
        {
          valor: "PSE",
          clave: "2",
        },
        {
          valor: "Transferencia",
          clave: "3",
        },
      ],
      tipoPago: "",
      modal: false,
      cropImg: "",
      image: {
        src: null,
        type: null,
      },
      img2: "",
      cropedIMG: "",
      activo: false,
      datosVacunas: [],
      mostrar: true,
      peso: "",
      observaciones: "",
      isCropped: false,
      raza: "",
      especie: "",
      sexo: "",
      edad: "",
      telefono: "",
      usuario: "",
      idProducto: "",
      nombreMascota: "",
      documentoUsuario: "",
      srcImage: "",
      imgaux: "",
      modalPeso: false,
      lote: "",
      nombre1: "",
      nombre2: "",
      apellido1: "",
      apellido2: "",
      ID_Titular: "",
    };
  },
  async mounted() {
    $("#boxLoading").addClass("loading");

    var today = moment().format("YYYY-MM-DD");

    document.getElementById("fechaServicio").value = today;
    document.getElementById("proximaFechaServicio").value = today;
    var prueba = localStorage.getItem("prueba");
    if (prueba === null) {
      this.$router.replace({
        name: "Home",
      });
    }
    //Si no existe prueba regresaremos al usuario al login

    document.body.style.backgroundColor = "white";
    //Cambiamos el color del background
    await axios
      .post(url2, {
        consecutivo: localStorage.getItem("consecutivo"),
        opcion: 3,
      })
      .then((response) => {
        var Datos = response.data.data[0];
        this.peso = Datos.peso;
        this.raza = Datos.Raza;
        this.ID_Titular = Datos.ID_Titular;
        this.especie = Datos.especie;

        var nombre = Datos.titnom.split(" ");
        console.log(nombre);
        for (let index = 0; index < nombre.length; index++) {
          switch (index) {
            case 0:
              this.apellido1 = Datos.apellido1 ?? nombre[index];

              break;
            case 1:
              this.apellido2 = Datos.apellido2 ?? nombre[index];

              break;
            case 2:
              this.nombre1 = Datos.nombre1 ?? nombre[index];

              break;
            case 3:
              this.nombre2 = Datos.nombre2 ?? nombre[index];

              break;
            default:
              this.nombre2 += " " + nombre[index];
              break;
          }
        }

        this.usuario = Datos.ID_Ben;
        this.nombreMascota = Datos.bennm1;

        axios
          .post(url2, {
            consecutivo: localStorage.getItem("consecutivo"),
            opcion: 4,
          })
          .then((response) => {
            var Datos = response.data.data[0];
            this.edad = Datos.edad;
            this.telefono = Datos.bentel;
            this.sexo = Datos.bensex;
            this.documentoUsuario = Datos.bendoc;

            $("#boxLoading").removeClass("loading");
          });
      });
    axios
      .post(url, {
        opcion: 1,
        consecutivo: localStorage.getItem("consecutivo"),
      })
      .then((response) => {
        this.srcImage = document.getElementById("imagenRecortar").src;
        var especieIntera = this.especie == "Felino" ? 12 : 11;
        console.log(response.data);
        this.datosVacunas = response.data;
        response.data.forEach((element) => {
          if (element.Categoria == especieIntera) {
            this.productos.push({
              clave: element.IdProducto,
              valor: element.Nombre,
              json: element,
            });
          } else {
            if (element.IdProducto == "1") {
              this.productos.push({
                clave: element.IdProducto,
                valor: element.Nombre,
                json: element,
              });
            }
          }
        });
      });
    document.addEventListener("click", (event) => {
      if (event.path[0].id != "ellipsis-v") {
        this.Mostrar = false;
      }
    });
  },
  methods: {
    salir: function () {
      this.$router.replace({
        name: "Home",
      });
      //Esta función sirve para redigir al usuario al login
    },
    redirrecionarAtras: function () {
      this.$router.replace({
        name: "Vacuna",
      });
    },
    NuevaVacuna: function () {
      this.$router.replace({
        name: "NuevaVacuna",
      });
    },
    showFileChooser() {
      this.$refs.file.click();
      console.log("HOLA");
    },
    async loadImage(event) {
      // Reference to the DOM input element
      console.log(event);
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const MAX_WIDTH = 300;
        const MAX_HEIGHT = 300;
        /* const MIME_TYPE = "image/jpeg";
            const QUALITY = 0.7; */
        const file = files[0];
        console.log(files);
        const blobURL = URL.createObjectURL(file);
        const img = new Image();
        img.src = blobURL;
        let callback = (image) => {
          console.log(image);
          let dataUrl = image.split(",");
          let base64 = dataUrl[1];
          let mime = dataUrl[0].match(/:(.*?);/)[1];
          let bin = atob(base64);
          let length = bin.length;
          let buf = new ArrayBuffer(length);
          let arr = new Uint8Array(buf);
          bin.split("").forEach((e, i) => (arr[i] = e.charCodeAt(0)));
          let f = new File([buf], "filename", { type: mime });
          console.log(f);

          const blob = URL.createObjectURL(f);

          const reader = new FileReader();
          // Define a callback function to run, when FileReader finishes its job
          reader.onload = (e) => {
            // Note: arrow function used here, so that "this.image" refers to the image of Vue component
            this.image = {
              // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
              src: blob,
              // Determine the image type to preserve it during the extracting the image from canvas:
              type: getMimeType(e.target.result, f.type),
            };
          };
          // Start the reader job - read file as a data url (base64 format)
          reader.readAsArrayBuffer(f);
          this.modal = true;
        };
        img.onload = function () {
          URL.revokeObjectURL(this.src);
          let width = img.width;
          let height = img.height;

          // calculate the width and height, constraining the proportions
          if (width > height) {
            if (width > MAX_WIDTH) {
              height = Math.round((height * MAX_WIDTH) / width);
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = Math.round((width * MAX_HEIGHT) / height);
              height = MAX_HEIGHT;
            }
          }

          const [newWidth, newHeight] = [width, height];
          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          callback(canvas.toDataURL());
        };
      }
    },
    change({ coordinates, canvas }) {
      console.log(coordinates, canvas);
      this.img2 = canvas.toDataURL();
    },
    crop() {
      document.getElementById("imagenRecortar").src = this.img2;
      localStorage.setItem("pruebaImagen", this.img2);
      this.modal = false;
      this.isCropped = true;
    },
    fechas_valores(item) {
      this.IdProducto = item.IdProducto;

      this.mostrar = true;
      var today = moment().format("YYYY-MM-DD");
      document.getElementById("proximaFechaServicio").value = today;
      this.pago = "";
      this.valorCancelado = item.precio;
    },
    tipoVacunacion() {
      if (this.pago == "Primaria") {
        var fechaProxima = moment().add(15, "days").format("YYYY-MM-DD");

        document.getElementById("proximaFechaServicio").value = fechaProxima;
      } else {
        var fechaProxima2 = moment().add(1, "year").format("YYYY-MM-DD");

        document.getElementById("proximaFechaServicio").value = fechaProxima2;
      }
    },
    registrar() {
      if (this.activo == false) {
        if (this.mostrar == false) {
          console.log(this.tipoPago, this.isCropped);
          if (this.tipoPago == "" || this.isCropped == false || this.lote == "") {
            this.$swal.fire("Faltan datos", "", "error");
          } else {
            $("#boxLoading").addClass("loading");

            axios
              .post(url, {
                opcion: 2,
                peso: this.peso,
                edad: this.edad,
                telefono: this.telefono,
                sexo: this.sexo,
                especie: this.especie == "Felino" ? 2 : 1,
                consecutivo: localStorage.getItem("consecutivo"),
                fechaProxima: document.getElementById("proximaFechaServicio").value,
                valorCancelado: parseInt(this.valorCancelado),
                producto: this.productoSelected,
                tipoVacunacion: this.pago,
                veterinario: this.datos.Id,
                usuario: this.usuario,
                productoID: this.IdProducto,
                observaciones: this.observaciones,
                tipoPago: this.tipoPago,
                imagen: document.getElementById("imagenRecortar").src,
                nombreMascota: this.nombreMascota,
                veterinarioNombre: this.datos.nombre,
                documentoUsuario: this.documentoUsuario,
                raza: this.raza,
                fechaServicio: document.getElementById("fechaServicio").value,
                lote: this.lote,
                registraFactura: 0,
                nombre1: this.nombre1,
                nombre2: this.nombre2,
                apellido1: this.apellido1,
                apellido2: this.apellido2,
                ID_Titular: this.ID_Titular,
              })
              .then((response) => {
                console.log(response);
                $("#boxLoading").removeClass("loading");
                this.$swal
                  .fire({
                    title: "Vacuna Registrada <br>¿Desea registrar una nueva vacuna?",
                    showCancelButton: true,
                    confirmButtonText: `Registrar`,
                    cancelButtonText: `Finalizar`,
                    reverseButtons: true,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  })
                  .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      this.tipoPago = "";
                      this.productoSelected = "";
                      this.pago = "";
                      this.isCropped = false;
                      document.getElementById("imagenRecortar").src = this.srcImage;
                      this.observaciones = "";
                    } else {
                      this.$router.replace({
                        name: "Index",
                      });
                    }
                  });
              });
          }
        } else {
          if (
            this.tipoPago == "" ||
            this.productoSelected == "" ||
            this.pago == "" ||
            this.isCropped == false ||
            this.lote == ""
          ) {
            this.$swal.fire("Faltan datos", "", "error");
          } else {
            $("#boxLoading").addClass("loading");

            axios
              .post(url, {
                opcion: 2,
                peso: this.peso,
                edad: this.edad,
                telefono: this.telefono,
                sexo: this.sexo,
                especie: this.especie == "Felino" ? 2 : 1,
                consecutivo: localStorage.getItem("consecutivo"),
                fechaProxima: document.getElementById("proximaFechaServicio").value,
                valorCancelado: parseInt(this.valorCancelado),
                producto: this.productoSelected,
                tipoVacunacion: this.pago,
                veterinario: this.datos.Id,
                usuario: this.usuario,
                productoID: this.IdProducto,
                observaciones: this.observaciones,
                tipoPago: this.tipoPago,
                imagen: document.getElementById("imagenRecortar").src,
                nombreMascota: this.nombreMascota,
                veterinarioNombre: this.datos.nombre,
                documentoUsuario: this.documentoUsuario,
                raza: this.raza,
                fechaServicio: document.getElementById("fechaServicio").value,
                lote: this.lote,
                registraFactura: 0,
                nombre1: this.nombre1,
                nombre2: this.nombre2,
                apellido1: this.apellido1,
                apellido2: this.apellido2,
                ID_Titular: this.ID_Titular,
              })
              .then((response) => {
                console.log(response);
                $("#boxLoading").removeClass("loading");
                this.$swal
                  .fire({
                    title: "Vacuna Registrada <br>¿Desea registrar una nueva vacuna?",
                    showCancelButton: true,
                    confirmButtonText: `Registrar`,
                    cancelButtonText: `Finalizar`,
                    reverseButtons: true,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  })
                  .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      this.tipoPago = "";
                      this.productoSelected = "";
                      this.pago = "";
                      this.isCropped = false;
                      document.getElementById("imagenRecortar").src = this.srcImage;
                      this.observaciones = "";
                    } else {
                      this.$router.replace({
                        name: "Index",
                      });
                    }
                  });
              });
          }
        }
      } else {
        if (this.mostrar == false) {
          console.log(this.tipoPago, this.isCropped);
          if (this.tipoPago == "" || this.isCropped == false || this.lote == "") {
            this.$swal.fire("Faltan datos", "", "error");
          } else {
            $("#boxLoading").addClass("loading");

            axios
              .post(url, {
                opcion: 2,
                peso: this.peso,
                edad: this.edad,
                telefono: this.telefono,
                sexo: this.sexo,
                especie: this.especie == "Felino" ? 2 : 1,
                consecutivo: localStorage.getItem("consecutivo"),
                fechaProxima: document.getElementById("proximaFechaServicio").value,
                valorCancelado: parseInt(this.valorCancelado),
                producto: this.productoSelected,
                tipoVacunacion: this.pago,
                veterinario: this.datos.Id,
                usuario: this.usuario,
                productoID: this.IdProducto,
                observaciones: this.observaciones,
                tipoPago: this.tipoPago,
                imagen: document.getElementById("imagenRecortar").src,
                nombreMascota: this.nombreMascota,
                veterinarioNombre: this.datos.nombre,
                documentoUsuario: this.documentoUsuario,
                raza: this.raza,
                fechaServicio: document.getElementById("fechaServicio").value,
                lote: this.lote,
                registraFactura: 1,
                nombre1: this.nombre1,
                nombre2: this.nombre2,
                apellido1: this.apellido1,
                apellido2: this.apellido2,
                ID_Titular: this.ID_Titular,
              })
              .then((response) => {
                console.log(response);
                $("#boxLoading").removeClass("loading");
                this.$swal
                  .fire({
                    title: "Vacuna Registrada <br>¿Desea registrar una nueva vacuna?",
                    showCancelButton: true,
                    confirmButtonText: `Registrar`,
                    cancelButtonText: `Finalizar`,
                    reverseButtons: true,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  })
                  .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      this.tipoPago = "";
                      this.productoSelected = "";
                      this.pago = "";
                      this.isCropped = false;
                      document.getElementById("imagenRecortar").src = this.srcImage;
                      this.observaciones = "";
                    } else {
                      this.$router.replace({
                        name: "Index",
                      });
                    }
                  });
              });
          }
        } else {
          if (
            this.tipoPago == "" ||
            this.productoSelected == "" ||
            this.pago == "" ||
            this.isCropped == false ||
            this.lote == ""
          ) {
            this.$swal.fire("Faltan datos", "", "error");
          } else {
            $("#boxLoading").addClass("loading");

            axios
              .post(url, {
                opcion: 2,
                peso: this.peso,
                edad: this.edad,
                telefono: this.telefono,
                sexo: this.sexo,
                especie: this.especie == "Felino" ? 2 : 1,
                consecutivo: localStorage.getItem("consecutivo"),
                fechaProxima: document.getElementById("proximaFechaServicio").value,
                valorCancelado: parseInt(this.valorCancelado),
                producto: this.productoSelected,
                tipoVacunacion: this.pago,
                veterinario: this.datos.Id,
                usuario: this.usuario,
                productoID: this.IdProducto,
                observaciones: this.observaciones,
                tipoPago: this.tipoPago,
                imagen: document.getElementById("imagenRecortar").src,
                nombreMascota: this.nombreMascota,
                veterinarioNombre: this.datos.nombre,
                documentoUsuario: this.documentoUsuario,
                raza: this.raza,
                fechaServicio: document.getElementById("fechaServicio").value,
                lote: this.lote,
                registraFactura: 1,
                nombre1: this.nombre1,
                nombre2: this.nombre2,
                apellido1: this.apellido1,
                apellido2: this.apellido2,
                ID_Titular: this.ID_Titular,
              })
              .then((response) => {
                console.log(response);
                $("#boxLoading").removeClass("loading");
                this.$swal
                  .fire({
                    title: "Vacuna Registrada <br>¿Desea registrar una nueva vacuna?",
                    showCancelButton: true,
                    confirmButtonText: `Registrar`,
                    cancelButtonText: `Finalizar`,
                    reverseButtons: true,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                  })
                  .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      this.tipoPago = "";
                      this.productoSelected = "";
                      this.pago = "";
                      this.isCropped = false;
                      document.getElementById("imagenRecortar").src = this.srcImage;
                      this.observaciones = "";
                    } else {
                      this.$router.replace({
                        name: "Index",
                      });
                    }
                  });
              });
          }
        }
      }
    },
    guardarModalPeso: function () {
      axios
        .post(url2, {
          opcion: 21,
          peso: this.peso,
          usuario: this.usuario,
        })
        .then(() => {
          this.$swal.fire("Peso Registrado", "", "success");
          this.modalPeso = false;
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("peso"),
            this.peso
          );
        });
    },
    corregirFecha() {
      var today = moment().format("YYYY-MM-DD");

      document.getElementById("fechaServicio").value = today;
      document.getElementById("proximaFechaServicio").value = today;
      this.productoSelected = "";
      this.valorCancelado = "";
      this.pago = "";
      this.tipoPago = "";
      this.lote = "";
    },
  },
};
</script>

<style>
h1 {
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  top: -10px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

h1 {
  color: white;
}

.listas {
  padding: 10px;
  border-bottom: black solid 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.listas:hover {
  -webkit-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
  opacity: 0.8;
}

.image_area {
  position: relative !important;
}

.image_area:hover .overlay {
  height: 50% !important;
  cursor: pointer !important;
}

.text {
  color: #333 !important;
  font-size: 20px !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  text-align: center !important;
}

.overlay {
  position: absolute !important;
  bottom: 10px !important;
  left: 0 !important;
  right: 0 !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  overflow: hidden !important;
  height: 0 !important;
  transition: 0.5s ease !important;
  width: 100% !important;
}

.image_area:hover .overlay {
  height: 50% !important;
  cursor: pointer !important;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.el-select-dropdown__item {
  font-size: 1.2rem !important;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}
.el-select .el-select__tags-text {
  font-size: 120%;
}
.p-inputtext {
  font-size: 1.3rem !important;
}
.el-input__inner {
  font-size: 1.3rem !important;
}
.cropper {
  height: 40vh !important;
  width: 90vw !important;
  background: #ddd;
}
.form-group {
  display: flex;
  justify-content: center;
}
</style>
