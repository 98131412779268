<template>
<div   class=" w-100 pt-2 pb-2">
    <h1 @click="redirigir()" style="cursor:pointer; width:80%;margin-left:10%;margin-right:10%;" class=" text-center">{{ msg }}</h1>
</div>
</template>

<script>
//Este es un componente que usaremos en cada vista para dar un titulo 
export default {
    name: 'Nav',
    props: {
        msg: String
    },
    methods: {
        redirigir(){
            this.$router.replace({
                name: 'Index'
            });
        }
    }
}
</script>

<style scoped>

</style>
