<template>
  <div>
    <div class="about" style="background: #4451b4">
      <div class="w-100 d-flex align-items-center">
        <div class="w-100 pt-2 pb-2">
          <h1
            @click="redirigir()"
            style="cursor: pointer; width: 80%; margin-left: 10%; margin-right: 10%"
            class="text-center"
          >
            Historias Clinicas RMV
          </h1>
        </div>
        <div class="dropdown">
          <fa
            @click="Mostrar = true"
            id="ellipsis-v"
            style="
              cursor: pointer;
              position: absolute;
              right: 0%;
              margin-right: 10px;
              color: white;
              font-size: 1.1rem;
            "
            icon="ellipsis-v"
          />
          <div v-if="Mostrar" class="dropdown-content">
            <p style="cursor: pointer; color: black" @click="salir()">Salir</p>
          </div>
        </div>
      </div>
      <!--
            Esta seccion aquí nos sirve para desplegar un mini menu que mostrara la opción para salir
        -->
    </div>
    <div
      @click="enviar(key)"
      style="border-bottom: #c0c4cc solid 1px; padding: 0.5rem;display: flex;justify-content: space-between;"
      class="hover"
      v-for="key in array"
      :key="key"
    >
      <p class="m-0 p-0">{{ key }}</p>
      <button @click.stop="descargar(key)">DESCARGAR</button>
    </div>
    <div id="boxLoading"></div>
    <!--Este boton sirve para recargar los datos de visualización de los consecutivos-->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";

var url = "/php/crud.php";
export default {
  name: "Listar",

  data() {
    return {
      Mostrar: false,
      array: [],
      Examen: [],
      datos: JSON.parse(localStorage.getItem("datos")),
    };
  },
  mounted() {
    $("#boxLoading").addClass("loading");

    var keys = Object.keys(localStorage);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].includes("hora")) {
        this.array.push(parseInt(keys[i].split("hora")[0]));
      }
    }
    console.log(this.array);
    this.array.sort((a, b) => a - b);
    axios
      .post(url, {
        opcion: 37,
      })
      .then((response) => {
        this.Examen = response.data;
        $("#boxLoading").removeClass("loading");
      });

    document.addEventListener("click", (event) => {
      if (event.path[0].id != "ellipsis-v") {
        this.Mostrar = false;
      }
    });
    document.body.style.backgroundColor = "white";
    //Cambiamos el color del background
  },
  methods: {
    salir: function () {
      this.$router.replace({
        name: "Home",
      });
      //Esta función sirve para redigir al usuario al login
    },
    enviar: function (key) {
      this.$swal
        .fire({
          title: "Desea Registrar Sus Datos?",
          showCancelButton: true,
          confirmButtonText: `Aceptar`,
          cancelButtonText: `Cancelar`,
          reverseButtons: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            $("#boxLoading").addClass("loading");

            axios
              .post(url, {
                consecutivo: key,
                opcion: 4,
              })
              .then((response) => {
                var Datos = response.data.data[0];
                var consecutivo = key;
                var opcion = 14;
                var reconsulta = localStorage.getItem(key.toString().concat("checked"))
                  ? localStorage.getItem(key.toString().concat("checked")) == "true"
                    ? 1
                    : 0
                  : 0;
                var codigo = localStorage.getItem(key.toString().concat("codigo"))
                  ? localStorage.getItem(key.toString().concat("codigo"))
                  : "";
                var codigo_diagnostica = localStorage.getItem(
                  key.toString().concat("selectedCodigo")
                )
                  ? localStorage.getItem(key.toString().concat("selectedCodigo"))
                  : "";
                var impresion_diagnostica =
                  JSON.parse(
                    localStorage.getItem(key.toString().concat("selectedImpresion"))
                  ) == null
                    ? ""
                    : JSON.parse(
                        localStorage.getItem(key.toString().concat("selectedImpresion"))
                      ).join(" ,");
                var examen = localStorage.getItem(key.toString().concat("examen"))
                  ? localStorage.getItem(key.toString().concat("examen"))
                  : "";
                var revision = localStorage.getItem(key.toString().concat("revision"))
                  ? localStorage.getItem(key.toString().concat("revision"))
                  : "";
                var sintomas =
                  JSON.parse(
                    localStorage.getItem(key.toString().concat("selectedSintomas"))
                  ) == null
                    ? ""
                    : JSON.parse(
                        localStorage.getItem(key.toString().concat("selectedSintomas"))
                      ).join(" ,");
                var motivo = localStorage.getItem(key.toString().concat("motivo"))
                  ? localStorage.getItem(key.toString().concat("motivo"))
                  : "";
                var antecedentes = localStorage.getItem(
                  key.toString().concat("antecedentes")
                )
                  ? localStorage.getItem(key.toString().concat("antecedentes"))
                  : "";
                var tratamiento = localStorage.getItem(
                  key.toString().concat("tratamiento")
                )
                  ? localStorage.getItem(key.toString().concat("tratamiento"))
                  : "";
                var traslado = localStorage.getItem(key.toString().concat("traslado"))
                  ? localStorage.getItem(key.toString().concat("traslado")) == "true"
                    ? 1
                    : 0
                  : 0;
                var hora = localStorage.getItem(key.toString().concat("hora"));
                var tamano =
                  JSON.parse(localStorage.getItem(key.toString().concat("tamano")))
                    .clave ?? "";
                var comida =
                  JSON.parse(localStorage.getItem(key.toString().concat("comida")))
                    .clave ?? "";
                var peso = localStorage.getItem(key.toString().concat("peso")) ?? "";
                var comidas =
                  localStorage.getItem(key.toString().concat("comidas")) ?? "";
                var cantidad =
                  localStorage.getItem(key.toString().concat("cantidad")) ?? "";
                var marca = localStorage.getItem(key.toString().concat("marca")) ?? "";
                var examenPrevia =
                  JSON.parse(
                    localStorage.getItem(key.toString().concat("examenSelected"))
                  ) == null
                    ? ""
                    : JSON.parse(
                        localStorage.getItem(key.toString().concat("examenSelected"))
                      ).join(" ,");
                var calMedico =
                  localStorage.getItem(key.toString().concat("medico")) ?? "";
                var calAuxiliar =
                  localStorage.getItem(key.toString().concat("auxiliar")) ?? "";
                var calOperador =
                  localStorage.getItem(key.toString().concat("operador")) ?? "";
                var rechazar =
                  localStorage.getItem(key.toString().concat("rechazar")) ?? "";
                var movil = "";
                var id = localStorage.getItem(key.toString().concat("id")) ?? "";
                var diferencial =
                  JSON.parse(
                    localStorage.getItem(key.toString().concat("selectedDiferencial"))
                  ) == null
                    ? ""
                    : JSON.parse(
                        localStorage.getItem(key.toString().concat("selectedDiferencial"))
                      ).join(" ,");
                var formula =
                  localStorage.getItem(key.toString().concat("formula")) ?? "";
                var location =
                  localStorage.getItem(key.toString().concat("location")) ?? "";
                var examenDevuelto = [];
                var examenDevuelto2 = "";
                var examenThis =
                  JSON.parse(
                    localStorage.getItem(key.toString().concat("examenSelected"))
                  ) == null
                    ? []
                    : JSON.parse(
                        localStorage.getItem(key.toString().concat("examenSelected"))
                      );

                this.Examen.forEach((element) => {
                  examenThis.forEach((element2) => {
                    if (element.valor == element2) {
                      examenDevuelto.push(element.clave);
                      examenDevuelto2 = examenDevuelto2 + "" + element.valor + " ,";
                    }
                  });
                });
                var examenes = examenDevuelto;
                console.log(examenes);

                var medicamentos =
                  JSON.parse(
                    localStorage.getItem(key.toString().concat("selectedMedicamento"))
                  ) == null
                    ? []
                    : JSON.parse(
                        localStorage.getItem(key.toString().concat("selectedMedicamento"))
                      );
                var medico = this.datos.nombre;
                var mascota = Datos.bennm1;
                var documentoTitular = Datos.titnit;
                var documentoMascota = Datos.bendoc;
                var propietario = Datos.titnom;
                var hora2 = new Date();
                var texto = "".concat(
                  hora2.getHours().toString().length > 1
                    ? hora2.getHours().toString()
                    : "0".concat(hora2.getHours().toString()),
                  ":",
                  hora2.getMinutes().toString().length > 1
                    ? hora2.getMinutes().toString()
                    : "0".concat(hora2.getMinutes().toString())
                );
                var horaFin = texto;
                var sexo = Datos.bensex == "M" ? "Masculino" : "Femenino";
                var raza = Datos.Raza;
                var especie = Datos.especie;
                var direccion = Datos.bendir;
                var triaje = Datos.reqcda;
                var horaLlamada = Datos.reqhor;
                var consulta =
                  Datos.ult_traslado == 30 || Datos.ult_traslado == 40
                    ? "Si"
                    : Datos.ult_traslado == 10 ||
                      Datos.ult_traslado == 20 ||
                      Datos.ult_traslado == 41
                    ? "No"
                    : "No Aplica";
                var operador_2 = Datos.NomUsuario;
                var medico_2 = Datos.Medico;
                var auxiliar_2 = Datos.Auxiliar;
                var conductor_2 = Datos.Conductor;
                var fechaDespara = "";
                try {
                  fechaDespara = Datos.fecha.split(" ")[0];
                } catch (error) {
                  console.log(error);
                  fechaDespara = "";
                }
                var edad = Datos.edad;
                var mail = Datos.mail;

                var documento = this.datos.documento;
                axios
                  .post(url, {
                    consecutivo: consecutivo,
                    opcion: opcion,
                    reconsulta: reconsulta,
                    codigo: codigo,
                    codigo_diagnostica: codigo_diagnostica,
                    impresion_diagnostica: impresion_diagnostica,
                    examen: examen,
                    revision: revision,
                    sintomas: sintomas,
                    motivo: motivo,
                    antecedentes: antecedentes,
                    tratamiento: tratamiento,
                    traslado: traslado,
                    hora: hora,
                    tamano: tamano,
                    comida: comida,
                    peso: peso,
                    comidas: comidas,
                    cantidad: cantidad,
                    marca: marca,
                    examenPrevia: examenPrevia,
                    calMedico: calMedico,
                    calAuxiliar: calAuxiliar,
                    calOperador: calOperador,
                    rechazar: rechazar,
                    movil: movil,
                    id: id,
                    diferencial: diferencial,
                    formula: formula,
                    location: location,
                    examenes: examenes,
                    medicamentos: medicamentos,
                    medico: medico,
                    mascota: mascota,
                    documentoTitular: documentoTitular,
                    documentoMascota: documentoMascota,
                    propietario: propietario,
                    horaFin: horaFin,
                    sexo: sexo,
                    raza: raza,
                    especie: especie,
                    direccion: direccion,
                    triaje: triaje,
                    horaLlamada: horaLlamada,
                    consulta: consulta,
                    operador_2: operador_2,
                    medico_2: medico_2,
                    auxiliar_2: auxiliar_2,
                    conductor_2: conductor_2,
                    fechaDespara: fechaDespara,
                    edad: edad,
                    mail: mail,
                    examenDevuelto2: examenDevuelto2,
                    documento: documento,
                  })
                  .then((response) => {
                    $("#boxLoading").removeClass("loading");
                    if (response.data.ok) {
                      this.$swal
                        .fire({
                          title: "Datos Previamente Ingresados",
                          icon: `error`,
                        })
                        .then(() => {
                          
                        });
                    } else {
                      this.$swal
                        .fire({
                          title: "Datos Ingresados Correctamente",
                          confirmButtonText: `Aceptar`,
                          icon: `success`,
                        })
                        .then(() => {
                          /* Read more about isConfirmed, isDenied below */

                          
                        });
                    }
                  });
              });
          }
        });
    },
    descargar(key){
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 36,
          consecutivo: key,
        })
        .then((response) => {
          console.log("HOLA");
          if (response.data == 2) {
            axios({
              url: url,

              method: "POST",
              data: {
                consecutivo: key,
                opcion: 35,
                documento: this.datos.documento,
              },

              responseType: "blob",
            }).then((response) => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));

              var fileLink = document.createElement("a");

              fileLink.href = fileURL;

              fileLink.setAttribute("download", "historia.pdf");

              document.body.appendChild(fileLink);
              $("#boxLoading").removeClass("loading");
              fileLink.click();
            });
          } else {
            $("#boxLoading").removeClass("loading");
            this.$swal.fire(
              "Aun no completa su historia medica, pdf no habilitado",
              "",
              "error"
            );
          }
        });
      
      

    }
  },
};
</script>

<style>
h1 {
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  top: -10px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

h1 {
  color: white;
}

.listas {
  padding: 10px;
  border-bottom: black solid 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.listas:hover {
  -webkit-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
}
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
  opacity: 0.8;
}
.el-select .el-select__tags-text {
  font-size: 120%;
}
.p-inputtext {
  font-size: 1.3rem !important;
}
.el-input__inner {
  font-size: 1.3rem !important;
}
.el-select-dropdown__item {
  font-size: 1.2rem !important;
}
.hover:hover {
  background: #dcdfe6;
}
</style>
