<template>
  <div>
    <div class="about" style="background: #4451b4">
      <div class="w-100 d-flex align-items-center">
        <Nav msg="Historias Clinicas RMV" />
        <div class="dropdown">
          <fa
            @click="Mostrar = true"
            id="ellipsis-v"
            style="
              cursor: pointer;
              position: absolute;
              right: 0%;
              margin-right: 10px;
              color: white;
              font-size: 1.1rem;
            "
            icon="ellipsis-v"
          />
          <div v-if="Mostrar" class="dropdown-content">
            <p style="cursor: pointer; color: black" @click="salir()">Salir</p>
          </div>
        </div>
      </div>
      <div
        class="w-100 d-flex flex-column align-items-center"
        style="background: rgba(48, 62, 160, 255)"
      >
        <p style="color: white">
          {{ datos.nombre + " " + datos.apellido + " " }}<span>&#183; </span
          >{{ datos.documento }}
        </p>
        <p style="color: white">Móvil <span>&#183; </span>{{ movil }}</p>
      </div>
    </div>
    <div id="prueba" style="margin: 1rem">
      <p class="text-center mb-0">
        <b
          >Consecutivo: <span>{{ consecutivo }}</span>
        </b>
      </p>
      <p class="text-center mb-0">
        <b>
          <span>{{ nombre }}</span>
        </b>
      </p>
      <p class="text-center mb-0">
        <b>
          <span>{{ documento }}</span>
        </b>
      </p>
      <div v-if="modalFirma">
        <transition name="model">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header w-100">
                    <div id="canvasModificar" style="padding-left: 5%" class="w-100">
                      <h1 style="color: black">Firma</h1>
                      <signature-pad
                        style="border: black solid 1px; height: 200px"
                        class="w-100"
                        ref="signaturePad"
                        v-model="signatureFile"
                      >
                      </signature-pad>
                      <div class="d-flex align-items-center justify-content-center">
                        <button
                          @click="guardarFirma(3)"
                          style="widht: 100px; position: relative; top: 5px"
                        >
                          REINICIAR
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div class="form-group">
                      <button
                        @click="guardarFirma(2)"
                        style="color: red; widht: 100px; position: relative; top: 5px"
                      >
                        CERRAR
                      </button>
                      <button
                        @click="guardarFirma(1)"
                        style="
                          left: 10px;
                          color: green;
                          widht: 100px;
                          position: relative;
                          top: 5px;
                        "
                      >
                        ACEPTAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div
      style="
        font-size: 17px;
        border-radius: 10px;
        width: 340px;
        height: 30px;
        background: #0c4ca4;
        color: white;
      "
    >
      <p style="widht: 440px; text-align: center">HISTORIA CLÍNICA PREHOSPITALARIA</p>
    </div>
    <div id="boxLoading"></div>
    <div>
      <img style="width: 300px; height: 300px" id="imagen" alt="" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
var url = "/php/crud.php";
var url2 = "/php/pruebapdf.php";
var url3 = "/php/marcasAgua.php";

import Nav from "@/components/Nav.vue";

import $ from "jquery";
export default {
  name: "Pdf",
  components: {
    Nav,
  },
  data() {
    return {
      datos: JSON.parse(localStorage.getItem("datos")), //Datos asignados previamente por localstorage
      movil: localStorage.getItem("movil"),
      Mostrar: false,
      id: "",
      nombre: "",
      consecutivo: localStorage.getItem("consecutivo"),
      edad: "",
      nombreMascota: "",
      documento: "",
      documentoTitular: "",
      sexo: "",
      raza: "",
      especie: "",
      peso: "",
      direccion: "",
      triaje: "",
      antecedentes: "",
      sintomas: "",
      horaLlamada: "",
      consulta: "",
      operador: "",
      medico: "",
      auxiliar: "",
      conductor: "",
      fechaDespara: "",
      modalFirma: true,
      nicolas: "",
    };
  },
  methods: {
    salir: function () {
      this.$router.replace({
        name: "Home",
      });
    },
    async Prueba() {
      var hora = new Date();
      var texto = "".concat(
        hora.getHours().toString().length > 1
          ? hora.getHours().toString()
          : "0".concat(hora.getHours().toString()),
        ":",
        hora.getMinutes().toString().length > 1
          ? hora.getMinutes().toString()
          : "0".concat(hora.getMinutes().toString())
      );

      axios
        .post(url2, {
          hola: "hola",
          consecutivo: localStorage.getItem("consecutivo"),
          edad: this.edad,
          propietario: this.nombre,
          mascota: this.nombreMascota,
          documentoMascota: this.documento,
          documentoTitular: this.documentoTitular,
          horaInicio: localStorage.getItem(
            localStorage.getItem("consecutivo").toString().concat("hora")
          ), //hora
          horaFin: texto,
          sexo: this.sexo,
          raza: this.raza,
          especie: this.especie,
          peso: this.peso,
          direccion: this.direccion,
          triaje: this.triaje,
          antecedentes: this.antecedentes,
          sintomas: this.sintomas,
          horaLlamada: this.horaLlamada,
          consulta: this.consulta,
          operador: this.operador, //_2
          medico: this.medico, //_2
          auxiliar: this.auxiliar, //_2
          conductor: this.conductor, //_2
          tamano:
            JSON.parse(
              localStorage.getItem(
                localStorage.getItem("consecutivo").toString().concat("tamano")
              )
            ).valor ?? "",
          comida:
            JSON.parse(
              localStorage.getItem(
                localStorage.getItem("consecutivo").toString().concat("comida")
              )
            ).valor ?? "",
          comidas:
            localStorage.getItem(
              localStorage.getItem("consecutivo").toString().concat("comidas")
            ) ?? "",
          cantidad:
            localStorage.getItem(
              localStorage.getItem("consecutivo").toString().concat("cantidad")
            ) ?? "",
          marca:
            localStorage.getItem(
              localStorage.getItem("consecutivo").toString().concat("marca")
            ) ?? "",
          fechaDespara: this.fechaDespara,
        })
        .then(() => {
          console.log("HOLA");
        });
    },
    guardarFirma: function (valor) {
      var can = this.$refs.signaturePad.signaturePadCanvas;

      var ctx = can.getContext("2d");
      if (valor == 1) {
        // var documento = this.documento;
        var img = new Image();
        img.src = can.toDataURL();
        console.log(img.src);
        this.modalFirma = false;
        document.getElementById("imagen").src = img.src;

        axios
          .post(url3, {
            imgBase64: img.src,
          })
          .then(() => {
            $("#boxLoading").removeClass("loading");
            ctx.clearRect(0, 0, can.width, can.height);
            this.modalFirma = false;
            this.$swal.fire("Firma ingresada", "", "success");
            this.colorFirma = "#efefef";
            this.colorFirma2 = "black";
            localStorage.setItem(
              localStorage.getItem("consecutivo").toString().concat("firma"),
              true
            );
          });
      }
      if (valor == 3) {
        ctx.clearRect(0, 0, can.width, can.height);
      }
      if (valor == 2) {
        ctx.clearRect(0, 0, can.width, can.height);
        this.modalFirma = false;
      }
    },
  },
  mounted() {
    $("#boxLoading").addClass("loading");
    var prueba = localStorage.getItem("prueba");

    if (prueba === null) {
      this.$router.replace({
        name: "Home",
      });
    }

    document.getElementsByTagName("canvas")[0].classList.add("modificar2");

    axios
      .post(url, {
        consecutivo: localStorage.getItem("consecutivo"),
        opcion: 3,
      })
      .then((response) => {
        var Datos = response.data.data[0];
        this.id = Datos.ID_Ben;
        this.nombre = Datos.titnom;
        this.raza = Datos.Raza;
        this.especie = Datos.especie;
        this.peso = Datos.peso;
        try {
          this.fechaDespara = Datos.fecha.split(" ")[0];
        } catch (error) {
          console.log(error);
        }
        axios
          .post(url, {
            consecutivo: localStorage.getItem("consecutivo"),
            opcion: 4,
          })
          .then((response) => {
            var Datos = response.data.data[0];
            this.documento = Datos.bendoc;
            this.edad = Datos.edad;
            this.nombreMascota = Datos.bennm1;
            this.documentoTitular = Datos.titnit;
            this.sexo = Datos.bensex == "M" ? "Masculino" : "Femenino";
            this.direccion = Datos.bendir;
            this.triaje = Datos.reqcda;
            this.antecedentes = Datos.bentxt;
            this.sintomas = Datos.reqsig;
            this.horaLlamada = Datos.reqhor;
            this.consulta =
              Datos.ult_traslado == 30 || Datos.ult_traslado == 40
                ? "Si"
                : Datos.ult_traslado == 10 ||
                  Datos.ult_traslado == 20 ||
                  Datos.ult_traslado == 41
                ? "No"
                : "No Aplica";
            this.operador = Datos.NomUsuario;
            this.medico = Datos.Medico;
            this.auxiliar = Datos.Auxiliar;
            this.conductor = Datos.Conductor;
            $("#boxLoading").removeClass("loading");
          });
      });
    document.addEventListener("click", (event) => {
      if (event.path[0].id != "ellipsis-v") {
        this.Mostrar = false;
      }
    });
    document.body.style.backgroundColor = "white";
    //Cambiamos el color del background
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

h1,
h2 {
  color: white;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.title {
  font-size: 2.2rem;

  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

input:hover {
  color: black;
}

button {
  border: none;
  outline: none;
  width: 150px;
  height: 2rem;
  border-radius: 55px !important;
  color: #acacac;
}

button:hover {
  color: black;
}

option {
  color: black;
  background: white;
}

option::hover {
  background: white;
}

/*select{
    margin-left: auto;
    order: 2;
    background: none;
    border: none;
    outline: none;
    -moz-appearance:none; 
    -webkit-appearance:none; 
    appearance:none;
    color: transparent;
    width: 10px;
  }
  */
.dropdown {
  position: relative;
  display: inline-block;
  top: -10px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
  opacity: 0.8;
}
.el-select .el-select__tags-text {
  font-size: 120%;
}
.p-inputtext {
  font-size: 1.3rem !important;
}
.el-input__inner {
  font-size: 1.3rem !important;
}
.el-select-dropdown__item {
  font-size: 1.2rem !important;
}

.modificar1 {
  background: url("../assets/excamen_fiscio_gato_dorsal_vantral.png");
}
.modificar2 {
  background: url("../assets/excamen_fiscio_perro_dorsal_vantral.png");
}
.form-group {
  display: flex;
    justify-content: center;
}
</style>
