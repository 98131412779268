<template>
  <div>
    <div class="about" style="background: #4451b4">
      <div class="w-100 d-flex align-items-center">
        <Nav msg="Historias Clinicas RMV" />
        <div class="dropdown">
          <fa
            @click="Mostrar = true"
            id="ellipsis-v"
            style="
              cursor: pointer;
              position: absolute;
              right: 0%;
              margin-right: 10px;
              color: white;
              font-size: 1.1rem;
            "
            icon="ellipsis-v"
          />
          <div v-if="Mostrar" class="dropdown-content">
            <p style="cursor: pointer; color: black" @click="salir()">Salir</p>
          </div>
        </div>
      </div>
      <div
        class="w-100 d-flex flex-column align-items-center"
        style="background: rgba(48, 62, 160, 255)"
      >
        <p style="color: white">
          {{ datos.nombre + " " + datos.apellido + " " }}<span>&#183; </span
          >{{ datos.documento }}
        </p>
        <p style="color: white">Móvil <span>&#183; </span>{{ movil }}</p>
      </div>
    </div>
    <div style="margin: 1rem; font-size: 1.3rem">
      <p
        class="text-center mb-0"
        style="cursor: pointer"
        @click="modalConsecutivo = true"
      >
        <b
          >Consecutivo: <span>{{ consecutivo }}</span>
        </b>
      </p>
      <p class="text-center mb-0">
        <b>
          <span>{{ nombre }}</span>
        </b>
        <br />
        <b>
          <span>{{ nombreMascota }}</span>
        </b>
      </p>
      <p class="text-center mb-0">
        <b>
          <span>{{ documento }}</span>
        </b>
      </p>
      <!--<p class=" mb-0">
            <b>
                Edad: <span>{{edad}}</span> años
            </b>
        </p>-->
      <div style="font-size: 1.3rem">
        <p class="mb-2">
          <b> Antecedentes </b>
        </p>
        <Textarea
          style="font-size: 1.3rem"
          @input="cambios(1)"
          class="w-100"
          v-model="antecedentes"
          :autoResize="true"
          rows="2"
          cols="30"
        />
      </div>
      <div style="font-size: 1.3rem">
        <p class="mb-2">
          <b> Motivo De Consulta </b>
        </p>
        <Textarea
          style="font-size: 1.3rem"
          @input="cambios(2)"
          class="w-100"
          v-model="motivo"
          :autoResize="true"
          rows="2"
          cols="30"
        />
      </div>

      <div style="font-size: 1.3rem">
        <p class="mb-2 mt-2">
          <b>
            Sintomas
            <span
              style="font-size: 1.2rem; cursor: pointer"
              @click="modalSintomas = true"
            >
              <fa style="cursor: pointer; right: auto" icon="upload" />
            </span>
          </b>
        </p>
        <el-select
          ref="multiselect"
          class="w-100"
          v-on:keyup.enter="click(0)"
          @change="cambios(3)"
          multiple
          v-model="selectedSintomas"
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="item in sintomas"
            :key="item.valor"
            :label="item.valor"
            :value="item.valor"
          >
          </el-option>
        </el-select>
      </div>
      <h1 style="color: black; margin-top: 1rem">REVISION POR SISTEMAS</h1>
      <div
        style="
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div style="width: 50%">
          <p class="mb-2 mt-2">
            <b> Frecuencia cardíaca: F.C </b>
          </p>
        </div>
        <div style="width: 50%">
          <el-input
            type="number"
            @input="cambios(19)"
            v-model="FC"
            placeholder="Frecuencia cardíaca: F.C"
          />
        </div>
      </div>
      <div
        style="
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div style="width: 50%">
          <p class="mb-2 mt-2">
            <b> Frecuencia respiratoria: F.R </b>
          </p>
        </div>
        <div style="width: 50%">
          <el-input
            type="number"
            @input="cambios(20)"
            v-model="FR"
            placeholder="Frecuencia respiratoria: F.R"
          />
        </div>
      </div>
      <div
        style="
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div style="width: 50%">
          <p class="mb-2 mt-2">
            <b> Temperatura: T° </b>
          </p>
        </div>
        <div style="width: 50%">
          <el-input
            type="number"
            @input="cambios(21)"
            v-model="temperatura"
            placeholder="Temperatura: T°"
          />
        </div>
      </div>
      <div
        style="
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div style="width: 50%">
          <p class="mb-2 mt-2">
            <b> Condición Corporal: C.C </b>
          </p>
        </div>
        <div style="width: 50%">
          <el-select
            @change="cambios(22)"
            style="width: 100%"
            v-model="CC"
            placeholder="Condición Corporal: C.C"
          >
            <el-option
              v-for="item in CCLista"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        style="
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div style="width: 50%">
          <p class="mb-2 mt-2">
            <b> Presión arterial: P.A.S. y D.A.S </b>
          </p>
        </div>
        <div style="width: 50%">
          <el-select
            @change="cambios(23)"
            style="width: 100%"
            v-model="PASDAS"
            placeholder="Presión arterial: P.A.S. y D.A.S"
          >
            <el-option
              v-for="item in PASDASLista"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        style="
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div style="width: 50%">
          <p class="mb-2 mt-2">
            <b> Pulso </b>
          </p>
        </div>
        <div style="width: 50%">
          <el-select
            @change="cambios(24)"
            style="width: 100%"
            v-model="pulso"
            placeholder="Pulso"
          >
            <el-option
              v-for="item in pulsoLista"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="font-size: 1.3rem">
        <p class="mb-2">
          <b> Examen Físico </b>
        </p>
        <Textarea
          @input="cambios(5)"
          class="w-100"
          v-model="examen"
          :autoResize="true"
          rows="1"
          cols="30"
        />
      </div>
      <div style="font-size: 1.3rem">
        <p class="mb-2 mt-2">
          <b>
            Impresión Diagnostica
            <span
              style="font-size: 1.2rem; cursor: pointer"
              @click="modalImpresion = true"
            >
              <fa style="cursor: pointer; right: auto" icon="upload" />
            </span>
          </b>
        </p>
        <el-select
          class="w-100"
          v-on:keyup.enter="click(1)"
          @change="cambios(6)"
          multiple
          v-model="selectedImpresion"
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="item in impresion"
            :key="item.valor"
            :label="item.valor"
            :value="item.valor"
          >
          </el-option>
        </el-select>
      </div>
      <div style="font-size: 1.3rem">
        <p class="mb-2 mt-2">
          <b>
            Diagnostico Diferencial
            <span
              style="font-size: 1.2rem; cursor: pointer"
              @click="modalDiferencial = true"
            >
              <fa style="cursor: pointer; right: auto" icon="upload" />
            </span>
          </b>
        </p>
        <el-select
          class="w-100"
          v-on:keyup.enter="click(1)"
          @change="cambios(16)"
          multiple
          v-model="selectedDiferencial"
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="item in diferencial"
            :key="item.valor"
            :label="item.valor"
            :value="item.valor"
          >
          </el-option>
        </el-select>
      </div>
      <div style="font-size: 1.3rem">
        <p class="mb-2 mt-2">
          <b>
            Examenes Laboratorio
            <span
              style="font-size: 1.2rem; cursor: pointer"
              @click="modalHistorias2 = true"
            >
              <fa style="cursor: pointer; right: auto" icon="upload" />
            </span>
          </b>
        </p>

        <el-select
          class="w-100"
          v-on:keyup.enter="click(1)"
          @change="cambios(12)"
          multiple
          v-model="examenSelected"
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="item in Examen"
            :key="item.valor"
            :label="item.valor"
            :value="item.valor"
          >
          </el-option>
        </el-select>
      </div>
      <div style="font-size: 1.3rem; display: none">
        <p class="mb-2">
          <b>
            Código diagnostico
            <span
              style="font-size: 1.2rem; cursor: pointer"
              @click="modalHistorias = true"
              ><fa style="cursor: pointer; right: auto" icon="upload" />
            </span>
          </b>
        </p>
        <el-select
          class="w-100"
          @change="cambios(7)"
          v-model="selectedCodigo"
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="item in options"
            :key="item.valor"
            :label="item.valor"
            :value="item.clave"
          >
          </el-option>
        </el-select>
      </div>
      <div style="font-size: 1.3rem" class="mb-2 pt-2">
        <p class="mb-2 pt-2">
          <b> Código de valoración médico </b>
        </p>
        <div class="p-field-radiobutton">
          <RadioButton
            @change="cambios(8)"
            id="city1"
            name="city"
            value="V"
            v-model="codigo"
          />
          <label style="margin-right: 1rem" for="city1">Verde</label>
          <RadioButton
            @change="cambios(8)"
            id="city2"
            name="city"
            value="A"
            v-model="codigo"
          />
          <label style="margin-right: 1rem" for="city2">Amarillo</label>
          <RadioButton
            @change="cambios(8)"
            id="city3"
            name="city"
            value="R"
            v-model="codigo"
          />
          <label style="margin-right: 1rem" for="city3">Rojo</label>
        </div>
        <!-- <div class="p-field-radiobutton">

             </div>
             <div class="p-field-radiobutton">

             </div> -->
      </div>
      <div style="font-size: 1.3rem" class="mt-1">
        <p class="mb-2">
          <b> Tratamiento </b>
        </p>
        <Textarea
          @input="cambios(9)"
          class="w-100"
          v-model="tratamiento"
          :autoResize="true"
          rows="1"
          cols="30"
        />
      </div>
      <div style="font-size: 1.3rem">
        <p class="mb-2">
          <b>
            Medicamentos
            <span
              style="font-size: 1.2rem; cursor: pointer"
              @click="modalMedicamento = true"
              ><fa style="cursor: pointer; right: auto" icon="upload" />
            </span>
          </b>
        </p>
        <el-select
          class="w-100"
          @change="cambios(18)"
          multiple
          v-model="selectedMedicamento"
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="item in medicamentos"
            :key="item.valor"
            :label="item.valor"
            :value="item.clave"
          >
          </el-option>
        </el-select>
      </div>
      <!--<div style="font-size: 1.3rem">
        <p class="mb-2">
          <b> Fórmula </b>
        </p>
        <Textarea
          @input="cambios(17)"
          class="w-100"
          v-model="formula"
          :autoResize="true"
          rows="1"
          cols="30"
        />
      </div>-->
      <div style="font-size: 1.3rem">
        <p class="mb-2">
          <b>
            Enviar Correo
            <span>
              <fa
                @click="actualizar()"
                style="cursor: pointer; right: auto"
                icon="sync-alt"
            /></span>
          </b>
        </p>
        <input
          v-model="mail"
          class="w-100"
          style="
            border: 0;
            border-bottom: 1px solid #ced4da;
            height: 42px;
            padding-left: 10px;
          "
          type="text"
          autocomplete="off"
        />
      </div>

      <div style="font-size: 1.1rem" class="mb-3 pt-2">
        <p class="mb-2">
          <label for="checkbox"
            ><b> Reconsulta? </b
            ><Checkbox
              @change="cambios(10)"
              name="checkbox"
              id="checkbox"
              v-model="checked"
              :binary="true"
          /></label>
        </p>
      </div>
      <p style="font-size: 1.1rem" class="mb-0">
        <b
          >Motivo Última Consulta: <br /><span>{{ motivoReconsulta }}</span>
        </b>
      </p>
      <p style="font-size: 1.1rem" class="mb-3">
        <b
          >Fecha y Hora Última Consulta:
          <span>{{ diaReconsulta + " " + horaReconsulta }}</span>
        </b>
      </p>
      <div style="font-size: 1.1rem" class="mb-3">
        <p class="mb-2">
          <label for="traslado"
            ><b> Traslado? </b
            ><Checkbox
              @change="cambios(11)"
              name="traslado"
              id="traslado"
              v-model="traslado"
              :binary="true"
          /></label>
        </p>
      </div>
      <div v-if="traslado">
        <Button
          style="color: black"
          class="p-button-raised mb-3 w-100"
          v-bind:style="{ background: salidaColor }"
          label="SALIDA TRASLADO"
          @click="salida()"
        />
        <Button
          style="color: black"
          class="p-button-raised mb-3 w-100"
          v-bind:style="{ background: llegadaColor }"
          label="LLEGADA INSTITUCIÓN"
          @click="llegada()"
        />
        <Button
          style="color: black"
          class="p-button-raised mb-3 w-100"
          v-bind:style="{ background: salidaInsColor }"
          label="SALIDA INSTITUCIÓN"
          @click="salidaIns()"
        />
      </div>
      <Button
        class="p-button-raised p-button-success mb-4"
        style="
          color: black;
          width: 13rem;
          background: rgb(251, 251, 88);
          font-size: 0.7rem;
        "
        label="REGISTRAR DESPARASITACIÓN"
        @click="registrarDesparasitacion"
      />
      <div
        style="
          color: white;
          margin-bottom: 1rem;
          width: 100%;
          border-radius: 10px;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #689f38;
        "
      >
        <form ref="form">
          <label style="width: max-content" class="cameraButton"
            >SUBIR FORMULA CONSULTA
            <input
              multiple
              ref="file"
              id="fileFormula"
              name="prueba"
              @change="pruebaz(3)"
              type="file"
              accept="image/*; capture=camera"
            />
          </label>
        </form>
      </div>
      <div
        style="
          color: white;
          margin-bottom: 1rem;
          width: 100%;
          border-radius: 10px;
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #689f38;
        "
      >
        <form ref="form">
          <label style="width: max-content" class="cameraButton"
            >SUBIR FOTO CONSULTA
            <input
              multiple
              ref="file"
              id="file"
              name="prueba"
              @change="pruebaz(1)"
              type="file"
              accept="image/*; capture=camera"
            />
          </label>
        </form>
      </div>

      <Button
        class="p-button-raised p-button-success w-100 mb-3"
        label="EXAMEN FÍSICO PIEL"
        @click="modalexamenFisicoActivar"
      />

      <Button
        v-bind:style="{ background: colorFirma, color: colorFirma2 }"
        @click="modalFirma = true"
        class="p-button-raised p-button-success w-100 mb-3"
        label="FIRMAR CONSULTA"
      />
      <Button
        v-bind:style="{ background: colorCalificar, color: colorCalificar2 }"
        @click="Calificar()"
        class="p-button-raised p-button-success w-100 mb-3"
        label="CALIFICACIÓN CONSULTA"
      />
      <Button
        class="p-button-raised p-button-warning w-100 mb-3 bold"
        label="FINALIZAR CONSULTA"
        @click="finalizar()"
      />
      <button
        @click="imagenes()"
        style="
          background: #2196f3;
          border-radius: 10px;
          margin-bottom: 1rem;
          color: white;
          width: 100%;
          position: relative;
          top: 5px;
        "
      >
        Ver Imagenes Consulta
      </button>

      <div class="d-flex w-100 justify-content-center align-items-center flex-column">
        <button
          @click="atras()"
          style="
            background: #2196f3;
            border-radius: 10px;
            margin-bottom: 1rem;
            color: white;
            width: 90%;
            position: relative;
            top: 5px;
          "
        >
          ATRÁS
        </button>
      </div>
    </div>
    <div v-if="modalHistorias">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header w-100">
                  <div class="d-flex w-100" style="justify-content: space-evenly">
                    <div style="padding-right: 5%">
                      <h1 style="color: black">Código</h1>

                      <input
                        maxlength="5"
                        v-model="codigoModal"
                        class="w-100"
                        style="border: 1px solid #ced4da; height: 42px"
                        type="text"
                        autocomplete="off"
                      />
                    </div>
                    <div style="padding-left: 5%">
                      <h1 style="color: black">Descripción</h1>
                      <Textarea
                        class="w-100"
                        v-model="descripcionModal"
                        :autoResize="true"
                        rows="1"
                        cols="30"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <button
                      @click="modalHistorias = false"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                    <button
                      @click="guardarModal()"
                      style="
                        left: 10px;
                        color: green;
                        widht: 100px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      ACEPTAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="modalSintomas">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header w-100">
                  <div class="d-flex w-100" style="justify-content: space-evenly">
                    <div style="padding-left: 5%">
                      <h1 style="color: black">Sintoma</h1>
                      <Textarea
                        class="w-100"
                        v-model="descripcionSintoma"
                        :autoResize="true"
                        rows="1"
                        cols="30"
                      />
                    </div>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <button
                      @click="modalSintomas = false"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                    <button
                      @click="guardarModalSintomas()"
                      style="
                        left: 10px;
                        color: green;
                        widht: 100px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      ACEPTAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="modalHistorias2">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header w-100">
                  <div style="padding-left: 5%" class="w-100">
                    <h1 style="color: black">Examen</h1>
                    <Textarea
                      class="w-100"
                      v-model="examenModal"
                      :autoResize="true"
                      rows="1"
                      cols="30"
                    />
                  </div>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <button
                      @click="modalHistorias2 = false"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                    <button
                      @click="guardarModal2()"
                      style="
                        left: 10px;
                        color: green;
                        widht: 100px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      ACEPTAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="modalImpresion">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header w-100">
                  <div style="padding-left: 5%" class="w-100">
                    <h1 style="color: black">Impresión Diagnostica</h1>
                    <Textarea
                      class="w-100"
                      v-model="nuevaImpresion"
                      :autoResize="true"
                      rows="1"
                      cols="30"
                    />
                  </div>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <button
                      @click="modalImpresion = false"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                    <button
                      @click="guardarModal3()"
                      style="
                        left: 10px;
                        color: green;
                        widht: 100px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      ACEPTAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="modalDiferencial">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header w-100">
                  <div style="padding-left: 5%" class="w-100">
                    <h1 style="color: black">Diagnostico Diferencial</h1>
                    <Textarea
                      class="w-100"
                      v-model="nuevaDiferencial"
                      :autoResize="true"
                      rows="1"
                      cols="30"
                    />
                  </div>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <button
                      @click="modalDiferencial = false"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                    <button
                      @click="guardarModal4()"
                      style="
                        left: 10px;
                        color: green;
                        widht: 100px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      ACEPTAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="modalFirma">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header w-100">
                  <div style="padding-left: 5%" class="w-100">
                    <h1 style="color: black">Firma</h1>
                    <signature-pad
                      style="border: black solid 1px; height: 200px"
                      class="w-100"
                      ref="signaturePad"
                      v-model="signatureFile"
                    >
                    </signature-pad>
                    <div class="d-flex align-items-center justify-content-center">
                      <button
                        @click="guardarFirma(3)"
                        style="widht: 100px; position: relative; top: 5px"
                      >
                        REINICIAR
                      </button>
                    </div>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <button
                      @click="guardarFirma(2)"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                    <button
                      @click="guardarFirma(1)"
                      style="
                        left: 10px;
                        color: green;
                        widht: 100px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      ACEPTAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="modalCalificar">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content" v-if="calificar">
                <div class="modal-header w-100">
                  <div style="padding-left: 5%" class="w-100">
                    <h1 style="color: black">Calificar Consulta</h1>
                    <p style="color: #6c757d">
                      Califique de 1 a 5 los siguientes aspectos, Tenga en cuenta que: (1)
                      Muy Baja (2) Baja (3) Aceptable (4) Alta (5) Muy Alta.
                    </p>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="m-1 p-field-radiobutton">
                    <div>
                      <p style="margin-bottom: 0px; color: #5e656c">
                        1. Atención del Médico Veterinario
                      </p>
                    </div>
                    <RadioButton
                      @change="cambios(13)"
                      id="medico1"
                      name="medico"
                      value="1"
                      v-model="medico"
                    />
                    <label style="margin-right: 0.7rem; margin-left: 0.7rem" for="medico1"
                      >1</label
                    >
                    <RadioButton
                      @change="cambios(13)"
                      id="medico2"
                      name="medico"
                      value="2"
                      v-model="medico"
                    />
                    <label style="margin-right: 0.7rem; margin-left: 0.7rem" for="medico2"
                      >2</label
                    >
                    <RadioButton
                      @change="cambios(13)"
                      id="medico3"
                      name="medico"
                      value="3"
                      v-model="medico"
                    />
                    <label style="margin-right: 0.7rem; margin-left: 0.7rem" for="medico3"
                      >3</label
                    >
                    <RadioButton
                      @change="cambios(13)"
                      id="medico4"
                      name="medico"
                      value="4"
                      v-model="medico"
                    />
                    <label style="margin-right: 0.7rem; margin-left: 0.7rem" for="medico4"
                      >4</label
                    >
                    <RadioButton
                      @change="cambios(13)"
                      id="medico5"
                      name="medico"
                      value="5"
                      v-model="medico"
                    />
                    <label style="margin-right: 0.7rem; margin-left: 0.7rem" for="medico5"
                      >5</label
                    >
                    <div class="mt-2">
                      <p style="margin-bottom: 0px; color: #5e656c">
                        2. Atención auxiliar Veterinario
                      </p>
                    </div>
                    <RadioButton
                      @change="cambios(14)"
                      id="auxiliar1"
                      name="auxiliar"
                      value="1"
                      v-model="auxiliar"
                    />
                    <label
                      style="margin-right: 0.7rem; margin-left: 0.7rem"
                      for="auxiliar1"
                      >1</label
                    >
                    <RadioButton
                      @change="cambios(14)"
                      id="auxiliar2"
                      name="auxiliar"
                      value="2"
                      v-model="auxiliar"
                    />
                    <label
                      style="margin-right: 0.7rem; margin-left: 0.7rem"
                      for="auxiliar2"
                      >2</label
                    >
                    <RadioButton
                      @change="cambios(14)"
                      id="auxiliar3"
                      name="auxiliar"
                      value="3"
                      v-model="auxiliar"
                    />
                    <label
                      style="margin-right: 0.7rem; margin-left: 0.7rem"
                      for="auxiliar3"
                      >3</label
                    >
                    <RadioButton
                      @change="cambios(14)"
                      id="auxiliar4"
                      name="auxiliar"
                      value="4"
                      v-model="auxiliar"
                    />
                    <label
                      style="margin-right: 0.7rem; margin-left: 0.7rem"
                      for="auxiliar4"
                      >4</label
                    >
                    <RadioButton
                      @change="cambios(14)"
                      id="auxiliar5"
                      name="auxiliar"
                      value="5"
                      v-model="auxiliar"
                    />
                    <label
                      style="margin-right: 0.7rem; margin-left: 0.7rem"
                      for="auxiliar5"
                      >5</label
                    >
                    <div class="mt-2">
                      <p style="margin-bottom: 0px; color: #5e656c">
                        3. Atención al llamar a solicitar medico
                      </p>
                    </div>
                    <RadioButton
                      @change="cambios(15)"
                      id="operador1"
                      name="operador"
                      value="1"
                      v-model="operador"
                    />
                    <label
                      style="margin-right: 0.7rem; margin-left: 0.7rem"
                      for="operador1"
                      >1</label
                    >
                    <RadioButton
                      @change="cambios(15)"
                      id="operador2"
                      name="operador"
                      value="2"
                      v-model="operador"
                    />
                    <label
                      style="margin-right: 0.7rem; margin-left: 0.7rem"
                      for="operador2"
                      >2</label
                    >
                    <RadioButton
                      @change="cambios(15)"
                      id="operador3"
                      name="operador"
                      value="3"
                      v-model="operador"
                    />
                    <label
                      style="margin-right: 0.7rem; margin-left: 0.7rem"
                      for="operador3"
                      >3</label
                    >
                    <RadioButton
                      @change="cambios(15)"
                      id="operador4"
                      name="operador"
                      value="4"
                      v-model="operador"
                    />
                    <label
                      style="margin-right: 0.7rem; margin-left: 0.7rem"
                      for="operador4"
                      >4</label
                    >
                    <RadioButton
                      @change="cambios(15)"
                      id="operador5"
                      name="operador"
                      value="5"
                      v-model="operador"
                    />
                    <label
                      style="margin-right: 0.7rem; margin-left: 0.7rem"
                      for="operador5"
                      >5</label
                    >
                  </div>

                  <div class="form-group d-flex justify-content-center">
                    <button
                      @click="guardarCalificar()"
                      style="color: green; widht: 100px; position: relative; top: 5px"
                    >
                      GUARDAR
                    </button>
                  </div>
                </div>
              </div>
              <div class="modal-content" v-if="!calificar">
                <div class="modal-header w-100">
                  <div style="padding-left: 5%" class="w-100">
                    <h1 style="color: black">¿Desea realizar la encuesta?</h1>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <button
                      @click="rechazar()"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      RECHAZAR
                    </button>
                    <button
                      @click="calificar = true"
                      style="
                        left: 10px;
                        color: green;
                        widht: 100px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      ACEPTAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="modalexamenFisico">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header w-100">
                  <div id="canvasModificar" style="padding-left: 5%" class="w-100">
                    <h1 style="color: black">Examen Físico</h1>
                    <signature-pad
                      style="border: black solid 1px; height: 200px"
                      class="w-100"
                      ref="signaturePadFiscio"
                      id="HOLA"
                      v-model="signatureFileFisico"
                    >
                    </signature-pad>
                    <div class="d-flex align-items-center justify-content-center">
                      <button
                        @click="guardarFisico(3)"
                        style="widht: 100px; position: relative; top: 5px"
                      >
                        REINICIAR
                      </button>
                    </div>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <button
                      @click="guardarFisico(2)"
                      style="color: red; widht: 100px; position: relative; top: 5px"
                    >
                      CERRAR
                    </button>
                    <button
                      @click="guardarFisico(1)"
                      style="
                        left: 10px;
                        color: green;
                        widht: 100px;
                        position: relative;
                        top: 5px;
                      "
                    >
                      ACEPTAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <Dialog header="Datos Generales" v-model:visible="modalConsecutivo">
      <p>Nombre Mascota: {{ nombreMascota }}</p>
      <p>Correo: {{ mail }}</p>
      <p>Nombre Titular: {{ nombre }}</p>
      <p>Telefono Titular: {{ telefonoTitular }}</p>
      <p>Peso: {{ peso }} KG</p>

      <br />
      <Button
        @click="modalConsecutivo = false"
        label="Cerrar"
        style="float: right; margin-top: 1rem"
      />
    </Dialog>
    <Dialog header="Agregar Medicamento" v-model:visible="modalMedicamento">
      <Textarea
        class="w-100"
        v-model="nuevoMedicamento"
        :autoResize="true"
        rows="1"
        cols="30"
      />

      <br />
      <button
        @click="modalMedicamento = false"
        style="color: red; widht: 100px; position: relative; top: 5px"
      >
        CERRAR
      </button>
      <button
        @click="(modalMedicamento = false), guardarMedicamento()"
        style="left: 10px; color: green; widht: 100px; position: relative; top: 5px"
      >
        ACEPTAR
      </button>
    </Dialog>
    <div id="boxLoading"></div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import axios from "axios";
import $ from "jquery";
var url = "/php/crud.php";
var url2 = "/php/imagenes.php";
var url3 = "/php/firmas.php";
var url4 = "/php/marcasAgua.php";

export default {
  name: "Historias",
  components: {
    Nav,
  },
  data() {
    return {
      datos: JSON.parse(localStorage.getItem("datos")),
      movil: localStorage.getItem("movil"),
      Mostrar: false,
      consecutivo: localStorage.getItem("consecutivo"),
      nombre: "",
      documento: "",
      selectedCodigo: "",
      options: [],
      value: "",
      selectedSintomas: [],
      sintomas: [],
      codigo: "",
      checked: "",
      traslado: "",
      salidaColor: "#ffee58",
      llegadaColor: "#ffee58",
      salidaInsColor: "#ffee58",
      antecedentes: "",
      motivo: "",
      revision: "",
      examen: "",
      tratamiento: "",
      modalHistorias: false,
      codigoModal: "",
      descripcionModal: "",
      id: "",
      Examen: [],
      examenSelected: [],
      examenModal: "",
      modalHistorias2: false,
      motivoReconsulta: "No Aplica",
      diaReconsulta: "No Aplica",
      horaReconsulta: "",
      modalCalificar: false,
      medico: 1,
      auxiliar: 1,
      operador: 1,
      calificar: false,
      Rechazar: "",

      formula: "",
      pruebasss: "",
      modalFirma: false,
      signatureFile: null,
      colorFirma: "#689f38",
      colorFirma2: "white",
      colorCalificar: "#689f38",
      colorCalificar2: "white",
      mail: "",
      nombreMascota: "",
      edad: "",
      documentoTitular: "",
      sexo: "",
      raza: "",
      especie: "",
      direccion: "",
      triaje: "",
      horaLlamada: "",
      consulta: "",
      operador_2: this.operador,
      medico_2: this.medico,
      auxiliar_2: this.auxiliar,
      conductor_2: this.conductor,
      fechaDespara: "",
      modalSintomas: false,

      descripcionSintoma: "",
      modalMedicamento: false,
      selectedMedicamento: [],
      medicamentos: [],
      selectedDiferencial: [],
      diferencial: [],
      selectedImpresion: [],
      impresion: [],
      modalImpresion: false,
      nuevaImpresion: "",
      modalDiferencial: false,
      nuevaDiferencial: "",
      modalexamenFisico: false,
      signatureFileFisico: null,
      modalConsecutivo: false,
      peso: "",
      telefonoTitular: "",
      FC: "",
      FR: "",
      temperatura: "",
      CCLista: [
        {
          value: "1-Caquexico",
        },
        {
          value: "2-Flaco",
        },
        {
          value: "3-Buena Condición",
        },
        {
          value: "4-Gordo",
        },
        {
          value: "5-Obeso",
        },
      ],
      PASDASLista: [
        {
          value: "Normotenso",
        },
        {
          value: "Hipertenso",
        },
        {
          value: "Hipotenso",
        },
      ],
      CC: null,
      PASDAS: "",
      pulso: "",
      pulsoLista: [
        {
          value: "Fuerte",
        },
        {
          value: "Concordante",
        },
      ],
      nuevoMedicamento: "",
    };
  },
  mounted() {
    $("#boxLoading").addClass("loading");
    this.FC = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("FC")
    );
    this.FR = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("FR")
    );
    this.temperatura = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("temperatura")
    );
    this.CC = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("CC")
    );
    this.PASDAS = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("PASDAS")
    );
    this.pulso = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("pulso")
    );
    this.antecedentes = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("antecedentes")
    );
    this.formula = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("formula")
    );
    this.revision = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("revision")
    );
    this.examen = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("examen")
    );

    this.codigo = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("codigo")
    );
    this.medico = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("medico")
    );
    this.auxiliar = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("auxiliar")
    );
    this.operador = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("operador")
    );
    this.tratamiento = localStorage.getItem(
      localStorage.getItem("consecutivo").toString().concat("tratamiento")
    );
    // this.diferencial = localStorage.getItem(localStorage.getItem("consecutivo").toString().concat('diferencial'));
    this.checked =
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("checked")
      ) == "true"
        ? true
        : false;
    this.traslado =
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("traslado")
      ) == "true"
        ? true
        : false;

    if (
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("firma")
      ) != null
    ) {
      this.colorFirma = "#efefef";
      this.colorFirma2 = "black";
    }
    if (
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("rechazar")
      ) != null
    ) {
      this.rechazar = localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("rechazar")
      );
      this.colorCalificar = "#efefef";
      this.colorCalificar2 = "black";
    }
    if (
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("salida")
      )
    ) {
      this.salidaColor = "#efefef";
    }
    if (
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("llegada")
      )
    ) {
      this.llegadaColor = "#efefef";
    }
    if (
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("salidaIns")
      )
    ) {
      this.salidaInsColor = "#efefef";
    }

    var prueba = localStorage.getItem("prueba");
    if (prueba === null) {
      this.$router.replace({
        name: "Home",
      });
    }
    axios
      .post(url, {
        consecutivo: localStorage.getItem("consecutivo"),
        opcion: 3,
      })
      .then((response) => {
        var Datos = response.data.data[0];
        this.id = Datos.ID_Ben;
        this.peso = Datos.peso;
        this.telefonoTitular = Datos.tittel;
        localStorage.setItem(
          localStorage.getItem("consecutivo").toString().concat("id"),
          this.id
        );
        this.nombre = Datos.titnom;
        this.mail = Datos.mail;
        this.raza = Datos.Raza;
        this.especie = Datos.especie;
        try {
          this.fechaDespara = Datos.fecha.split(" ")[0];
        } catch (error) {
          console.log(error);
        }
        axios
          .post(url, {
            id: this.id,
            opcion: 16,
          })
          .then((response) => {
            this.Examen = response.data.examen;
            this.examenSelected = JSON.parse(
              localStorage.getItem(
                localStorage.getItem("consecutivo").toString().concat("examenSelected")
              )
            );
          });
        axios
          .post(url, {
            id: this.id,
            opcion: 20,
          })
          .then((response) => {
            if (response.data.datos.length > 0) {
              this.motivoReconsulta = response.data.datos[0].motivo;
              this.horaReconsulta = response.data.datos[0].hora;
              this.diaReconsulta = response.data.datos[0].dia;
            }
            axios
              .post(url, {
                opcion: 9,
              })
              .then((response) => {
                this.options = response.data.data;
                this.selectedCodigo = localStorage.getItem(
                  localStorage.getItem("consecutivo").toString().concat("selectedCodigo")
                );
                axios
                  .post(url, {
                    opcion: 10,
                  })
                  .then((response) => {
                    this.sintomas = response.data.data;
                    this.selectedSintomas = JSON.parse(
                      localStorage.getItem(
                        localStorage
                          .getItem("consecutivo")
                          .toString()
                          .concat("selectedSintomas")
                      )
                    );
                    axios
                      .post(url, {
                        consecutivo: localStorage.getItem("consecutivo"),
                        opcion: 4,
                      })
                      .then((response) => {
                        var Datos = response.data.data[0];
                        if (
                          localStorage.getItem(
                            localStorage
                              .getItem("consecutivo")
                              .toString()
                              .concat("antecedentes")
                          ) != null
                        ) {
                          this.antecedentes = localStorage.getItem(
                            localStorage
                              .getItem("consecutivo")
                              .toString()
                              .concat("antecedentes")
                          );
                        } else {
                          this.antecedentes = Datos.bentxt;
                        }
                        if (Datos.motivo == null || Datos.motivo == "") {
                          this.motivo = localStorage.getItem(
                            localStorage
                              .getItem("consecutivo")
                              .toString()
                              .concat("motivo")
                          );
                        } else {
                          this.motivo = Datos.motivo;
                        }
                        this.nombreMascota = Datos.bennm1;
                        this.edad = Datos.edad;
                        this.documentoTitular = Datos.titnit;
                        this.sexo = Datos.bensex == "M" ? "Masculino" : "Femenino";
                        this.direccion = Datos.bendir;
                        this.triaje = Datos.reqcda;
                        this.horaLlamada = Datos.reqhor;
                        this.consulta =
                          Datos.ult_traslado == 30 || Datos.ult_traslado == 40
                            ? "Si"
                            : Datos.ult_traslado == 10 ||
                              Datos.ult_traslado == 20 ||
                              Datos.ult_traslado == 41
                            ? "No"
                            : "No Aplica";
                        this.operador_2 = Datos.NomUsuario;
                        this.medico_2 = Datos.Medico;
                        this.auxiliar_2 = Datos.Auxiliar;
                        this.conductor_2 = Datos.Conductor;

                        this.documento = Datos.bendoc;
                        axios
                          .post(url, {
                            opcion: 28,
                          })
                          .then((response) => {
                            console.log(response);
                            this.medicamentos = response.data.data;
                            this.selectedMedicamento = JSON.parse(
                              localStorage.getItem(
                                localStorage
                                  .getItem("consecutivo")
                                  .toString()
                                  .concat("selectedMedicamento")
                              )
                            );
                            axios
                              .post(url, {
                                opcion: 30,
                              })
                              .then((response) => {
                                console.log(response);
                                this.diferencial = response.data[0];
                                this.selectedDiferencial = JSON.parse(
                                  localStorage.getItem(
                                    localStorage
                                      .getItem("consecutivo")
                                      .toString()
                                      .concat("selectedDiferencial")
                                  )
                                );
                                this.impresion = response.data[1];
                                this.selectedImpresion = JSON.parse(
                                  localStorage.getItem(
                                    localStorage
                                      .getItem("consecutivo")
                                      .toString()
                                      .concat("selectedImpresion")
                                  )
                                );
                                $("#boxLoading").removeClass("loading");
                              });
                          });
                      });
                  });
              });
          });
      });

    document.addEventListener("click", (event) => {
      if (event.path[0].id != "ellipsis-v") {
        this.Mostrar = false;
      }
    });

    document.body.style.backgroundColor = "white";
  },
  methods: {
    salir: function () {
      this.$router.replace({
        name: "Home",
      });
    },
    finalizar: function () {
      var hora = new Date();
      var texto = "".concat(
        hora.getHours().toString().length > 1
          ? hora.getHours().toString()
          : "0".concat(hora.getHours().toString()),
        ":",
        hora.getMinutes().toString().length > 1
          ? hora.getMinutes().toString()
          : "0".concat(hora.getMinutes().toString())
      );
      var examenDevuelto = [];
      var examenDevuelto2 = "";
      if (this.examenSelected != null) {
        this.Examen.forEach((element) => {
          this.examenSelected.forEach((element2) => {
            if (element.valor == element2) {
              examenDevuelto.push(element.clave);
              examenDevuelto2 = examenDevuelto2 + "" + element.valor + " ,";
            }
          });
        });
      }

      if (
        localStorage.getItem(
          localStorage.getItem("consecutivo").toString().concat("firma")
        ) != null &&
        localStorage.getItem(
          localStorage.getItem("consecutivo").toString().concat("rechazar")
        ) != null && this.codigo != null
      ) {
        $("#boxLoading").addClass("loading");
        if ("geolocation" in navigator) {
          const onUbicacionConcedida = (ubicacion) => {
            localStorage.setItem(
              localStorage.getItem("consecutivo").toString().concat("location"),
              "".concat(ubicacion.coords.latitude, ",", ubicacion.coords.longitude)
            );
            if (this.traslado == true || this.traslado == "true") {
              if (
                this.salidaColor != "#efefef" ||
                this.llegadaColor != "#efefef" ||
                this.salidaInsColor != "#efefef"
              ) {
                this.$swal.fire("Aún no has registrado todas las horas", "", "error");
              } else {
                axios
                  .post(url, {
                    consecutivo: localStorage.getItem("consecutivo"),
                    opcion: 14,
                    reconsulta: this.checked ? 1 : 0,
                    codigo: this.codigo ?? "",
                    codigo_diagnostica: this.selectedCodigo,
                    impresion_diagnostica:
                      this.selectedImpresion == null
                        ? ""
                        : this.selectedImpresion.join(", "),
                    examen: this.examen ?? "",
                    revision: this.revision ?? "",
                    sintomas:
                      this.selectedSintomas == null
                        ? ""
                        : this.selectedSintomas.join(", "),
                    motivo: this.motivo ?? "",
                    antecedentes: this.antecedentes ?? "",
                    tratamiento: this.tratamiento ?? "",
                    traslado: this.traslado ? 1 : 0,
                    hora: localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("hora")
                    ),
                    tamano:
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("tamano")
                      ) == "undefined" ||
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("tamano")
                      ) == null
                        ? ""
                        : JSON.parse(
                            localStorage.getItem(
                              localStorage
                                .getItem("consecutivo")
                                .toString()
                                .concat("tamano")
                            )
                          ).clave ?? "",
                    comida:
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("comida")
                      ) == "undefined" ||
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("comida")
                      ) == null
                        ? ""
                        : JSON.parse(
                            localStorage.getItem(
                              localStorage
                                .getItem("consecutivo")
                                .toString()
                                .concat("comida")
                            )
                          ).clave ?? "",
                    peso:
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("peso")
                      ) ?? "",
                    comidas:
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("comidas")
                      ) ?? "",
                    cantidad:
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("cantidad")
                      ) ?? "",
                    marca:
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("marca")
                      ) ?? "",
                    examenPrevia:
                      this.examenSelected == null ? "" : this.examenSelected.join(", "),
                    calMedico: this.medico ?? "",
                    calAuxiliar: this.auxiliar ?? "",
                    calOperador: this.operador ?? "",
                    rechazar: this.rechazar ?? "",
                    movil: this.movil ?? "",
                    id: this.id ?? "",
                    diferencial:
                      this.selectedDiferencial == null
                        ? ""
                        : this.selectedDiferencial.join(", "),
                    formula: this.formula ?? "",
                    location: "".concat(
                      ubicacion.coords.latitude,
                      ",",
                      ubicacion.coords.longitude
                    ),
                    examenes: examenDevuelto,
                    medicamentos: this.selectedMedicamento,
                    medico: this.datos.nombre,
                    mascota: this.nombreMascota,
                    documentoTitular: this.documentoTitular,
                    documentoMascota: this.documento,
                    propietario: this.nombre,
                    horaFin: texto,
                    sexo: this.sexo,
                    raza: this.raza,
                    especie: this.especie,
                    direccion: this.direccion,
                    triaje: this.triaje,
                    horaLlamada: this.horaLlamada,
                    consulta: this.consulta,
                    operador_2: this.operador_2,
                    medico_2: this.medico_2,
                    auxiliar_2: this.auxiliar_2,
                    conductor_2: this.conductor_2,
                    fechaDespara: this.fechaDespara,
                    edad: this.edad,
                    mail: this.mail,
                    examenDevuelto2: examenDevuelto2,
                    documento: this.datos.documento,
                    FC: this.FC,
                    FR: this.FR,
                    temperatura: this.temperatura,
                    CC: this.CC,
                    PASDAS: this.PASDAS,
                    pulso: this.pulso,
                  })
                  .then((response) => {
                    $("#boxLoading").removeClass("loading");
                    if (response.data.ok) {
                      this.$swal
                        .fire({
                          title: "Datos Previamente Ingresados",
                          icon: `error`,
                        })
                        .then(() => {
                          this.$router.replace({
                            name: "Final",
                          });
                        });
                    } else {
                      this.$swal
                        .fire({
                          title: "Datos Ingresados Correctamente",
                          confirmButtonText: `Aceptar`,
                          icon: `success`,
                        })
                        .then(() => {
                          /* Read more about isConfirmed, isDenied below */

                          this.$router.replace({
                            name: "Final",
                          });
                        });
                    }
                  });
              }
            } else {
              console.log("ASD");
              axios
                .post(url, {
                  consecutivo: localStorage.getItem("consecutivo"),
                  opcion: 14,
                  reconsulta: this.checked ? 1 : 0,
                  codigo: this.codigo ?? "",
                  codigo_diagnostica: this.selectedCodigo,
                  impresion_diagnostica:
                    this.selectedImpresion == null
                      ? ""
                      : this.selectedImpresion.join(", "),
                  examen: this.examen ?? "",
                  revision: this.revision ?? "",
                  sintomas:
                    this.selectedSintomas == null ? "" : this.selectedSintomas.join(", "),
                  motivo: this.motivo ?? "",
                  antecedentes: this.antecedentes ?? "",
                  tratamiento: this.tratamiento ?? "",
                  traslado: this.traslado ? 1 : 0,
                  hora: localStorage.getItem(
                    localStorage.getItem("consecutivo").toString().concat("hora")
                  ),
                  tamano:
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("tamano")
                    ) == "undefined" ||
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("tamano")
                    ) == null
                      ? ""
                      : JSON.parse(
                          localStorage.getItem(
                            localStorage
                              .getItem("consecutivo")
                              .toString()
                              .concat("tamano")
                          )
                        ).clave ?? "",
                  comida:
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("comida")
                    ) == "undefined" ||
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("comida")
                    ) == null
                      ? ""
                      : JSON.parse(
                          localStorage.getItem(
                            localStorage
                              .getItem("consecutivo")
                              .toString()
                              .concat("comida")
                          )
                        ).clave ?? "",
                  peso:
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("peso")
                    ) ?? "",
                  comidas:
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("comidas")
                    ) ?? "",
                  cantidad:
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("cantidad")
                    ) ?? "",
                  marca:
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("marca")
                    ) ?? "",
                  examenPrevia:
                    this.examenSelected == null ? "" : this.examenSelected.join(", "),
                  calMedico: this.medico ?? "",
                  calAuxiliar: this.auxiliar ?? "",
                  calOperador: this.operador ?? "",
                  rechazar: this.rechazar ?? "",
                  movil: this.movil ?? "",
                  id: this.id ?? "",
                  diferencial:
                    this.selectedDiferencial == null
                      ? ""
                      : this.selectedDiferencial.join(", "),
                  formula: this.formula ?? "",
                  location: "".concat(
                    ubicacion.coords.latitude,
                    ",",
                    ubicacion.coords.longitude
                  ),
                  examenes: examenDevuelto,
                  medicamentos: this.selectedMedicamento,
                  medico: this.datos.nombre,
                  mascota: this.nombreMascota,
                  documentoTitular: this.documentoTitular,
                  documentoMascota: this.documento,
                  propietario: this.nombre,
                  horaFin: texto,
                  sexo: this.sexo,
                  raza: this.raza,
                  especie: this.especie,
                  direccion: this.direccion,
                  triaje: this.triaje,
                  horaLlamada: this.horaLlamada,
                  consulta: this.consulta,
                  operador_2: this.operador_2,
                  medico_2: this.medico_2,
                  auxiliar_2: this.auxiliar_2,
                  conductor_2: this.conductor_2,
                  fechaDespara: this.fechaDespara,
                  edad: this.edad,
                  mail: this.mail,
                  examenDevuelto2: examenDevuelto2,
                  documento: this.datos.documento,
                  FC: this.FC,
                  FR: this.FR,
                  temperatura: this.temperatura,
                  CC: this.CC,
                  PASDAS: this.PASDAS,
                  pulso: this.pulso,
                })
                .then((response) => {
                  $("#boxLoading").removeClass("loading");
                  if (response.data.ok) {
                    this.$swal
                      .fire({
                        title: "Datos Previamente Ingresados",
                        icon: `error`,
                      })
                      .then(() => {
                        this.$router.replace({
                          name: "Final",
                        });
                      });
                  } else {
                    this.$swal
                      .fire({
                        title: "Datos Ingresados Correctamente",
                        confirmButtonText: `Aceptar`,
                        icon: `success`,
                      })
                      .then(() => {
                        /* Read more about isConfirmed, isDenied below */

                        this.$router.replace({
                          name: "Final",
                        });
                      });
                  }
                });
            }
          };

          const onErrorDeUbicacion = () => {
            console.log("ha");
            if (this.traslado == true || this.traslado == "true") {
              if (
                this.salidaColor != "#efefef" ||
                this.llegadaColor != "#efefef" ||
                this.salidaInsColor != "#efefef"
              ) {
                this.$swal.fire("Aún no has registrado todas las horas", "", "error");
              } else {
                axios
                  .post(url, {
                    consecutivo: localStorage.getItem("consecutivo"),
                    opcion: 14,
                    reconsulta: this.checked ? 1 : 0,
                    codigo: this.codigo ?? "",
                    codigo_diagnostica: this.selectedCodigo,
                    impresion_diagnostica:
                      this.selectedImpresion == null
                        ? ""
                        : this.selectedImpresion.join(", "),
                    examen: this.examen ?? "",
                    revision: this.revision ?? "",
                    sintomas:
                      this.selectedSintomas == null
                        ? ""
                        : this.selectedSintomas.join(", "),
                    motivo: this.motivo ?? "",
                    antecedentes: this.antecedentes ?? "",
                    tratamiento: this.tratamiento ?? "",
                    traslado: this.traslado ? 1 : 0,
                    hora: localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("hora")
                    ),
                    tamano:
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("tamano")
                      ) == "undefined" ||
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("tamano")
                      ) == null
                        ? ""
                        : JSON.parse(
                            localStorage.getItem(
                              localStorage
                                .getItem("consecutivo")
                                .toString()
                                .concat("tamano")
                            )
                          ).clave ?? "",
                    comida:
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("comida")
                      ) == "undefined" ||
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("comida")
                      ) == null
                        ? ""
                        : JSON.parse(
                            localStorage.getItem(
                              localStorage
                                .getItem("consecutivo")
                                .toString()
                                .concat("comida")
                            )
                          ).clave ?? "",
                    peso:
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("peso")
                      ) ?? "",
                    comidas:
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("comidas")
                      ) ?? "",
                    cantidad:
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("cantidad")
                      ) ?? "",
                    marca:
                      localStorage.getItem(
                        localStorage.getItem("consecutivo").toString().concat("marca")
                      ) ?? "",
                    examenPrevia:
                      this.examenSelected == null ? "" : this.examenSelected.join(", "),
                    calMedico: this.medico ?? "",
                    calAuxiliar: this.auxiliar ?? "",
                    calOperador: this.operador ?? "",
                    rechazar: this.rechazar ?? "",
                    movil: this.movil ?? "",
                    id: this.id ?? "",
                    diferencial:
                      this.selectedDiferencial == null
                        ? ""
                        : this.selectedDiferencial.join(", "),
                    formula: this.formula ?? "",
                    location: "",
                    examenes: examenDevuelto,
                    medicamentos: this.selectedMedicamento,
                    medico: this.datos.nombre,
                    mascota: this.nombreMascota,
                    documentoTitular: this.documentoTitular,
                    documentoMascota: this.documento,
                    propietario: this.nombre,
                    horaFin: texto,
                    sexo: this.sexo,
                    raza: this.raza,
                    especie: this.especie,
                    direccion: this.direccion,
                    triaje: this.triaje,
                    horaLlamada: this.horaLlamada,
                    consulta: this.consulta,
                    operador_2: this.operador_2,
                    medico_2: this.medico_2,
                    auxiliar_2: this.auxiliar_2,
                    conductor_2: this.conductor_2,
                    fechaDespara: this.fechaDespara,
                    edad: this.edad,
                    mail: this.mail,
                    examenDevuelto2: examenDevuelto2,
                    documento: this.datos.documento,
                    FC: this.FC,
                    FR: this.FR,
                    temperatura: this.temperatura,
                    CC: this.CC,
                    PASDAS: this.PASDAS,
                    pulso: this.pulso,
                  })
                  .then((response) => {
                    $("#boxLoading").removeClass("loading");
                    if (response.data.ok) {
                      this.$swal
                        .fire({
                          title: "Datos Previamente Ingresados",
                          icon: `error`,
                        })
                        .then(() => {
                          this.$router.replace({
                            name: "Final",
                          });
                        });
                    } else {
                      this.$swal
                        .fire({
                          title: "Datos Ingresados Correctamente",
                          confirmButtonText: `Aceptar`,
                          icon: `success`,
                        })
                        .then(() => {
                          /* Read more about isConfirmed, isDenied below */

                          this.$router.replace({
                            name: "Final",
                          });
                        });
                    }
                  });
              }
            } else {
              console.log(this.revision);
              axios
                .post(url, {
                  consecutivo: localStorage.getItem("consecutivo"),
                  opcion: 14,
                  reconsulta: this.checked ? 1 : 0,
                  codigo: this.codigo ?? "",
                  codigo_diagnostica: this.selectedCodigo,
                  impresion_diagnostica:
                    this.selectedImpresion == null
                      ? ""
                      : this.selectedImpresion.join(", "),
                  examen: this.examen ?? "",
                  revision: this.revision ?? "",
                  sintomas:
                    this.selectedSintomas == null ? "" : this.selectedSintomas.join(", "),
                  motivo: this.motivo ?? "",
                  antecedentes: this.antecedentes ?? "",
                  tratamiento: this.tratamiento ?? "",
                  traslado: this.traslado ? 1 : 0,
                  hora: localStorage.getItem(
                    localStorage.getItem("consecutivo").toString().concat("hora")
                  ),
                  tamano:
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("tamano")
                    ) == "undefined" ||
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("tamano")
                    ) == null
                      ? ""
                      : JSON.parse(
                          localStorage.getItem(
                            localStorage
                              .getItem("consecutivo")
                              .toString()
                              .concat("tamano")
                          )
                        ).clave ?? "",
                  comida:
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("comida")
                    ) == "undefined" ||
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("comida")
                    ) == null
                      ? ""
                      : JSON.parse(
                          localStorage.getItem(
                            localStorage
                              .getItem("consecutivo")
                              .toString()
                              .concat("comida")
                          )
                        ).clave ?? "",
                  peso:
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("peso")
                    ) ?? "",
                  comidas:
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("comidas")
                    ) ?? "",
                  cantidad:
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("cantidad")
                    ) ?? "",
                  marca:
                    localStorage.getItem(
                      localStorage.getItem("consecutivo").toString().concat("marca")
                    ) ?? "",
                  examenPrevia:
                    this.examenSelected == null ? "" : this.examenSelected.join(", "),
                  calMedico: this.medico ?? "",
                  calAuxiliar: this.auxiliar ?? "",
                  calOperador: this.operador ?? "",
                  rechazar: this.rechazar ?? "",
                  movil: this.movil ?? "",
                  id: this.id ?? "",
                  diferencial:
                    this.selectedDiferencial == null
                      ? ""
                      : this.selectedDiferencial.join(", "),
                  formula: this.formula ?? "",
                  location: "",
                  examenes: examenDevuelto,
                  medicamentos: this.selectedMedicamento,
                  medico: this.datos.nombre,
                  mascota: this.nombreMascota,
                  documentoTitular: this.documentoTitular,
                  documentoMascota: this.documento,
                  propietario: this.nombre,
                  horaFin: texto,
                  sexo: this.sexo,
                  raza: this.raza,
                  especie: this.especie,
                  direccion: this.direccion,
                  triaje: this.triaje,
                  horaLlamada: this.horaLlamada,
                  consulta: this.consulta,
                  operador_2: this.operador_2,
                  medico_2: this.medico_2,
                  auxiliar_2: this.auxiliar_2,
                  conductor_2: this.conductor_2,
                  fechaDespara: this.fechaDespara,
                  edad: this.edad,
                  mail: this.mail,
                  examenDevuelto2: examenDevuelto2,
                  documento: this.datos.documento,
                  FC: this.FC,
                  FR: this.FR,
                  temperatura: this.temperatura,
                  CC: this.CC,
                  PASDAS: this.PASDAS,
                  pulso: this.pulso,
                })
                .then((response) => {
                  $("#boxLoading").removeClass("loading");
                  if (response.data.ok) {
                    this.$swal
                      .fire({
                        title: "Datos Previamente Ingresados",
                        icon: `error`,
                      })
                      .then(() => {
                        this.$router.replace({
                          name: "Final",
                        });
                      });
                  } else {
                    this.$swal
                      .fire({
                        title: "Datos Ingresados Correctamente",
                        confirmButtonText: `Aceptar`,
                        icon: `success`,
                      })
                      .then(() => {
                        /* Read more about isConfirmed, isDenied below */

                        this.$router.replace({
                          name: "Final",
                        });
                      });
                  }
                });
            }
          };

          const opcionesDeSolicitud = {
            enableHighAccuracy: true, // Alta precisión
            maximumAge: 0, // No queremos caché
            timeout: 20000, // Esperar solo 5 segundos
          };
          // Solicitar
          navigator.geolocation.getCurrentPosition(
            onUbicacionConcedida,
            onErrorDeUbicacion,
            opcionesDeSolicitud
          );
        } else {
          $("#boxLoading").removeClass("loading");
          this.$swal.fire("Geolocalización no disponible", "", "error");
        }
      } else {
        this.$swal.fire(
          "Error, alguno de los siguientes registro no están diligenciados correctamente. Firmar consulta, calificar consulta o código de valoración.",
          "",
          "error"
        );
      }
    },
    salida: function () {
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 11,
          consecutivo: localStorage.getItem("consecutivo"),
        })
        .then((response) => {
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("salida"),
            true
          );

          if (response.data.ok == true) {
            this.$swal.fire("Hora Registrada exitosamente", "", "success");
          } else {
            this.$swal.fire("Error ya registro hora", "", "error");
          }
          $("#boxLoading").removeClass("loading");
        });

      this.salidaColor = "#efefef";
    },
    llegada: function () {
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 12,
          consecutivo: localStorage.getItem("consecutivo"),
        })
        .then((response) => {
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("llegada"),
            true
          );

          if (response.data.ok == true) {
            this.$swal.fire("Hora Registrada exitosamente", "", "success");
          } else {
            this.$swal.fire("Error ya registro hora", "", "error");
          }
          $("#boxLoading").removeClass("loading");
        });

      this.llegadaColor = "#efefef";
    },
    salidaIns: function () {
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 13,
          consecutivo: localStorage.getItem("consecutivo"),
        })
        .then((response) => {
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("salidaIns"),
            true
          );

          if (response.data.ok == true) {
            this.$swal.fire("Hora Registrada exitosamente", "", "success");
          } else {
            this.$swal.fire("Error ya registro hora", "", "error");
          }
          $("#boxLoading").removeClass("loading");
        });

      this.salidaInsColor = "#efefef";
    },
    cambios: function (valor) {
      switch (valor) {
        case 1:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("antecedentes"),
            this.antecedentes
          );

          break;
        case 2:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("motivo"),
            this.motivo
          );

          break;
        case 3:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("selectedSintomas"),
            JSON.stringify(this.selectedSintomas)
          );

          break;
        case 4:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("revision"),
            this.revision
          );

          break;
        case 5:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("examen"),
            this.examen
          );

          break;
        case 6:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("selectedImpresion"),
            JSON.stringify(this.selectedImpresion)
          );
          break;
        case 7:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("selectedCodigo"),
            this.selectedCodigo
          );
          break;
        case 8:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("codigo"),
            this.codigo
          );
          break;
        case 9:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("tratamiento"),
            this.tratamiento
          );
          break;
        case 10:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("checked"),
            this.checked
          );

          break;
        case 11:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("traslado"),
            this.traslado
          );

          break;
        case 12:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("examenSelected"),
            JSON.stringify(this.examenSelected)
          );
          break;
        case 13:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("medico"),
            this.medico
          );

          break;
        case 14:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("auxiliar"),
            this.auxiliar
          );

          break;
        case 15:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("operador"),
            this.operador
          );

          break;
        case 16:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("selectedDiferencial"),
            JSON.stringify(this.selectedDiferencial)
          );

          break;
        case 17:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("formula"),
            this.formula
          );

          break;
        case 18:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("selectedMedicamento"),
            JSON.stringify(this.selectedMedicamento)
          );
          break;
        case 19:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("FC"),
            this.FC
          );
          break;
        case 20:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("FR"),
            this.FR
          );
          break;
        case 21:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("temperatura"),
            this.temperatura
          );
          break;
        case 22:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("CC"),
            this.CC
          );
          break;
        case 23:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("PASDAS"),
            this.PASDAS
          );
          break;
        case 24:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("pulso"),
            this.pulso
          );
          break;

        default:
          break;
      }
    },
    guardarModal: function () {
      this.modalHistorias = false;
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 15,
          codigo: this.codigoModal,
          descripcion: this.descripcionModal,
        })
        .then((response) => {
          this.options = response.data.data;
          this.codigoModal = "";
          this.descripcionModal = "";
          this.$swal.fire("Datos Ingresados!", "", "success");
          $("#boxLoading").removeClass("loading");
        });
    },
    guardarModalSintomas: function () {
      this.modalSintomas = false;
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 25,
          descripcion: this.descripcionSintoma,
        })
        .then((response) => {
          this.sintomas = response.data.data;
          this.$swal.fire("Datos Ingresados!", "", "success");
          $("#boxLoading").removeClass("loading");
        });
    },
    atras: function () {
      this.$router.replace({
        name: "Previa",
      });
    },
    guardarModal2: function () {
      $("#boxLoading").addClass("loading");
      this.modalHistorias2 = false;
      axios
        .post(url, {
          opcion: 17,

          descripcion: this.examenModal,
        })
        .then((response) => {
          this.Examen = response.data.examen;
          this.examenModal = "";
          this.$swal.fire("Datos Ingresados!", "", "success");
          $("#boxLoading").removeClass("loading");
        });
    },
    guardarModal3: function () {
      $("#boxLoading").addClass("loading");
      this.modalImpresion = false;
      axios
        .post(url, {
          opcion: 31,
          impresion: this.nuevaImpresion,
        })
        .then((response) => {
          this.impresion = response.data[0];
          this.nuevaImpresion = "";
          this.$swal.fire("Datos Ingresados!", "", "success");
          $("#boxLoading").removeClass("loading");
        });
    },
    guardarModal4: function () {
      $("#boxLoading").addClass("loading");
      this.modalDiferencial = false;
      axios
        .post(url, {
          opcion: 32,
          diferencial: this.nuevaDiferencial,
        })
        .then((response) => {
          this.diferencial = response.data[0];
          this.nuevaDiferencial = "";
          this.$swal.fire("Datos Ingresados!", "", "success");
          $("#boxLoading").removeClass("loading");
        });
    },
    guardarCalificar: function () {
      this.modalCalificar = false;
      this.calificar = false;
      this.rechazar = 0;
      this.colorCalificar = "#efefef";
      this.colorCalificar2 = "black";
      localStorage.setItem(
        localStorage.getItem("consecutivo").toString().concat("rechazar"),
        this.rechazar
      );
    },
    rechazar: function () {
      this.modalCalificar = false;
      this.calificar = false;
      this.rechazar = 1;
      this.colorCalificar = "#efefef";
      this.colorCalificar2 = "black";
      localStorage.setItem(
        localStorage.getItem("consecutivo").toString().concat("rechazar"),
        this.rechazar
      );
    },
    Calificar: function () {
      if (
        localStorage.getItem(
          localStorage.getItem("consecutivo").toString().concat("rechazar")
        ) != null
      ) {
        this.$swal.fire("Ya has calificado la encuesta", "", "error");
      } else {
        this.modalCalificar = true;
      }
    },
    pruebaz: async function (param) {
      $("#boxLoading").addClass("loading");

      if (param == 1) {
        await document.getElementById("file").files.forEach((element) => {
          var documento = this.documento;
          var usuario = this.usuario;
          // var contador = 1;
          const MAX_WIDTH = 600;
          const MAX_HEIGHT = 600;
          /* const MIME_TYPE = "image/jpeg";
            const QUALITY = 0.7; */
          const file = element;
          const blobURL = URL.createObjectURL(file);
          const img = new Image();
          img.src = blobURL;
          img.onload = function () {
            URL.revokeObjectURL(this.src);
            let width = img.width;
            let height = img.height;

            // calculate the width and height, constraining the proportions
            if (width > height) {
              if (width > MAX_WIDTH) {
                height = Math.round((height * MAX_WIDTH) / width);
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width = Math.round((width * MAX_HEIGHT) / height);
                height = MAX_HEIGHT;
              }
            }

            const [newWidth, newHeight] = [width, height];
            const canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            axios
              .post(url2, {
                id: localStorage.getItem("consecutivo"),
                documento: documento,
                imgBase64: canvas.toDataURL(),
                usuario: usuario,
                opcion: 1,
              })
              .then(() => {});
          };
        });
      } else if (param == 3) {
        await document.getElementById("fileFormula").files.forEach((element) => {
          var documento = this.documento;
          var usuario = this.usuario;
          // var contador = 1;
          const MAX_WIDTH = 300;
          const MAX_HEIGHT = 300;
          /* const MIME_TYPE = "image/jpeg";
            const QUALITY = 0.7; */
          const file = element;
          const blobURL = URL.createObjectURL(file);
          const img = new Image();
          img.src = blobURL;
          img.onload = function () {
            URL.revokeObjectURL(this.src);
            let width = img.width;
            let height = img.height;

            // calculate the width and height, constraining the proportions
            if (width > height) {
              if (width > MAX_WIDTH) {
                height = Math.round((height * MAX_WIDTH) / width);
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width = Math.round((width * MAX_HEIGHT) / height);
                height = MAX_HEIGHT;
              }
            }

            const [newWidth, newHeight] = [width, height];
            const canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            axios
              .post(url2, {
                id: localStorage.getItem("consecutivo"),
                documento: documento,
                imgBase64: canvas.toDataURL(),
                usuario: usuario,
                opcion: 3,
              })
              .then(() => {});
          };
        });
      }
      $("#boxLoading").removeClass("loading");
      this.$swal.fire("Imagen Registrada", "", "success");
    },
    guardarFirma: function (valor) {
      var can = this.$refs.signaturePad.signaturePadCanvas;
      var ctx = can.getContext("2d");
      if (valor == 1) {
        $("#boxLoading").addClass("loading");
        var documento = this.documento;
        var img = new Image();
        img.src = can.toDataURL();
        console.log(img.src);
        this.modalFirma = false;

        axios
          .post(url3, {
            imgBase64: img.src,
            documento: documento,
          })
          .then(() => {
            $("#boxLoading").removeClass("loading");
            ctx.clearRect(0, 0, can.width, can.height);
            this.modalFirma = false;
            this.$swal.fire("Firma ingresada", "", "success");
            this.colorFirma = "#efefef";
            this.colorFirma2 = "black";
            localStorage.setItem(
              localStorage.getItem("consecutivo").toString().concat("firma"),
              true
            );
          });
      }
      if (valor == 3) {
        ctx.clearRect(0, 0, can.width, can.height);
      }
      if (valor == 2) {
        ctx.clearRect(0, 0, can.width, can.height);
        this.modalFirma = false;
      }
    },
    guardarFisico: function (valor) {
      var can = this.$refs.signaturePadFiscio.signaturePadCanvas;
      var ctx = can.getContext("2d");
      if (valor == 1) {
        $("#boxLoading").addClass("loading");
        var documento = this.documento;
        var especie = this.especie;
        var img = new Image();
        img.src = can.toDataURL();
        console.log(img.src);
        this.modalexamenFisico = false;

        axios
          .post(url4, {
            imgBase64: img.src,
            documento: documento,
            especie: especie,
          })
          .then((response) => {
            $("#boxLoading").removeClass("loading");
            console.log(response);
            // ctx.clearRect(0, 0, can.width, can.height);
            // this.modalexamenFisico = false;
            // this.$swal.fire('Firma ingresada', '', 'success')

            // localStorage.setItem(localStorage.getItem("consecutivo").toString().concat('dibujoFisico'), true);
          });
      }
      if (valor == 3) {
        ctx.clearRect(0, 0, can.width, can.height);
      }
      if (valor == 2) {
        ctx.clearRect(0, 0, can.width, can.height);
        this.modalexamenFisico = false;
      }
    },
    actualizar: function () {
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 19,
          id: this.id,
          mail: this.mail,
        })
        .then(() => {
          $("#boxLoading").removeClass("loading");
          this.$swal.fire({
            title: "Correo actualizado",
            confirmButtonText: `Aceptar`,
            icon: `success`,
          });
        });
    },
    enviar: function () {
      console.log(this.mail);
    },
    click: function (valor) {
      document.querySelectorAll('[role="tooltip"]')[valor].style.display = "none";
    },
    imagenes: function () {
      this.$router.replace({
        name: "Imagenes",
      });
    },
    async modalexamenFisicoActivar() {
      await this.cambiarBackground();

      if (this.especie == "Felino") {
        document.getElementsByTagName("canvas")[0].classList.add("canvasModificar1");
      } else {
        document.getElementsByTagName("canvas")[0].classList.add("canvasModificar2");
      }
    },
    cambiarBackground() {
      this.modalexamenFisico = true;
    },
    registrarDesparasitacion() {
      $("#boxLoading").addClass("loading");

      if ("geolocation" in navigator) {
        const onUbicacionConcedida = (ubicacion) => {
          axios
            .post(url, {
              opcion: 44,
              medico: this.datos.nombre + " " + this.datos.apellido,
              usuario: this.id,
              location: ubicacion.coords.latitude + "," + ubicacion.coords.longitude,
            })
            .then((response) => {
              if (response.data) {
                this.$swal.fire("Desparasitación Registrada!", "", "success");
              } else {
                this.$swal.fire(
                  "Error Desparasitación Registrada Previamente",
                  "",
                  "error"
                );
              }
              $("#boxLoading").removeClass("loading");
            });
        };

        const onErrorDeUbicacion = () => {
          axios
            .post(url, {
              opcion: 44,
              medico: this.datos.nombre + " " + this.datos.apellido,
              usuario: this.id,
              location: "falta coordenada",
            })
            .then((response) => {
              if (response.data) {
                this.$swal.fire("Desparasitación Registrada!", "", "success");
              } else {
                this.$swal.fire(
                  "Error Desparasitación Registrada Previamente",
                  "",
                  "error"
                );
              }
              $("#boxLoading").removeClass("loading");
            });
        };

        const opcionesDeSolicitud = {
          enableHighAccuracy: true, // Alta precisión
          maximumAge: 0, // No queremos caché
          timeout: 20000, // Esperar solo 5 segundos
        };
        // Solicitar
        navigator.geolocation.getCurrentPosition(
          onUbicacionConcedida,
          onErrorDeUbicacion,
          opcionesDeSolicitud
        );
      } else {
        $("#boxLoading").removeClass("loading");
        this.$swal.fire("Geolocalización no disponible", "", "error");
      }
    },
    guardarMedicamento() {
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 46,
          medicamento: this.nuevoMedicamento,
        })
        .then((response) => {
          $("#boxLoading").removeClass("loading");
          this.medicamentos = response.data;
          this.$swal.fire({
            title: "Medicamento Agregado",
            confirmButtonText: `Aceptar`,
            icon: `success`,
          });
        });
    },
  },
};
</script>

<style>
h1 {
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  top: -10px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

h1 {
  color: white;
}

.listas {
  padding: 10px;
  border-bottom: black solid 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.listas:hover {
  -webkit-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
}

#contenedor {
  display: inline-block;
  min-width: 96vw;
}

.texto2 {
  border-bottom: black solid 1px;
}

.p-dropdown-item {
  overflow: auto !important;
  white-space: break-spaces !important;
}

.p-dropdown-items-wrapper {
  max-width: 100vw;
}

label.cameraButton {
  display: inline-block;
  margin: 1em 0;

  /* Styles to make it look like a button */
  padding: 0.5em;
  border: none;
  background-color: none;
}

/* Look like a clicked/depressed button */

/* This is the part that actually hides the 'Choose file' text box for camera inputs */
label.cameraButton input[accept*="camera"] {
  display: none;
}
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
  opacity: 0.8;
}
.el-select .el-select__tags-text {
  font-size: 120%;
}
.p-inputtext {
  font-size: 1.3rem !important;
}
.el-input__inner {
  font-size: 1.3rem !important;
}
.el-select-dropdown__item {
  font-size: 1.2rem !important;
}
.el-select-dropdown__item {
  font-size: 1.2rem !important;
}
.canvasModificar1 {
  background: url("../assets/excamen_fiscio_gato_dorsal_vantral.png") center;
}
.canvasModificar2 {
  background: url("../assets/excamen_fiscio_perro_dorsal_vantral.png") center;
}
.form-group {
  display: flex;
  justify-content: center;
}
.bold {
  font-weight: bold !important;
}
.p-radiobutton {
  position: relative;
  top: -3px;
}
</style>
