<template>
<div>
    <div class="about" style="background: #4451b4;">
        <div class="w-100 d-flex align-items-center">
            <Nav msg="Historias Clinicas RMV" />
            <div class="dropdown">
                <fa @click="Mostrar=true" id="ellipsis-v" style=" cursor:pointer; position: absolute;right: 0%;margin-right: 10px; color:white; font-size:1.1rem;" icon="ellipsis-v" />
                <div v-if="Mostrar" class="dropdown-content">
                    <p style="cursor:pointer; color:black;" @click="salir()">Salir</p>

                </div>
            </div>
        </div>
        <div class="w-100 d-flex flex-column align-items-center" style="background: rgba(48,62,160,255);">
            <p style="color:white;"> {{ datos.nombre + " " + datos.apellido + " " }}<span>&#183; </span>{{datos.documento }}</p>
            <p style="color:white;"> Móvil <span>&#183; </span>{{movil }}</p>
        </div>

    </div>
    <div style="margin:1rem">
        <p class="text-center mb-0">
            <b>Consecutivo: <span>{{consecutivo}}</span> </b>

        </p>
        <p class="text-center mb-0">
            <b>
                <span>{{nombre}}</span>
            </b>
        </p>
        <p class="text-center mb-0">
            <b>
                <span>{{documento}}</span>
            </b>
        </p>
        <Button class="p-button-raised p-button-success w-100 mb-3" style="color:white;" label="NOTIFICAR HORA DISPONIBLE" @click="notificar()" />
        
    </div>
    <div id="boxLoading"></div>

</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import axios from 'axios'
import $ from 'jquery';
var url = "/php/crud.php";
/* var url = "/php/crud.php"; */
export default {
    name: 'Final',
    components: {
        Nav
    },
    data() {
        return {
            datos: JSON.parse(localStorage.getItem("datos")),
            movil: localStorage.getItem("movil"),
            Mostrar: false,
            consecutivo: localStorage.getItem("consecutivo"),
            nombre: '',
            documento: '',
            id: '',
        }
    },
    mounted() {
        $("#boxLoading").addClass("loading");
        var prueba = localStorage.getItem("prueba");
        if (prueba === null) {
            this.$router.replace({
                name: 'Home'
            });
        }
        axios.post(url, {
            consecutivo: localStorage.getItem("consecutivo"),
            opcion: 3
        }).then(response => {
            var Datos = response.data.data[0];
            this.nombre = Datos.titnom;
            
            this.id = Datos.ID_Ben;

        })

        axios.post(url, {
            consecutivo: localStorage.getItem("consecutivo"),
            opcion: 4
        }).then(response => {
            var Datos = response.data.data[0];
            this.documento = Datos.bendoc;
             $("#boxLoading").removeClass("loading")

        })
        document.addEventListener('click',((event)=>{
            
            if(event.path[0].id != 'ellipsis-v'){
                this.Mostrar = false;
            }
        }))
        document.body.style.backgroundColor = "white";

    },
    methods: {
        salir: function () {

            this.$router.replace({
                name: 'Home'
            });
        },
        notificar: function () {
            axios.post(url, {
                opcion: 18,
                consecutivo: localStorage.getItem("consecutivo"),
            }).then((response) => {
                if(response.data.ok){
                    this.$swal.fire({
                    title: 'Hora de salida registrada previamente',
                    confirmButtonText: `Aceptar`,
                    icon: `error`
                    }).then(() => {
                        /* Read more about isConfirmed, isDenied below */

                        this.$router.replace({
                            name: 'Index'
                        });

                    })
                }else{
                    this.$swal.fire({
                    title: 'Hora de salida registrada',
                    confirmButtonText: `Aceptar`,
                    icon: `success`
                    }).then(() => {
                        /* Read more about isConfirmed, isDenied below */

                        this.$router.replace({
                            name: 'Index'
                        });

                    })

                }
                
            })
        }

    }
}
</script>

<style>
h1 {
    text-align: center;
}

.dropdown {
    position: relative;
    display: inline-block;
    top: -10px;
}

.dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0%;

}

.dropdown:hover .dropdown-content {
    display: block;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

body,
input {
    font-family: "Poppins", sans-serif;

}

h1 {
    color: white;
}

.listas {
    padding: 10px;
    border-bottom: black solid 1px;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.listas:hover {
    -webkit-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
    box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
}

#contenedor {
    display: inline-block;
    min-width: 96vw;
}

.texto2 {
    border-bottom: black solid 1px;

}

.p-dropdown-item {
    overflow: auto !important;
    white-space: break-spaces !important;
}

.p-dropdown-items-wrapper {
    max-width: 100vw;
}
.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif') 50% 50% no-repeat rgb(249, 249, 249);
    opacity: .8;
}
.el-select .el-select__tags-text{
    font-size: 120%;
}
.p-inputtext{
    font-size: 1.3rem !important;
}
.el-input__inner {
    font-size: 1.3rem !important;
}
.el-select-dropdown__item{
    font-size: 1.2rem !important;
}
</style>
