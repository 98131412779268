import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css'
import 'pdfmake'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css'
import 'datatables.net-buttons/js/buttons.html5'
import 'datatables.net-buttons/js/buttons.colVis'
import 'datatables.net-buttons/js/buttons.flash'
import 'datatables.net-buttons/js/buttons.print'
import 'datatables.net-responsive-dt'
import 'datatables.net-responsive/js/dataTables.responsive.min.js'
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
import 'primevue/resources/themes/saga-blue/theme.css'      
import 'primevue/resources/primevue.min.css'           
import 'primeicons/primeicons.css'
import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import VueSignature from "vue3-signature-pad";
library.add(fas)

createApp(App)
.use(router)
.use(VueSweetalert2)
.component('fa',FontAwesomeIcon)
.use(PrimeVue)
.use(ElementPlus)
.use(VueSignature)
.component('Button', Button)
.component('Dropdown', Dropdown)
.component('Textarea',Textarea)
.component('MultiSelect',MultiSelect)
.component('Dialog',Dialog)
.component('RadioButton',RadioButton)
.component('Checkbox',Checkbox)
.component('InputText',InputText)
.mount('#app')
