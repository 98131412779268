<template>
  <div>
    <div class="about" style="background: #4451b4">
      <div class="w-100 d-flex align-items-center">
        <Nav msg="Historias Clinicas RMV" />
        <div class="dropdown">
          <fa
            @click="Mostrar = true"
            style="
              cursor: pointer;
              position: absolute;
              right: 0%;
              margin-right: 10px;
              color: white;
              font-size: 1.1rem;
            "
            id="ellipsis-v"
            icon="ellipsis-v"
          />
          <div v-if="Mostrar" class="dropdown-content">
            <p style="cursor: pointer; color: black" @click="salir()">Salir</p>
          </div>
        </div>
      </div>
      <!--
            Esta seccion aquí nos sirve para desplegar un mini menu que mostrara tres opciones, al darle click en Móvil mostrara un modal que le permitira al usuario
            Ingresar el numero de la móvil con la que va a trabajar
        -->
      <div
        class="w-100 d-flex flex-column align-items-center"
        style="background: rgba(48, 62, 160, 255)"
      >
        <p style="color: white">
          {{ datos.nombre + " " + datos.apellido + " " }}<span>&#183; </span
          >{{ datos.documento }}
        </p>
        <p style="color: white">Móvil <span>&#183; </span>{{ movil }}</p>
      </div>
      <!--
            Esta seccion aquí nos sirve para mostrar algunos datos asociados al usuario que ingreso con el login
         -->
    </div>
    <!--
        Toda esta sección podríamos tomarlo como la cabecera que repetiremos en muchas vistas
    -->
    <div style="font-size: 1.3rem">
      <p class="texto text-center">
        <b
          >Lectura de Examenes de la Consulta: <span>{{ consecutivo }}</span>
        </b>
      </p>
      <div class="container" style="margin-top: 1rem" id="containerTableClinica"></div>
      <div class="d-flex w-100 justify-content-center flex-column">
        <button
          @click="descargar()"
          style="
            margin-left: 5%;
            margin-right: 5%;
            background: #2196f3;
            border-radius: 10px;
            margin-bottom: 1rem;
            color: white;
            width: auto;
            height: auto;
            position: relative;
            top: 5px;
          "
        >
          Descargar
        </button>
      </div>
      <h1 style="color: black">Anotaciones</h1>
      <div class="container mt-3">
        <Textarea
          @change="cambios(3)"
          type="text"
          v-model="anotaciones"
          placeholder="Anotaciones"
        />
      </div>
      <div class="container">
        <p style="margin-top: 1rem">Requiere formula</p>
        <RadioButton id="no1" @change="formular()" value="0" v-model="checked" />
        <label style="margin-right: 1rem" for="no1">No</label>
        <RadioButton id="si1" @change="formular()" value="1" v-model="checked" />
        <label style="margin-right: 1rem" for="si1">Si</label>
      </div>
      <div class="container mt-3">
        <Textarea
          @change="cambios(4)"
          type="text"
          v-model="prueba"
          :disabled="disable"
          placeholder="Formula"
        />
      </div>
      <div class="container">
        <p style="margin-top: 1rem">Requiere Servicio veterinario</p>
        <RadioButton id="no2" @change="cambios(2)" value="0" v-model="checked2" />
        <label style="margin-right: 1rem" for="no2">No</label>
        <RadioButton id="si2" @change="cambios(2)" value="1" v-model="checked2" />
        <label style="margin-right: 1rem" for="si2">Si</label>
      </div>
      <h1 style="color: black">Estado</h1>
      <el-select
        @change="cambios(5)"
        class="w-100 mt-3 mb-3"
        v-model="selectedEstado"
        filterable
        placeholder="Estado de lectura de exámenes"
      >
        <el-option
          v-for="item in Estado"
          :key="item.valor"
          :label="item.valor"
          :value="item.clave"
        >
        </el-option>
      </el-select>
      <div style="font-size: 1.3rem">
        <p class="mb-0" style="margin-left: 1rem">
          <b>
            Enviar Correo
            <span>
              <fa
                @click="actualizar()"
                style="cursor: pointer; right: auto"
                icon="sync-alt"
            /></span>
          </b>
        </p>
        <input
          v-model="mail"
          class="w-100"
          style="
            border: 0;
            border-bottom: 1px solid #ced4da;
            height: 42px;
            padding-left: 10px;
          "
          type="text"
          autocomplete="off"
        />
      </div>

      <div class="d-flex w-100 justify-content-center align-items-center flex-column">
        <button
          @click="finalizar()"
          v-bind:style="{ background: CREAR, color: CREARCOLOR }"
          style="
            border-radius: 10px;
            margin-bottom: 1rem;
            width: 90%;
            position: relative;
            top: 5px;
            font-size: 1rem;
          "
        >
          FINALIZAR LECTURA DE EXÁMENES
        </button>
        <button
          @click="atras()"
          style="
            background: #2196f3;
            border-radius: 10px;
            margin-bottom: 1rem;
            color: white;
            width: 90%;
            position: relative;
            top: 5px;
          "
        >
          ATRAS
        </button>
      </div>

      <!--
            Consecutivo almacenado previamente
        -->

      <!--
            Nombre de la mascota, asociado con el campo bennm1
        -->

      <!--
            Especie de la mascota, 1 para canino 2 para femenino
        -->

      <!--
            Esta sección nos mostrara si en verdad el usuario necesita registrar o no el ciclo de desparasitación para su entrega, aquí va a tener
            un texto cambiante teniendo dos opciones: entregar dosis y no entregar dosis, además cambiar el color del fondo dependiendo de si se entrega o no
            siendo amarillo para si y gris para no.
            Además al darle click abre un modal
        -->

      <!--
            Este es el campo peso
        -->
    </div>
    <div style="background-color: #efefef"></div>

    <div id="boxLoading"></div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import axios from "axios";
import $ from "jquery";
// var url2 = "/php/imagenes.php";
var url = "/php/crud.php";
// var url3 = "/php/prueba.php";
// var url4 = "/php/imagenes_mini.php";
export default {
  name: "Home2",
  components: {
    Nav,
  },
  data() {
    return {
      datos: JSON.parse(localStorage.getItem("datos")),
      movil: localStorage.getItem("movil"),
      Mostrar: false,
      consecutivo: localStorage.getItem("consecutivo"),
      nombre: "",
      nombreMascota: "",
      color: localStorage.getItem("color"),
      texto: localStorage.getItem("texto"),
      raza: "",
      ciclo_Activo: "",
      peso: "",
      fecha: "",
      especie: "",
      dosis: "",
      antecedentes: "",
      notasOperador: "",
      notasServicio: "",
      hora: "",
      consulta: "",
      textoCicloActivo: "",
      validar: false,
      consultaAnterior: "",
      telefonoPropietario: "",
      telefonoServicio: "",
      persona: "",
      documento: "",
      direccion: "",
      signos: "",
      modal: false,
      usuario: "",
      ciclo: "",
      modal2: false,
      RegistrarHora: false,
      fechaDespara: "",
      NOTIFICAR: "#ffee58",
      CREAR: "#efefef",
      CREARCOLOR: "black",
      modalPeso: false,
      src2: "",
      motivo: "",
      diagnostico: "",
      examenes: "",
      tratamiento: "",
      formula: "",
      examenesTabla: [],
      checked: "0",
      checked2: "0",
      prueba: "",
      disable: true,
      Estado: [],
      selectedEstado: "",
      documentoTitular: "",
      anotaciones: "",
      mail: "",
      id: "",
      sexo: "",
      edad: "",
    };
  },
  mounted() {
    $("#boxLoading").addClass("loading");
    if (
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("LErequiereFormula")
      ) != null
    ) {
      this.checked = localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("LErequiereFormula")
      );
    }
    if (
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("LErequiereServicioVet")
      ) != null
    ) {
      this.checked2 = localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("LErequiereServicioVet")
      );
    }
    if (
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("LEanotaciones")
      ) != null
    ) {
      this.anotaciones = localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("LEanotaciones")
      );
    }
    if (
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("LEformula")
      ) != null
    ) {
      this.prueba = localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("LEformula")
      );
    }
    if (
      localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("LErequiereFormula")
      ) != null
    ) {
      this.checked = localStorage.getItem(
        localStorage.getItem("consecutivo").toString().concat("LErequiereFormula")
      );
    }
    if (this.checked == 1) {
      this.disable = false;
    } else {
      this.disable = true;
    }
    this.CREAR = "#4451b4";
    this.CREARCOLOR = "white";

    var prueba = localStorage.getItem("prueba");

    if (prueba === null) {
      this.$router.replace({
        name: "Home",
      });
    }
    document.body.style.backgroundColor = "white";
    axios
      .post(url, {
        consecutivo: localStorage.getItem("consecutivo"),
        opcion: 3,
      })
      .then((response) => {
        var Datos = response.data.data[0];
        this.mail = Datos.mail;
        this.id = Datos.ID_Ben;
        try {
          this.fechaDespara = Datos.fecha.split(" ")[0];
        } catch (error) {
          console.log(error);
        }
        this.nombre = Datos.titnom;
        this.nombreMascota = Datos.bennm1;
        this.raza = Datos.Raza;
        this.ciclo_Activo =
          Datos.cliclo_activo == Datos.desparasitacion_ciclo ? "#fbfb58" : "#8080808c";
        this.textoCicloActivo =
          Datos.cliclo_activo == Datos.desparasitacion_ciclo
            ? "Entregar Dosis"
            : "NO Entregar Dosis";
        this.ciclo = Datos.cliclo_activo;
        this.validar = Datos.cliclo_activo == Datos.desparasitacion_ciclo;
        this.peso = Datos.peso;
        this.especie = Datos.especie;
        this.fecha = Datos.Fecha;
        this.dosis = Datos.tipo_dosis;
        this.usuario = Datos.ID_Ben;
      });
    axios
      .post(url, {
        consecutivo: localStorage.getItem("consecutivo"),
        opcion: 4,
      })
      .then((response) => {
        var Datos = response.data.data[0];
        this.sexo = Datos.bensex;
        this.edad = Datos.edad;
        this.antecedentes = Datos.bentxt;
        this.notasOperador = Datos.reqtxo;
        this.notasServicio = Datos.reqtxt;
        this.hora = Datos.reqhor;
        this.consulta =
          Datos.ult_traslado == 30 || Datos.ult_traslado == 40
            ? "Si"
            : Datos.ult_traslado == 10 ||
              Datos.ult_traslado == 20 ||
              Datos.ult_traslado == 41
            ? "No"
            : "No Aplica";
        try {
          this.consultaAnterior = Datos.ult_diag.concat(
            " ",
            Datos.ult_diag2,
            " (",
            Datos.ult_fecha,
            ", ",
            Datos.ult_hora,
            ")"
          );
        } catch (error) {
          console.log(error);
        }

        this.telefonoPropietario = Datos.bentel;
        this.telefonoServicio = Datos.reqtel;
        this.persona = Datos.reqper;
        this.documento = Datos.bendoc;
        this.documentoTitular = Datos.titnit;
        this.direccion = Datos.bendir;
        this.signos = Datos.reqsig;
        this.motivo = Datos.motivo;
        this.tratamiento = Datos.tratamiento;
        this.formula = Datos.formula;
        this.diagnostico = Datos.diagnostico;
        axios
          .post(url, {
            consecutivo: localStorage.getItem("consecutivo"),
            opcion: 22,
          })
          .then((response) => {
            var Datos = response.data.datos[0];
            this.examenes = Datos.examenes.replace(",", ", ");
            this.examenesTabla = Datos.examenes.split(",");
            var Div_Contenedor_Table = document.getElementById("containerTableClinica");
            Div_Contenedor_Table.innerHTML = "";

            let html = `
                            <div class="row">
                                <div class="col-lg-12">
                                    <table id="Tabla_Beneficiarios" class="table-striped table-bordered" style="width:100%; font-size: 1.1rem;">
                                        <thead class="text-center">
                                            <th>Examen</>
                                        </thead>
                                        <tbody id="Datos_Beneficiarios">
                                        </tbody>
                                    </table>
                                </div>
                            </div> 
                            `;
            Div_Contenedor_Table.append($(html)[0]);
            var Datos_Beneficiarios = document.getElementById("Datos_Beneficiarios");
            this.examenesTabla.forEach((element) => {
              let html_Interno = `
                                <tr>
                                    <td>${element}</td>
                                </tr>
                                `;
              Datos_Beneficiarios.append($(html_Interno)[0]);
            });
            $("#Tabla_Beneficiarios").DataTable({
              responsive: true,
              dom: "Bfrtip",
              buttons: [],
              order: [[0, "desc"]],
              language: {
                info: "Mostrando _START_ de _END_ de _TOTAL_ registros",
                infoEmpty: "Mostrando 0 de 0 de 0 registros",
                search: "Buscar",
                paginate: {
                  previous: "Atras",
                  next: "Siguiente",
                },
              },
            });
            axios
              .post(url, {
                consecutivo: localStorage.getItem("consecutivo"),
                opcion: 24,
              })
              .then((response) => {
                this.Estado = response.data.datos;
                console.log(this.Estado);
                if (
                  localStorage.getItem(
                    localStorage
                      .getItem("consecutivo")
                      .toString()
                      .concat("LEestadoLectura")
                  ) != null
                ) {
                  this.selectedEstado = localStorage.getItem(
                    localStorage
                      .getItem("consecutivo")
                      .toString()
                      .concat("LEestadoLectura")
                  );
                }
                $("#boxLoading").removeClass("loading");
              });
          });
      });
      document.addEventListener('click',((event)=>{
            
            if(event.path[0].id != 'ellipsis-v'){
                this.Mostrar = false;
            }
        }))
  },
  methods: {
    salir: function () {
      this.$router.replace({
        name: "Home",
      });
    },
    atras: function () {
      this.$router.replace({
        name: "Lectura1",
      });
    },
    formular: function () {
      if (this.checked == 1) {
        this.disable = false;
      } else {
        this.disable = true;
      }
      this.cambios(1);
    },
    finalizar: function () {
      this.$swal
        .fire({
          title: "Desea Finalizar La Lectura Del Examen?",
          showCancelButton: true,
          confirmButtonText: `Aceptar`,
          cancelButtonText: `Cancelar`,
          reverseButtons: true,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          $("#boxLoading").addClass("loading");
          if (result.isConfirmed) {
            axios
              .post(url, {
                opcion: 29,
                consecutivo: localStorage.getItem("consecutivo"),
              })
              .then((response) => {
                axios
                  .post(url, {
                    consecutivo: localStorage.getItem("consecutivo"),
                    opcion: 23,
                    medico: this.datos.nombre,
                    formula: this.checked == "0"? 'No Aplica': this.prueba,
                    Estado: this.selectedEstado == "" ? 0 : this.selectedEstado,
                    requiereFormula: this.checked,
                    servicio: this.checked2,
                    anotaciones: this.anotaciones,
                    documento: this.documento,
                    documentoTitular: this.documentoTitular,
                    edad: this.edad,
                    peso: this.peso,
                    nombre: this.nombre,
                    especie: this.especie,
                    sexo: this.sexo,
                    nombreMascota: this.nombreMascota,
                    raza: this.raza,
                    direccion: this.direccion,
                    triaje: response.data[0]['codigo'],
                    fechaDespara: this.fechaDespara,
                    mail: this.mail,
                    consulta: this.consulta,
                    estadoTexto: this.selectedEstado == "" ? this.Estado[0]['valor'] : this.Estado[this.selectedEstado]['valor']
                  })
                  .then((response) => {
                    console.log(response);
                    $("#boxLoading").removeClass("loading");
                    this.$swal
                      .fire({
                        title: "Datos Actualizados Correctamente",
                        confirmButtonText: `Aceptar`,
                        icon: `success`,
                      })
                      .then(() => {
                        /* Read more about isConfirmed, isDenied below */

                        this.$router.replace({
                          name: "Index",
                        });
                      });
                  });
              });

            /* this.$router.replace({
                        name: 'Previa'
                    }); */
          }
        });
    },
    descargar: function () {
      $("#boxLoading").addClass("loading");
      axios({
        url: "http://app.vitalmascotas.com.co/vitalsoft_hibrido/public/api/documento",

        method: "POST",
        data: {
          documento: this.documentoTitular,
          consecutivo: this.consecutivo,
        },

        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));

        var fileLink = document.createElement("a");

        fileLink.href = fileURL;

        fileLink.setAttribute("download", "file.zip");

        document.body.appendChild(fileLink);
        $("#boxLoading").removeClass("loading");
        fileLink.click();
      });
    },
    cambios: function (caso) {
      switch (caso) {
        case 1:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("LErequiereFormula"),
            this.checked
          );
          break;
        case 2:
          localStorage.setItem(
            localStorage
              .getItem("consecutivo")
              .toString()
              .concat("LErequiereServicioVet"),
            this.checked2
          );
          break;
        case 3:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("LEanotaciones"),
            this.anotaciones
          );
          break;
        case 4:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("LEformula"),
            this.prueba
          );
          break;
        case 5:
          localStorage.setItem(
            localStorage.getItem("consecutivo").toString().concat("LEestadoLectura"),
            this.selectedEstado
          );
          break;

        default:
          break;
      }
    },
    actualizar: function () {
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          opcion: 19,
          id: this.id,
          mail: this.mail,
        })
        .then(() => {
          $("#boxLoading").removeClass("loading");
          this.$swal.fire({
            title: "Correo actualizado",
            confirmButtonText: `Aceptar`,
            icon: `success`,
          });
        });
    },
  },
};
</script>

<style>
h1 {
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  top: -10px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

h1 {
  color: white;
}

.listas {
  padding: 10px;
  border-bottom: black solid 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.listas:hover {
  -webkit-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
}

.texto {
  margin-left: 1rem;
}

.boton {
  margin-left: auto;
  color: white;
  margin-right: 2rem !important;
  width: 4rem;
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
  opacity: 0.8;
}

.dialog2 {
  max-width: 90vw !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before {
  background-color: cornflowerblue !important;
}

table.dataTable thead {
  background: linear-gradient(to right, #0575e6, #00f260);
  color: white;
}

table,
th,
td {
  border: 1px solid black !important;
}

td {
  text-align: center;
}

button {
  border-radius: 10px !important;
}

.rodar {
  height: 100vh;
  overflow-y: scroll;
}

button.swal2-styled {
  padding: 0;
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
  opacity: 0.8;
}
.el-select .el-select__tags-text {
  font-size: 120%;
}
.p-inputtext {
  font-size: 1.3rem !important;
}
.el-input__inner {
  font-size: 1.3rem !important;
}
.el-select-dropdown__item {
  font-size: 1.2rem !important;
}
</style>
