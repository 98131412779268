import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Consecutivos from '@/views/Consecutivos'
import NuevaVacuna from '@/views/NuevaVacuna'
import Consecutivos2 from '@/views/Consecutivos2'
import Lectura1 from '@/views/Lectura1'
import Lectura2 from '@/views/Lectura2'
import Historias from '@/views/Historias'
import Previa from '@/views/HistoriasPrevia'
import Final from '@/views/HistoriasFinal'
import Pdf from '@/views/HistoriasPdf'
import Vacuna from '@/views/Vacunas'
import Imagenes from '@/views/Imagenes'
import Listar from '@/views/Listar'
import NotFound from '@/components/NotFound'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/consecutivos',
    name: 'Consecutivos',
    component: Consecutivos
  },
  {
    path: '/nuevavacuna',
    name: 'NuevaVacuna',
    component: NuevaVacuna
  },
  {
    path: '/historias',
    name: 'Historias',
    component: Historias
  },
  {
    path: '/previa',
    name: 'Previa',
    component: Previa
  },
  {
    path: '/final',
    name: 'Final',
    component: Final
  },
  {
    path: '/pdf',
    name: 'Pdf',
    component: Pdf
  },
  {
    path: '/lectura1',
    name: 'Lectura1',
    component: Lectura1
  },
  {
    path: '/lectura2',
    name: 'Lectura2',
    component: Lectura2
  },
  {
    path: '/consecutivos2',
    name: 'Consecutivos2',
    component: Consecutivos2
  },
  {
    path: '/Vacuna',
    name: 'Vacuna',
    component: Vacuna
  },
  {
    path: '/Imagenes',
    name: 'Imagenes',
    component: Imagenes
  },
  {
    path: '/Listar',
    name: 'Listar',
    component: Listar
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/index',
    name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode:'history'
})

export default router
