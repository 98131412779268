<template>
<div class="d-flex align-items-center flex-column">
    <div class="w-100 d-flex align-items-center">
        <div   class=" w-100 pt-2 pb-2">
                <h1  style="cursor:pointer; width:80%;margin-left:10%;margin-right:10%;" class=" text-center">Historias Clinicas RMV</h1>
            </div>
        <div id="drop" class="dropdown">
            <fa @click="Mostrar=true" id="ellipsis-v" style="width:30px; cursor:pointer; position: absolute;right: 0%;margin-right: 10px; color:white; font-size:1.1rem;" icon="ellipsis-v" />
            <div v-if="Mostrar" class="dropdown-content">
                <p style="cursor:pointer; color:black;" @click="mostrar(1)">Móvil</p>
                <p style="cursor:pointer; color:black;" @click="mostrar(2)">Lista Realizados</p>
                <p style="cursor:pointer; color:black;">Subir Archivos</p>
            </div>
        </div>
    </div>
    <!--
        Esta seccion aquí nos sirve para desplegar un mini menu que mostrara tres opciones, al darle click en Móvil mostrara un modal que le permitira al usuario
        Ingresar el numero de la móvil con la que va a trabajar
    -->

    <img alt="Vue logo" src="../assets/vital-mascotas.png">
    <div class="container">
        <div class="forms-container">
            <div class="signin-signup">
                <form id="login" @submit.prevent="signInButtonPressed" class="sign-in-form">
                    <h2 class="title">Ingresar</h2>
                    <div class="input-field">
                        <i class="fas fa-user"></i>
                        <input v-model="usuario" type="text" placeholder="Username" id="username" autocomplete="off" />
                    </div>
                    <div class="input-field">
                        <i class="fas fa-lock"></i>
                        <input v-model="contrasena" type="password" placeholder="Password" id="pass" autocomplete="off" />
                    </div>
                    <button style="color:black;" @click="validar()">Ingresar</button>

                </form>

            </div>
        </div>

    </div>
    <!--
        Esta sección aquí es el login, el login esta compuesto básicamente por dos input y un boton ingresar el cual tiene un evento validar que enviara
        los datos que tienen los v-model usuario y contrasena al back para hacer una validación
    -->
    <div v-if="modal">
        <transition name="model">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog">

                        <div class="modal-content">
                            <div class="modal-header w-100">

                                <h4 class="modal-title w-100">
                                    Móvil
                                </h4>
                            </div>
                            <div class="modal-body">
                                <div class="form-group">
                                    <p>Ingrese el número de la móvil</p>
                                    <input placeholder="Móvil..." maxlength="2" v-model="movil" v-on:input="numeros()" class="w-100" style="border:0px; border-bottom:green solid 1px;" type="text" autocomplete="off" />
                                </div>
                                <div class="form-group">
                                    <button @click="modal=false" style="background: white; color: green; widht:100px; position: relative; top:5px;">ACEPTAR</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </transition>
    </div>
    <div id="boxLoading"></div>
    <!--
        Este es el modal que se menciono previamente arriba, este modal tiene dos eventos importantes en un input y el boton de aceptar
    -->

</div>
</template>

<script>
import axios from 'axios'
var url = "/php/crud.php";
import $ from 'jquery';
export default {
    name: 'Home',
    data() {
        return {
            usuario: '',//Datos input usuario
            contrasena: '',//Datos input contraseña
            movil: '',//Movil seleccionada por el usuario
            modal: false,//Estado de visibilidad del modal
            Mostrar: false,//Estado de visibilidad del menu 
        }
    },
    methods: {
        validar: function () {
            $("#boxLoading").addClass("loading");
            axios.post(url, {
                documento: this.usuario,
                contrasena: this.contrasena,
                opcion: 1
            }).then(response => {
                console.log(response);
                if (response.data.ok) {
                    console.log(response.data.data);
                    localStorage.setItem("prueba",true);
                    localStorage.setItem("datos",JSON.stringify(response.data.data[0]))
                    localStorage.setItem("movil",this.movil == "" ? "1" : this.movil)
                     $("#boxLoading").removeClass("loading")
                    this.$router.replace({
                        name: 'Index'
                    });

                } else {
                    this.$swal.fire('Datos Incorrectos', '', 'error')
                     $("#boxLoading").removeClass("loading")

                }

            }).catch(() => {
                this.$swal.fire('Error de red', '', 'error')
                $("#boxLoading").removeClass("loading")
            })

        }
        //Validar es una funcion que básicamente envia una peticion al servidor dado por la definición de url y una primera opción
        //Los datos a enviar serían el identificador de funcion a usar que es opción, el documento que será lo que contiene usuario
        //y la contraseña que son los datos que contiene contrasena
        //En caso de obtener un ok en los datos resultado entonces haremos un par de cosas
        //Pondremos en localstorage un prueba como true, esto nos servira para dirigir al usuario a este login en caso de que no exista
        //Mandaremos los datos del usuario que esta ingresando al localstorage
        //Mandaremos los datos de la movil seleccionada por el usuario al localstorage, en caso de no seleccionar ninguna se tomara la numero 1 por default
        //En caso de no recibir un ok al usuario se le regresara un datos incorrectos con un sweet alert
        ,
        mostrar: function (value) {
            switch (value) {
                case 1:
                    this.modal = true;
                    this.Mostrar = !this.Mostrar;
                    break;
                case 2:
                    this.$router.replace({
                        name: 'Listar'
                    });
            }
        }
        //mostrar es una función que nos permitira mostrar el modal, va a recibir un parametro y mediante ese parametro decidiremos que modal mostraremos
        ,
        numeros: function () {
            if (isNaN(parseInt(this.movil))) {
                this.movil = this.movil.substring(0, this.movil.length - 1);
            }

        }
        //Numeros es una función que nos permite cancelar el ingreso de caracteres que no queremos, así evitamos la decoración del input type number,
        //Dando como resultado un input type text que solo recibe numeros.
        //NOTA: Se puede lograr un resultado similar utilizando expresiones regulares
    },
    mounted(){
        document.addEventListener('click',((event)=>{
            
            if(event.path[0].id != 'ellipsis-v'){
                this.Mostrar = false;
                console.log("hola");
            }
        }))
        document.body.style.backgroundColor = "#4451b4";
        //Cambiamos el color del background
    }
}
</script>

<style >
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
input {
    font-family: "Poppins", sans-serif;

}



h1,
h2 {
    color: white;
}

form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 5rem;
    transition: all 0.2s 0.7s;
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

form.sign-up-form {
    opacity: 0;
    z-index: 1;
}

form.sign-in-form {
    z-index: 2;
}

.title {
    font-size: 2.2rem;

    margin-bottom: 10px;
}

.input-field {
    max-width: 380px;
    width: 100%;
    background-color: #f0f0f0;
    margin: 10px 0;
    height: 55px;
    border-radius: 55px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    position: relative;
}

.input-field i {
    text-align: center;
    line-height: 55px;
    color: #acacac;
    transition: 0.5s;
    font-size: 1.1rem;
}

.input-field input {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
}

.input-field input::placeholder {
    color: #aaa;
    font-weight: 500;
}

input:hover {
    color: black;
}

button {
    border: none;
    outline: none;
    width: 150px;
    height: 2rem;
    border-radius: 55px !important;
    color: #acacac;
}

button:hover {
    color: black;
}

option {
    color: black;
    background: white;

}

option::hover {
    background: white;
}

/*select{
    margin-left: auto;
    order: 2;
    background: none;
    border: none;
    outline: none;
    -moz-appearance:none; 
    -webkit-appearance:none; 
    appearance:none;
    color: transparent;
    width: 10px;
  }
  */
.dropdown {
    position: relative;
    display: inline-block;
    top: -10px;
}

.dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0%;

}

.dropdown:hover .dropdown-content {
    display: block;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.el-select .el-select__tags-text{
    font-size: 120%;
}
.p-inputtext{
    font-size: 1.3rem !important;
}
.el-input__inner {
    font-size: 1.3rem !important;
}
.el-select-dropdown__item{
    font-size: 1.2rem !important;
}
.form-group {
  display: flex;
    justify-content: center;
}
</style>
