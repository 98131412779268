<template>
<div>
    <div class="about" style="background: #4451b4;">
        <div class="w-100 d-flex align-items-center">
            <Nav msg="Historias Clinicas RMV" />
            <div class="dropdown">
                <fa @click="Mostrar=true" id="ellipsis-v" style=" cursor:pointer; position: absolute;right: 0%;margin-right: 10px; color:white; font-size:1.1rem;" icon="ellipsis-v" />
                <div v-if="Mostrar" class="dropdown-content">
                    <p style="cursor:pointer; color:black;" @click="salir()">Salir</p>

                </div>
            </div>
        </div>
        <!--
            Esta seccion aquí nos sirve para desplegar un mini menu que mostrara tres opciones, al darle click en Móvil mostrara un modal que le permitira al usuario
            Ingresar el numero de la móvil con la que va a trabajar
        -->
        <div class="w-100 d-flex flex-column align-items-center" style="background: rgba(48,62,160,255);">
            <p style="color:white;"> {{ datos.nombre + " " + datos.apellido + " " }}<span>&#183; </span>{{datos.documento }}</p>
            <p style="color:white;"> Móvil <span>&#183; </span>{{movil }}</p>
        </div>
        <!--
            Esta seccion aquí nos sirve para mostrar algunos datos asociados al usuario que ingreso con el login
         -->

    </div>
    <!--
        Toda esta sección podríamos tomarlo como la cabecera que repetiremos en muchas vistas
    -->
    <div style="font-size:1.3rem;"> 
        <p class="texto text-center">
            <b>Lectura de Examenes de la Consulta: <span>{{consecutivo}}</span> </b>
        </p>
        <p class="texto text-center">
            <b>Motivo: <span>{{motivo}}</span> </b>
        </p>
        <p class="texto text-center">
            <b>Diagnostico: <span>{{diagnostico}}</span> </b>
        </p>
        <p class="texto">
            <b>Examenes: </b>
            <br>
            
            <span>{{examenes}}</span>

        </p>
        <p class="texto">
            <b>Tratamiento: </b>
            <br>
            
            <span>{{tratamiento}}</span>

        </p>
        <p class="texto">
            <b>Formulado: </b>
            <br>
            
            <span>{{formula}}</span>

        </p>
        <div class="d-flex w-100 justify-content-center align-items-center  flex-column">

            <button @click="siguiente()" style="background:#2196f3; border-radius: 10px; margin-bottom: 1rem; color: white; width:90%; position: relative; top:5px;">SIGUIENTE</button>

        </div>

        <!--
            Consecutivo almacenado previamente
        -->
       
        <!--
            Nombre de la mascota, asociado con el campo bennm1
        -->
        
        <!--
            Especie de la mascota, 1 para canino 2 para femenino
        -->

        
        <!--
            Esta sección nos mostrara si en verdad el usuario necesita registrar o no el ciclo de desparasitación para su entrega, aquí va a tener
            un texto cambiante teniendo dos opciones: entregar dosis y no entregar dosis, además cambiar el color del fondo dependiendo de si se entrega o no
            siendo amarillo para si y gris para no.
            Además al darle click abre un modal
        -->
       
        <!--
            Este es el campo peso
        -->
    </div>
    <div style="background-color: #efefef;">

        
        
        

    </div>
    
    
    
    <div id="boxLoading"></div>
</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import axios from 'axios'
import $ from 'jquery';
// var url2 = "/php/imagenes.php";
var url = "/php/crud.php";
// var url3 = "/php/prueba.php";
// var url4 = "/php/imagenes_mini.php";
export default {
    name: 'Home2',
    components: {
        Nav
    },
    data() {
        return {
            datos: JSON.parse(localStorage.getItem("datos")),
            movil: localStorage.getItem("movil"),
            Mostrar: false,
            consecutivo: localStorage.getItem("consecutivo"),
            nombre: '',
            nombreMascota: '',
            color: localStorage.getItem("color"),
            texto: localStorage.getItem("texto"),
            raza: '',
            ciclo_Activo: '',
            peso: '',
            fecha: '',
            especie: '',
            dosis: '',
            antecedentes: '',
            notasOperador: '',
            notasServicio: '',
            hora: '',
            consulta: '',
            textoCicloActivo: '',
            validar: false,
            consultaAnterior: '',
            telefonoPropietario: '',
            telefonoServicio: '',
            persona: '',
            documento: '',
            direccion: '',
            signos: '',
            modal: false,
            usuario: '',
            ciclo: '',
            modal2: false,
            modalClinica: false,
            triaje: {
                A: "amarillo",
                V: "verde",
                R: "rojo",
                P: "azul",
                E: "error",
            },
            RegistrarHora: false,
            fechaDespara: '',
            NOTIFICAR: '#ffee58',
            CREAR: '#efefef',
            CREARCOLOR: 'black',
            modalPeso: false,
            src2:'',
            motivo: '',
            diagnostico:'',
            examenes:'',
            tratamiento:'',
            formula:'',

        }
    },
    mounted() {
        $("#boxLoading").addClass("loading");
        
        this.NOTIFICAR = '#efefef';
        this.CREAR = '#4451b4';
        this.CREARCOLOR = 'white';
        this.RegistrarHora = true;
        
        
        var prueba = localStorage.getItem("prueba");
        
        if (prueba === null) {
            this.$router.replace({
                name: 'Home'
            });
        }
        document.body.style.backgroundColor = "white";
        axios.post(url, {
            consecutivo: localStorage.getItem("consecutivo"),
            opcion: 3
        }).then(response => {
            var Datos = response.data.data[0];
           
            this.nombre = Datos.titnom;
            this.nombreMascota = Datos.bennm1;
            this.raza = Datos.Raza;
            this.ciclo_Activo = Datos.cliclo_activo == Datos.desparasitacion_ciclo ? '#fbfb58' : '#8080808c';
            this.textoCicloActivo = Datos.cliclo_activo == Datos.desparasitacion_ciclo ? 'Entregar Dosis' : 'NO Entregar Dosis';
            this.ciclo = Datos.cliclo_activo;
            this.validar = Datos.cliclo_activo == Datos.desparasitacion_ciclo;
            this.peso = Datos.peso;
            this.especie = Datos.especie;
            this.fecha = Datos.Fecha;
            this.dosis = Datos.tipo_dosis;
            this.usuario = Datos.ID_Ben;
            this.fechaDespara = Datos.fecha;
        })
        axios.post(url, {
            consecutivo: localStorage.getItem("consecutivo"),
            opcion: 4
        }).then(response => {
           
            var Datos = response.data.data[0];
            this.antecedentes = Datos.bentxt;
            this.notasOperador = Datos.reqtxo;
            this.notasServicio = Datos.reqtxt;
            this.hora = Datos.reqhor;
            this.consulta = (Datos.ult_traslado == 30 || Datos.ult_traslado == 40) ? 'Si' : (Datos.ult_traslado == 10 || Datos.ult_traslado == 20 || Datos.ult_traslado == 41) ? 'No' : 'No Aplica';
            try {
                this.consultaAnterior = Datos.ult_diag.concat(' ', Datos.ult_diag2, ' (', Datos.ult_fecha, ', ', Datos.ult_hora, ')');
            } catch (error) {
                console.log(error);
            }

            this.telefonoPropietario = Datos.bentel;
            this.telefonoServicio = Datos.reqtel;
            this.persona = Datos.reqper;
            this.documento = Datos.bendoc;
            this.direccion = Datos.reqdir;
            this.signos = Datos.reqsig;
            this.motivo = Datos.motivo;
            this.tratamiento = Datos.tratamiento;
            this.formula = Datos.formula;
            this.diagnostico = Datos.diagnostico;
            axios.post(url, {
            consecutivo: localStorage.getItem("consecutivo"),
            opcion: 22
            }).then(response => {
                var Datos = response.data.datos[0];
                this.examenes = Datos.examenes.replace(',',', ');
                $("#boxLoading").removeClass("loading")
            })
            
        })
        document.addEventListener('click',((event)=>{
            
            if(event.path[0].id != 'ellipsis-v'){
                this.Mostrar = false;
            }
        }))

    },
    methods: {
        salir: function () {
            
            this.$router.replace({
                name: 'Home'
            });
        },
        siguiente: function(){
            this.$router.replace({
                name: 'Lectura2'
            });
        }
       
        
       
    }
}
</script>

<style>
h1 {
    text-align: center;
}

.dropdown {
    position: relative;
    display: inline-block;
    top: -10px;
}

.dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0%;

}

.dropdown:hover .dropdown-content {
    display: block;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

body,
input {
    font-family: "Poppins", sans-serif;

}

h1 {
    color: white;
}

.listas {
    padding: 10px;
    border-bottom: black solid 1px;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.listas:hover {
    -webkit-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
    box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
}

.texto {

    margin-left: 1rem;
}

.boton {
    margin-left: auto;
    color: white;
    margin-right: 2rem !important;
    width: 4rem;
}

.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif') 50% 50% no-repeat rgb(249, 249, 249);
    opacity: .8;
}

.dialog2 {
    max-width: 90vw !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before {
    background-color: cornflowerblue !important;
}

table.dataTable thead {
    background: linear-gradient(to right, #0575E6, #00F260);
    color: white;
}

table,
th,
td {
    border: 1px solid black !important;
}

td {
    text-align: center;
}

button {
    border-radius: 10px !important;
}

.rodar {
    height: 100vh;
    overflow-y: scroll;
}
button.swal2-styled{
    padding: 0;
}
.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif') 50% 50% no-repeat rgb(249, 249, 249);
    opacity: .8;
}
.el-select .el-select__tags-text{
    font-size: 120%;
}
.p-inputtext{
    font-size: 1.3rem !important;
}
.el-input__inner {
    font-size: 1.3rem !important;
}
.el-select-dropdown__item{
    font-size: 1.2rem !important;
}
</style>
