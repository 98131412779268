<template>
  <div>
    <div class="about" style="background: #4451b4">
      <div class="w-100 d-flex align-items-center">
        <Nav msg="Historias Clinicas RMV" />
        <div class="dropdown">
          <fa
            @click="Mostrar = true"
            style="
              cursor: pointer;
              position: absolute;
              right: 0%;
              margin-right: 10px;
              color: white;
              font-size: 1.1rem;
            "
            id="ellipsis-v"
            icon="ellipsis-v"
          />
          <div v-if="Mostrar" class="dropdown-content">
            <p style="cursor: pointer; color: black" @click="salir()">Salir</p>
          </div>
        </div>
      </div>
      <div
        class="w-100 d-flex flex-column align-items-center"
        style="background: rgba(48, 62, 160, 255)"
      >
        <p style="color: white">
          {{ datos.nombre + " " + datos.apellido + " " }}<span>&#183; </span
          >{{ datos.documento }}
        </p>
        <p style="color: white">Móvil <span>&#183; </span>{{ movil }}</p>
      </div>
    </div>
    <div style="margin: 1rem; font-size: 1.3rem">
      <p class="text-center mb-0" style="cursor:pointer;" @click="modalConsecutivo = true">
        <b
          >Consecutivo: <span>{{ consecutivo }}</span>
        </b>
      </p>
      <p class="text-center mb-0">
        <b>
          <span>{{ nombre }}</span>
        </b>
      </p>
      <p class="text-center mb-0">
        <b>
          <span>{{ documento }}</span>
        </b>
      </p>
      <div class="gallery">
        <img
          v-for="imagen in imagenes"
          :key="imagen[1]"
          @click="handler($event, imagen)"
          :src="imagen[0]"
          :id="imagen[1]"
          alt=""
        />
      </div>

      <div class="d-flex w-100 justify-content-center align-items-center flex-column">
        <button
          @click="atras()"
          style="
            background: #2196f3;
            border-radius: 10px;
            margin-bottom: 1rem;
            color: white;
            width: 90%;
            position: relative;
            top: 5px;
          "
        >
          ATRAS
        </button>
      </div>
      <Dialog
        header="Recortar Imagen"
        v-model:visible="display"
        :style="{ width: '100vw' }"
      >
        <img style="width: 100%" :src="imagenModal[0]" />
        <template #footer>
          <Button
            label="Eliminar"
            icon="pi pi-pencil"
            @click="borrar"
            class="p-button-text"
          />
          <Button
            label="Cerrar"
            icon="pi pi-times"
            @click="display = false"
            class="p-button-text"
          />
        </template>
      </Dialog>
    </div>
    <Dialog header="Datos Generales" v-model:visible="modalConsecutivo">
      <p>Nombre Mascota: {{nombreMascota}}</p>
      <p>Correo: {{correo}}</p>
      <p>Nombre Titular: {{nombre}}</p>
      <p>Telefono Titular: {{telefonoTitular}}</p>
      <p>Peso: {{peso}} KG</p>
      
      <br />
      <Button
        @click="modalConsecutivo = false"
        label="Cerrar"
        style="float: right; margin-top: 1rem"
      />
    </Dialog>
    <div id="boxLoading"></div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import axios from "axios";
import $ from "jquery";
var url = "/php/crud.php";
/* var url = "http://localhost:8084/vue/proyecto_mascota s /php/crud.php"; */
export default {
  name: "Previa",
  components: {
    Nav,
  },
  data() {
    return {
      datos: JSON.parse(localStorage.getItem("datos")),
      movil: localStorage.getItem("movil"),
      Mostrar: false,
      consecutivo: localStorage.getItem("consecutivo"),
      nombre: "",
      selectedTamano: "",
      options: [],
      id: "",
      peso: "",
      comida: [],
      selectedComida: "",
      comidas: "",
      cantidad: "",
      marca: "",
      fechaDespara: "",
      Marca: [],
      modalMarca: false,
      documento: "",
      imagenes: [],
      display: false,
      imagenModal: [],
      nombreMascota: "",
      telefonoTitular: "",
      correo: "",
      modalConsecutivo: false
    };
  },
  mounted() {
    $("#boxLoading").addClass("loading");
    var prueba = localStorage.getItem("prueba");
    if (prueba === null) {
      this.$router.replace({
        name: "Home",
      });
    }
    axios
      .post(url, {
        consecutivo: localStorage.getItem("consecutivo"),
        opcion: 4,
      })
      .then((response) => {
        var Datos = response.data.data[0];
        this.documento = Datos.bendoc;
        axios
          .post(url, {
            consecutivo: localStorage.getItem("consecutivo"),
            opcion: 33,
            documentoMascota: Datos.bendoc,
          })
          .then((response) => {
            this.imagenes = response.data;
            axios
              .post(url, {
                consecutivo: localStorage.getItem("consecutivo"),
                opcion: 3,
              })
              .then((response) => {
                var Datos = response.data.data[0];

                this.nombre = Datos.titnom;
                this.nombreMascota = Datos.bennm1;

                this.peso = Datos.peso;
                this.telefonoTitular = Datos.tittel;
                this.correo = Datos.mail;
                $("#boxLoading").removeClass("loading");
              });
          });
      });
    document.addEventListener("click", (event) => {
      if (event.path[0].id != "ellipsis-v") {
        this.Mostrar = false;
      }
    });
    document.body.style.backgroundColor = "white";
  },
  methods: {
    salir: function () {
      this.$router.replace({
        name: "Home",
      });
    },
    atras: function () {
      this.$router.replace({
        name: "Historias",
      });
    },
    handler: function (event, imagen) {
      event.preventDefault();

      this.imagenModal = imagen;
      this.display = true;
    },
    borrar: function () {
      $("#boxLoading").addClass("loading");
      axios
        .post(url, {
          consecutivo: localStorage.getItem("consecutivo"),
          opcion: 34,
          documentoMascota: this.documento,
          nombreImagen: this.imagenModal[1],
        })
        .then(() => {
          $("#boxLoading").removeClass("loading");
          this.display = false;
          document.getElementById(this.imagenModal[1]).style.display = "none";
          this.$swal.fire("Imagen Eliminada Satisfactoriamente", "", "success");
        });
    },
  },
};
</script>

<style>
h1 {
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  top: -10px;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

h1 {
  color: white;
}

.listas {
  padding: 10px;
  border-bottom: black solid 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.listas:hover {
  -webkit-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
  box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
}

#contenedor {
  display: inline-block;
  min-width: 96vw;
}

.texto2 {
  border-bottom: black solid 1px;
}

.p-dropdown-item {
  overflow: auto !important;
  white-space: break-spaces !important;
}

.p-dropdown-items-wrapper {
  max-width: 100vw;
}

.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif") 50% 50%
    no-repeat rgb(249, 249, 249);
  opacity: 0.8;
}
.el-select .el-select__tags-text {
  font-size: 120%;
}
.p-inputtext {
  font-size: 1.3rem !important;
}
.el-input__inner {
  font-size: 1.3rem !important;
}
.el-select-dropdown__item {
  font-size: 1.2rem !important;
}
.gallery img {
  width: 18rem;
  margin: 1rem;
  transition: 1s;
}
.gallery img:hover {
  transform: scale(1.1);
}
</style>
