<template>
<div>
    <div class="about" style="background: #4451b4;">
        <div class="w-100 d-flex align-items-center">
            <Nav msg="Historias Clinicas RMV" />
            <div class="dropdown">
                <fa @click="Mostrar=true" id="ellipsis-v" style=" cursor:pointer; position: absolute;right: 0%;margin-right: 10px; color:white; font-size:1.1rem;" icon="ellipsis-v" />
                <div v-if="Mostrar" class="dropdown-content">
                    <p style="cursor:pointer; color:black;" @click="salir()">Salir</p>

                </div>
            </div>
        </div>
    </div>
    <div id="boxLoading"></div>
    <div class="modal-header d-flex flex-column w-100 d-flex justify-content-center">
        <h1 style="color:black;" class="text-center">Bitacora Vacunación</h1>
        <button @click="NuevaVacuna()" style=" background: #0038ff; color:white; width: 200px; position: relative; top:5px;">Agregar nueva vacuna </button>

    </div>
    <div class="container" style="margin-top: 1rem; " id="containerTable">

    </div>
    <div class="d-flex w-100 justify-content-center align-items-center  flex-column">
        <button @click="redirrecionarAtras()" style="background:#2196f3; border-radius: 10px; margin-bottom: 1rem; color: white; width:90%; position: relative; top:5px;">ATRÁS</button>
    </div>
</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import axios from 'axios'
import $ from 'jquery';
var url = "/php/crud.php";
export default {
    name: 'Vacuna',
    components: {
        Nav
    },
    data() {
        return {
            datos: JSON.parse(localStorage.getItem("datos")), //Datos asignados previamente por localstorage
            movil: localStorage.getItem("movil"), //Movil asignada por el localstorage movil
            Mostrar: false, //Estado visibilidad menu 
            consecutivos: [], //Listado de consecutivos asignados a la movil
            colores: ["red", "#c4c416", "#0073ff", "green", "grey"], //Colores que usaremos variando el triaje
            colores2: ["#8080808c", "white", "#dfe75e"], //Colores que usaremos de fondo dependiendo el reqreg
            reqord: [], //Dato que mostraremos después del consecutivo
            coloresAplicar: [], //Colores que aplicaremos a los botones
            coloresAplicar2: [], //Colores que aplicaremos al background
            texto: [], //Texto ue aplicaremos a los botones
            consecutivosMostrar: false, //Estado visibilidad texto no tiene consecutivos asignados
            textoLe: [],

        }
    },
    mounted() {
        $("#boxLoading").addClass("loading");

        var prueba = localStorage.getItem("prueba");
        if (prueba === null) {
            this.$router.replace({
                name: 'Home'
            });
        }
        //Si no existe prueba regresaremos al usuario al login

        axios.post(url, {
            opcion: 6,
            consecutivo: localStorage.getItem("consecutivo")
        }).then(response => {

            var Div_Contenedor_Table = document.getElementById('containerTable');
            Div_Contenedor_Table.innerHTML = "";
            var Datos = response.data;
            let html = `
                            <div class="row">
                                <div class="col-lg-12">
                                    <table id="Tabla_Beneficiarios" class="table-striped table-bordered" style="width:100%; font-size: 1.1rem;">
                                        <thead class="text-center">
                                            <th>Consecutivo</th>
                                            <th>Vacuna</th>
                                            <th>Fecha</th>
                                            <th>Vacunación Periodo</th>
                                            <th>Veterinario</th>
                                        </thead>
                                        <tbody id="Datos_Beneficiarios">
                                        </tbody>
                                    </table>
                                </div>
                            </div> 
                            `;
            Div_Contenedor_Table.append($(html)[0]);
            var Datos_Beneficiarios = document.getElementById('Datos_Beneficiarios');

            Datos.forEach(element => {
                let html_Interno = `
                                <tr>
                                    <td>${element['consecutivo']}</td>
                                    <td>${element['vacuna']}</td>
                                    <td>${element['fecha']}</td>
                                    <td>${element['periodo']}</td>
                                    <td>${element['veterinario']}</td>
                                </tr>
                                `;
                Datos_Beneficiarios.append($(html_Interno)[0]);

            });

            $('#Tabla_Beneficiarios').DataTable({
                responsive: true,
                dom: 'Bfrtip',
                buttons: [

                ]
            });
            $("#boxLoading").removeClass("loading")

        })
        document.addEventListener('click',((event)=>{
            
            if(event.path[0].id != 'ellipsis-v'){
                this.Mostrar = false;
            }
        }))
        document.body.style.backgroundColor = "white";
        //Cambiamos el color del background

    },
    methods: {
        salir: function () {

            this.$router.replace({
                name: 'Home'
            });
            //Esta función sirve para redigir al usuario al login
        },
        redirrecionarAtras: function () {
            this.$router.replace({
                name: 'Consecutivos'
            })
        },
        NuevaVacuna: function () {
            this.$router.replace({
                name: 'NuevaVacuna'
            });
        }

    }
}
</script>

<style>
h1 {
    text-align: center;
}

.dropdown {
    position: relative;
    display: inline-block;
    top: -10px;
}

.dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0%;

}

.dropdown:hover .dropdown-content {
    display: block;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

body,
input {
    font-family: "Poppins", sans-serif;

}

h1 {
    color: white;
}

.listas {
    padding: 10px;
    border-bottom: black solid 1px;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.listas:hover {
    -webkit-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
    box-shadow: inset 10px 10px 36px 0px rgba(0, 0, 0, 0.09);
}

.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif') 50% 50% no-repeat rgb(249, 249, 249);
    opacity: .8;
}
.el-select .el-select__tags-text{
    font-size: 120%;
}
.p-inputtext{
    font-size: 1.3rem !important;
}
.el-input__inner {
    font-size: 1.3rem !important;
}
.el-select-dropdown__item{
    font-size: 1.2rem !important;
}
</style>
