<template>
<div   class=" w-100 pt-2 pb-2">
    <div class="about" style="background: #4451b4;">
        <div class="w-100 d-flex align-items-center">
            <Nav msg="Historias Clinicas RMV" />
            
        </div>
    </div>

    <h1 style="color:black;">404 <br>NOT FOUND</h1>
    
</div>
</template>

<script>
//Este es un componente que usaremos en cada vista para dar un titulo 
import Nav from '@/components/Nav.vue'

export default {
    name: 'NotFound',
    components: {
        Nav
    },
    methods: {
        redirigir(){
            this.$router.replace({
                name: 'Index'
            });
        }
    }
}
</script>

<style scoped>

.pi-envelope:before{
display: none;
}
.prueba{
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
}
</style>
